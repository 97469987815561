import appStore from '@/stores/AppStore';
import React, { Component } from 'react';
import goalsPNG from '@/assets/goalsIcon.png';
import lanternPNG from '@/assets/thougthlantern.png';
import { Button, Link, Popup } from 'framework7-react';
import './notification-popup.scss';

class NotificationPopup extends Component {
	buildNotification() {
		let { notification } = this.props;
		switch (notification) {
			case 'unlockedGoals': {
				return (
					<>
						<h1 className="text-gradiant">You Have Unlocked Something New!</h1>
						<div className="image-ctn">
							<img src={goalsPNG} alt="Goals Icon" />
						</div>
						<h3>Short-Term Goals Feature</h3>
						<p>
							Now you can create short-term goals or real live event in First Resort Companion App. Complete them to add into your
							progress!
						</p>
						<Button
							className="primary-btn"
							onClick={() => {
								this.props.onAddGoal();
								this.props.onPopupClosed();
							}}
						>
							Add A New Goal
						</Button>
						<Link popupClose outline small className="secondary-btn">
							Later
						</Link>
					</>
				);
			}
			case 'unlockedThoughts': {
				return (
					<>
						<h1 className="text-gradiant">You Have Unlocked Something New!</h1>
						<div className="image-ctn">
							<img src={lanternPNG} alt="Goals Icon" />
						</div>
						<h3>Thought Log Feature</h3>
						<p>Now you can log your thoughts anytime in First Resort Companion App and reframe them in VR later!</p>
						<Button
							className="primary-btn"
							onClick={() => {
								this.props.onLogThought();
								this.props.onPopupClosed();
							}}
						>
							Log A Thought
						</Button>
						<Link popupClose outline small className="secondary-btn">
							Later
						</Link>
					</>
				);
			}
			case 'goalCreation': {
				return (
					<>
						<h1 className="text-gradiant">Let's do a non-VR Quest</h1>
						<div className="image-ctn">
							<img src={goalsPNG} alt="Goals Icon" />
						</div>
						<h3>Create New Short-Term Goals</h3>
						<p>
							In order to continue to your next VR chapter, it's time for you to add 3 short-term goals from one of your long-term
							goals.
						</p>
						<p>It could be from the long-term goal you're already working on or other that has no short-term goals yet.</p>
						<Button
							className="primary-btn"
							onClick={() => {
								this.props.onAddGoal();
								this.props.onPopupClosed();
							}}
						>
							Add Goal Now
						</Button>
						<Link popupClose outline small className="secondary-btn">
							Later
						</Link>
					</>
				);
			}
			default: {
				return '';
			}
		}
	}

	render() {
		let { onPopupClosed, opened, notification } = this.props;
		if (!notification) {
			return '';
		}
		return (
			<Popup
				className={`notification-popup ${!appStore.isMobile ? 'desktop' : 'mobile'}`}
				opened={opened}
				closeByBackdropClick={false}
				closeOnEscape={false}
				onPopupClosed={onPopupClosed}
			>
				<div className="popup-header hbox vcenter">
					<div className="grow-1"></div>
					<Link popupClose className="back-btn hbox vcenter">
						<i className="bx bx-x"></i>
					</Link>
				</div>
				<div className="page-ctn">{this.buildNotification()}</div>
			</Popup>
		);
	}
}

export default NotificationPopup;
