import React, { useState } from 'react';
import ariPNG from '@/assets/ari.png';
import { Button } from 'framework7-react';
import IOSInstallPrompt from '../ios-install-prompt/IOSInstallPrompt';
export function AriPrompt(props) {
	let { message } = props;
	const [opened, setOpened] = useState(false);

	const onShowInstall = () => {
		console.log('Prompting for install');
		if (appStore.deviceDetails.ios) {
			setOpened(true);
		} else if (window.deferredPrompt) {
			//show android pwa install prompt
			window.deferredPrompt.prompt();
		}
	};

	return (
		<div className="ari-prompt hbox vtop">
			<img src={ariPNG} alt="BehaVR Ari Bot" />
			<div className="message-body">
				<div>{message}</div>
				<Button fill large className="primary-btn" onClick={onShowInstall}>
					Add to Home Screen
				</Button>
				<Button onClick={props.onSkip}>Skip for now</Button>
			</div>
			<IOSInstallPrompt
				opened={opened}
				onClose={() => {
					setOpened(false);
				}}
			></IOSInstallPrompt>
		</div>
	);
}
