import React from 'react';
import { f7, Page } from 'framework7-react';
import { observer, ReactiveComponent, Store } from '@/utils/State';
import savLogo from '@/assets/savproduct.png';
import backgroundImage from '@/assets/bvrbg.png';
import appStore, { getUserProductConfig } from '@/stores/AppStore';
import { autoBind } from '@/utils/GeneralUtils';
import headsetPNG from '@/assets/userheadset.png';
import _ from 'lodash';
import './bvrlanding.scss';
import { Splash } from '../Splash';
import { AriMessage } from '@/components/ari-message/AriMessage';
import ThoughtCapturePopup from '@/components/thought-capture-popup/ThoughtCapturePopup';
import AccountManagement from '../account-management/AccountManagement';
import APIService from '@/services/APIService';
import BVRNav from '@/components/bvr-nav/BVRNav';
import GoalWidget from '@/components/goal-widgit/GoalWidget';
import NotificationPopup from '@/components/notification-popup/NotificationPopup';
import GoalCapturePopup from '@/components/goal-capture-popup/GoalCapturePopup';
import GoalEditPopup from '@/components/goal-edit-popup/GoalEditPopup';
import GoalDetailsPopup from '@/components/goal-details-popup/GoalDetailsPopup';
import FirstResortLogo from '@/assets/firstresort';
import { AriPrompt } from '@/components/ari-message/AriPrompt';

//* Defined outside of component no need for 'this' keyword
const thoughtPopup = Store({
	open: false,
	toggle: () => {
		thoughtPopup.open = !thoughtPopup.open;
	}
});
class BVRLanding extends ReactiveComponent {
	constructor(props) {
		super(props);
		this.data = Store({
			widgitReload: 0,
			activeGoal: null,
			goalToEdit: null,
			goalDetailsOpen: false,
			goalCaptureOpen: false,
			goalEditOpen: false,
			showNotification: false
		});
		this.imgRef = React.createRef();
	}

	//* Defined inside of component, need to reference with 'this' keyword
	settingsPopup = Store({
		open: false,
		toggle: () => {
			this.settingsPopup.open = !this.settingsPopup.open;
		}
	});

	tempThoughtStore = Store({
		thoughts: []
	});

	notificationStore = Store({
		notification: null
	});

	showSkipNotification() {
		this.data.showNotification = true;
		setTimeout(() => {
			this.data.showNotification = false;
		}, 5000);
	}

	reloadWidgit() {
		this.data.widgitReload++;
	}

	componentDidMount() {
		this.checkForTempThoughts();
		try {
			let bvrStatus = _.find(_.get(appStore.user, 'productStatus', []), { product: 'bvr100' });
			//* BVR first then check first-resort TODO @BVR account for all types in future
			if (!bvrStatus) {
				bvrStatus = _.find(_.get(appStore.user, 'productStatus', []), { product: 'first-resort' });
				appStore.bvrProductType = 'first-resort';
			} else {
				appStore.bvrProductType = 'bvr100';
			}
		} catch (err) {
			console.log(err);
		}

		if (appStore.bvrTarget !== null) {
			console.log(`Re-Routing to Target: ${appStore.bvrTarget}`);
			let target = _.clone(appStore.bvrTarget);
			appStore.bvrTarget = null;
			setTimeout(() => {
				switch (target) {
					case 'goals': {
						appStore.bvrActiveTab = 'goals';
						f7.view.main.router.navigate('/bvr-goals');
						break;
					}
					case 'creategoal': {
						appStore.bvrActiveTab = 'goals';
						appStore.bvrCreateTarget = 'goal';
						f7.view.main.router.navigate('/bvr-goals');
						break;
					}
					case 'thoughts': {
						appStore.bvrActiveTab = 'thoughts';
						f7.view.main.router.navigate('/bvr-thoughts');
						break;
					}
					default: {
						console.log(`No route for ${target}`);
					}
				}
			});
		} else {
			//* We're not rerouting so check for in app notification
			let notifications = _.get(appStore.user, 'config.product.first-resort.appNotifications', {});
			let toNofify = null;
			Object.keys(notifications).forEach((n) => {
				if (notifications[n]) {
					toNofify = n;
				}
			});
			if (!_.isEmpty(toNofify)) {
				APIService.acknowledgeNotification('first-resort', toNofify).then(() => {
					console.log(`${toNofify} acknowledged`);
				});
				this.notificationStore.notification = toNofify;
			}
		}
	}

	checkForTempThoughts() {
		APIService.getTempThoughts()
			.then((res) => {
				this.tempThoughtStore.thoughts = res;
				if (this.tempThoughtStore.thoughts.length > 0) {
					f7.dialog.alert(
						`You started recording a thought via SMS, "${this.tempThoughtStore.thoughts[0].body}" you're almost finished!`,
						'Continute Your Thought',
						() => {
							thoughtPopup.toggle();
						}
					);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}

	Header() {
		const getProductPrettyName = (product) => {
			switch (product) {
				case 'first-resort': {
					return 'First Resort';
				}
				case 'bvr100': {
					return 'BVR-100';
				}
				default: {
					return product;
				}
			}
		};

		const imageHeight = this.imgRef?.current?.clientHeight || 300;
		return (
			<>
				<div className="header-ctn hbox hcenter" style={{ marginTop: -imageHeight }}>
					<div className="title grow-1 hbox vcenter">
						<FirstResortLogo></FirstResortLogo>
						{getProductPrettyName(appStore.bvrProductType)}
					</div>
					<div className="settings-btn" onClick={this.settingsPopup.toggle}>
						<i className="bx bx-cog"></i>
					</div>
				</div>
				{this.data.showNotification && (
					<AriMessage
						className="mTop-32"
						message={`Remember you can go to 'Settings' to add shortcut to your Home screen for easier access!`}
					/>
				)}
			</>
		);
	}

	Profile() {
		return (
			<div className="profile-ctn vbox vcenter hcenter">
				<div className="user-icon vbox vcenter hcenter">
					<i className="bx bx-user"></i>
				</div>
				<div className="user-name text-gradiant">{_.get(appStore, 'user.firstName', 'Unknown')}</div>
			</div>
		);
	}

	ThoughtLogButton() {
		return (
			<div className="thought-cta hbox vcenter" onClick={thoughtPopup.toggle}>
				<div className="grow-1">What do you have in mind?</div>
				<div className="plus-icon hbox vcenter hcenter">
					<i className="bx bx-plus"></i>
				</div>
			</div>
		);
	}

	render() {
		if (!appStore.isLoggedIn) {
			return <Splash></Splash>;
		}
		let { thoughts, goals } = getUserProductConfig('first-resort');

		return (
			<Page name="bvr-landing" className={`bvr-landing-page-cmp ${!appStore.isMobile ? 'desktop' : 'mobile'}`}>
				<div className={`background`}></div>
				<div className="content-ctn y-scroll">
					<div className="img-ctn vbox hcenter" ref={this.imgRef}>
						<img src={backgroundImage} className="bg-image" alt="VR Session Landscape" />
					</div>
					{this.Header()}
					<div className="spacer"></div>
					{this.Profile()}
					<div className="divider-line"></div>
					<div className="action-ctn">
						{!appStore.isPWA() && !appStore.skipInstallCard && (
							<AriPrompt
								message={`Before you forget, let's add the First Resort companion app to your Home Screen`}
								onSkip={() => {
									appStore.skipInstallCard = true;
									this.showSkipNotification();
								}}
							></AriPrompt>
						)}
						{!goals && (
							<div className="empty-message">
								<div className="image-ctn">
									<img src={headsetPNG} alt="VR Headset Icon" />
								</div>
								<h2>Continue Your VR Session</h2>
								<p>You're ready for the next steps! As you progressing in VR, you will unlock useful features in this mobile app</p>
							</div>
						)}
						{(goals || thoughts) && (appStore.isPWA() || appStore.skipInstallCard) && !this.data.showNotification && (
							<AriMessage
								message={`“Hello ${_.capitalize(
									_.get(appStore, 'user.firstName', 'Unknown')
								)}! So far you are making great progress! Keep it up!”`}
							></AriMessage>
						)}
						{thoughts && this.ThoughtLogButton()}
						{goals && <GoalWidget reloadCount={this.data.widgitReload}></GoalWidget>}
					</div>
				</div>
				<AccountManagement
					productToSetup={appStore.bvrProductType}
					opened={this.settingsPopup.open}
					onClose={this.settingsPopup.toggle}
				></AccountManagement>
				<ThoughtCapturePopup
					opened={thoughtPopup.open}
					onPopupClosed={() => {
						thoughtPopup.toggle();
						this.checkForTempThoughts();
					}}
					tempThoughts={this.tempThoughtStore.thoughts}
				></ThoughtCapturePopup>
				<BVRNav
					onDataChanged={() => {
						this.reloadWidgit();
					}}
				></BVRNav>
				<NotificationPopup
					opened={this.notificationStore.notification !== null}
					onPopupClosed={() => {
						this.notificationStore.notification = null;
					}}
					notification={this.notificationStore.notification}
					onLogThought={() => {
						thoughtPopup.toggle();
					}}
					onAddGoal={() => {
						this.data.goalCaptureOpen = true;
					}}
				></NotificationPopup>
				<GoalCapturePopup
					ket="goal-capture-from-upcoming"
					opened={this.data.goalCaptureOpen}
					onEdit={(goal) => {
						this.data.activeGoal = null;
						this.data.goalDetailsOpen = false;
						this.data.goalToEdit = goal;
						this.data.goalEditOpen = true;
					}}
					onPopupClosed={() => {
						this.data.goalCaptureOpen = false;
						setTimeout(() => {
							this.reloadWidgit();
						}, 0);
					}}
				></GoalCapturePopup>
				<GoalEditPopup
					opened={this.data.goalEditOpen}
					goal={this.data.goalToEdit}
					onPopupClosed={async () => {
						this.data.goalEditOpen = false;
						this.data.goalToEdit = null;
						await this.reloadWidgit();
						if (this.data.activeGoal) {
							let updatedGoal = _.cloneDeep(_.find(this.data.goalList, { shortTermGoalId: this.data.activeGoal.shortTermGoalId }));
							if (!updatedGoal) {
								this.data.activeGoal = null;
								this.data.goalDetailsOpen = false;
							} else {
								this.data.activeGoal = updatedGoal;
							}
						}
					}}
				></GoalEditPopup>
				<GoalDetailsPopup
					opened={this.data.goalDetailsOpen}
					goal={this.data.activeGoal}
					onEdit={(goal) => {
						this.data.goalToEdit = goal;
						this.data.goalEditOpen = true;
					}}
					onDoItAgain={(goal) => {
						this.data.activeGoal = null;
						this.data.goalDetailsOpen = false;
						delete goal.shortTermGoalId;
						this.data.goalToEdit = goal;
						this.data.goalEditOpen = true;
					}}
					onPopupClosed={() => {
						this.data.activeGoal = null;
						this.data.goalDetailsOpen = false;
						this.reloadWidgit();
					}}
				/>
			</Page>
		);
	}
}

export default observer(BVRLanding);
