import React, { Component } from 'react';
import _ from 'lodash';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import { Button, Link, Sheet, f7, Preloader } from 'framework7-react';
import nurtureLogoPNG from '@/assets/nurtureLogo.png';
import savLogoPNG from '@/assets/savproduct.png';
import stressLogo from '@/assets/stressLogo.png';
import FirstResortLogo from '@/assets/firstResortLogo.png';
import pneLogo from '@/assets/pnelogo.png';
import baby1PNG from '@/assets/baby1.png';
import baby2PNG from '@/assets/baby2.png';
import baby3PNG from '@/assets/baby3.png';
import baby4PNG from '@/assets/baby4.png';
import baby5PNG from '@/assets/baby5.png';
import baby6PNG from '@/assets/baby6.png';
import appStore from '@/stores/AppStore';
import { StorkSVG } from './SVGAssets';
import FormBuilder from '../form-builder/FormBuilder';
import APIService from '@/services/APIService';
import AnalyticsService, { TrackedEvents } from '@/services/AnalyticsService';
import './product-setup-sheet.scss';

export const ProductMap = {
	nurture: {
		name: 'NurtureVR',
		img: nurtureLogoPNG,
		oculusLink: 'https://www.oculus.com/experiences/quest/',
		steps: {
			1: 'deliveryQuestion',
			2: 'deliveryDate',
			3: 'dueDate',
			4: 'skinTone',
			5: 'pinCode',
			6: 'completedSetupPico',
			7: 'launch'
		}
	},
	sav: {
		name: 'SAV - Anxiety Regulation',
		img: savLogoPNG,
		oculusLink: 'https://www.oculus.com/experiences/quest/',
		steps: {
			1: 'pinCode',
			2: 'completedSetupPico',
			3: 'launch'
		}
	},
	bvr100: {
		name: 'BVR-100',
		img: savLogoPNG,
		oculusLink: 'https://www.oculus.com/experiences/quest/',
		steps: {
			1: 'pinCode',
			2: 'completedSetupPico',
			3: 'launch'
		}
	},
	'first-resort': {
		name: 'First Resort',
		img: FirstResortLogo,
		oculusLink: 'https://www.oculus.com/experiences/quest/',
		steps: {
			1: 'pinCode',
			2: 'completedSetupPico',
			3: 'launch'
		}
	},
	pne2: {
		name: 'PNE+',
		oculusLink: 'https://www.oculus.com/experiences/quest/',
		img: pneLogo,
		steps: {
			1: 'pinCode',
			2: 'completedSetupPico',
			3: 'launch'
		}
	},
	stress: {
		name: 'Coping with Stress',
		oculusLink: 'https://www.oculus.com/experiences/quest/',
		img: stressLogo,
		steps: {
			1: 'pinCode',
			2: 'completedSetupPico',
			3: 'launch'
		}
	}
};

@observer
export default class ProductSetupSheet extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			setupComplete: false,
			headsetType: 'pico',
			headsetSyncMode: 'none',
			step: 0,
			loading: true,
			pinCodeValid: false,
			showSync: false,
			syncSuccess: false,
			syncCodeValid: false,
			syncPayload: { code: '' },
			updatePINPayload: { pinCode: '' },
			productSetupData: {
				babyArrived: false,
				deliveryDate: new Date(),
				dueDate: new Date(),
				skinTone: 1,
				pinCode: ''
			}
		});
		autoBind(this);
	}

	reset() {
		this.data.setupComplete = false;
		this.data.headsetType = 'pico';
		this.data.headsetSyncMode = 'none';
		this.data.step = 0;
		this.data.pinCodeValid = false;
		this.data.showSync = false;
		this.data.syncSuccess = false;
		this.data.syncPayload = { code: '' };
		this.data.syncCodeValid = false;
		this.data.productSetupData = {
			babyArrived: false,
			deliveryDate: new Date(),
			dueDate: new Date(),
			skinTone: 0,
			pinCode: ''
		};
		this.data.updatePINPayload = { pinCode: '' };
		this.data.loading = true;
	}

	handleSheetClose(e) {
		this.reset();
		if (this.props.onClosedSheet) {
			this.props.onClosedSheet();
		}
	}

	handleSheetOpen() {
		console.log('Sheet Opened');
		let { product } = this.props;
		let headsetType = _.get(appStore, `referringEntity.headsetType`, null) === 'pico' ? 'pico' : 'quest';
		let headsetSyncMode = _.get(appStore, `referringEntity.headsetSyncMode`, null) === 'none' ? 'none' : 'legacy';
		let setupComplete = false;
		//check for setup status
		if (_.get(appStore.user, 'purchased', []).indexOf(product) >= 0) {
			let status = _.find(_.get(appStore.user, 'productStatus', []), { product: product });
			if (status && status.setup) {
				setupComplete = true;
			}
		}
		this.data.setupComplete = setupComplete;
		this.data.headsetType = headsetType;
		this.data.headsetSyncMode = headsetSyncMode;
		this.data.loading = false;
	}

	onSetup() {
		this.data.step = 1;
	}

	onSyncHeadset() {
		this.data.showSync = true;
	}

	submitSyncCode() {
		let code = _.get(this.data, 'syncPayload.code', '');
		if (!_.isEmpty(code)) {
			f7.dialog.preloader('Syncing...');
			APIService.syncHeadset(code)
				.then((res) => {
					this.data.syncSuccess = true;
					f7.dialog.close();
				})
				.catch((err) => {
					console.log(err);
					f7.dialog.close();
					f7.dialog.alert('Invalid Code, please try again');
				});
		}
	}

	onUpdateHeadsetPIN() {
		f7.dialog.preloader('Updating...');
		let code = _.get(this.data, 'updatePINPayload.pinCode', '');
		if (!_.isEmpty(code)) {
			APIService.updateHeadsetPIN(code)
				.then(() => {
					this.handleSheetClose();
					f7.dialog.close();
					f7.toast.create({ text: 'Success', closeTimeout: 1000, icon: `<i class="f7-icons">checkmark_shield</i>` }).open();
				})
				.catch((err) => {
					console.log(err);
					f7.dialog.close();
					f7.dialog.alert('Unable to update PIN, please try again.');
				});
		}
	}

	onOpenOculusLab() {
		this.handleSheetClose();
		try {
			window.open(ProductMap[this.props.product].oculusLink, '_blank');
		} catch (err) {
			console.log(err);
		}
	}

	getAddressText() {
		let { street1, street2, city, state, zip } = appStore.user;
		return `${_.toUpper(street1)}${street2 ? ` ${street2}` : ''} ${_.toUpper(city)} ${_.toUpper(state)}, ${zip}`;
	}

	saveSetupDetails() {
		f7.dialog.preloader('Completing Product Setup...');
		let { deliveryDate, dueDate, skinTone, pinCode, babyArrived } = this.data.productSetupData;
		let setupPayload = {
			pinCode,
			productType: this.props.product
		};

		//Nurture Specific Payload Params
		if (this.props.product === 'nurture') {
			if (babyArrived) {
				setupPayload.deliveryDate = deliveryDate;
			} else {
				setupPayload.dueDate = dueDate;
			}
			if (this.data.headsetType === 'pico') {
				setupPayload.skinTone = skinTone;
			}
		}
		console.log(this.props.product);
		APIService.completeProductSetup(this.props.product, setupPayload)
			.then((res) => {
				f7.dialog.close();
				appStore.user = res;
				if (this.props.product === 'nurture') {
					this.data.step = this.data.headsetType === 'pico' ? 6 : 7;
				}
				if (['sav', 'bvr100', 'first-resort', 'pne2', 'stress'].indexOf(this.props.product) >= 0) {
					this.data.step = 3;
				}
				f7.dialog.close();
				AnalyticsService.trackEvent(TrackedEvents.PWAPinCreated, {
					behavrId: res.userId,
					practiceId: res.practiceId
				});
			})
			.catch((err) => {
				console.log(err);
				f7.dialog.close();
				f7.dialog.alert('Please Try Again', 'Error:');
			});
	}

	getSyncContent() {
		if (this.data.syncSuccess) {
			return (
				<div className="setup-step-ctn">
					<div className="setup-header hbox vcenter">
						<div className="title">Sync Successful!</div>
					</div>
					<div className="sub-text">Your headset has been successfully connected.</div>
					<div className="sync-icon hbox vcenter hcenter">
						<i className="bx bx-check-circle"></i>
					</div>
					<div className="sub-text">
						{`Now you can launch your VR sessions anytime from your VR headset. ${
							this.props.product === 'nurture' ? `It's time to focus on yourself and your baby.` : ''
						}`}
					</div>
					<div className="btn-ctn">
						<Button large className="primary-btn" onClick={this.handleSheetClose}>
							Done
						</Button>
					</div>
				</div>
			);
		}
		return (
			<div className="setup-step-ctn">
				<div className="setup-header hbox vcenter">
					<div className="title">Sync Headset</div>
				</div>
				<div className="sub-text left-align">Please enter the code displayed on your VR headset. (Without Dashes)</div>
				<FormBuilder
					formData={this.data.syncPayload}
					formConfig={{
						code: {
							type: 'text',
							label: 'Sync Code:',
							placeholder: 'Enter Code Here',
							validator: {
								type: 'length',
								value: 4
							}
						}
					}}
					setValidationState={(valid) => {
						this.data.syncCodeValid = valid;
					}}
				></FormBuilder>
				<div className="btn-ctn">
					<Button large className="primary-btn" disabled={!this.data.syncCodeValid} onClick={this.submitSyncCode}>
						Submit
					</Button>
					<Button
						large
						className="secondary-btn"
						onClick={() => {
							this.handleSheetClose();
						}}
					>
						Cancel
					</Button>
				</div>
			</div>
		);
	}

	getUpdatePINContent() {
		return (
			<div className="setup-step-ctn">
				<div className="setup-header hbox vcenter">
					<div className="title">Update Headset PIN</div>
				</div>
				<div className="sub-text left-align">This pin will allow you to login to the BehaVR experiences on your headset.</div>
				<FormBuilder
					formData={this.data.updatePINPayload}
					formConfig={{
						pinCode: {
							label: 'Enter Your 4 Digit PIN:',
							placeholder: 'Enter Code Here',
							type: 'tel',
							className: 'pin-code',
							maxlength: 4,
							validator: {
								type: 'length',
								value: 4
							}
						}
					}}
					setValidationState={(valid) => {
						this.data.pinCodeValid = valid;
					}}
				></FormBuilder>
				<div className="btn-ctn">
					<Button large className="primary-btn" disabled={!this.data.pinCodeValid} onClick={this.onUpdateHeadsetPIN}>
						Save
					</Button>
					<Button
						large
						className="secondary-btn"
						onClick={() => {
							this.handleSheetClose();
						}}
					>
						Cancel
					</Button>
				</div>
			</div>
		);
	}

	getInitialContent() {
		let { product, updatePIN } = this.props;
		let { setupComplete, headsetType, headsetSyncMode, loading, step, showSync } = this.data;
		if (loading) {
			return (
				<div className="hbox vcenter hcenter">
					<Preloader size={32} color="blue"></Preloader>
				</div>
			);
		}
		if (updatePIN) {
			return this.getUpdatePINContent();
		}
		if (showSync) {
			return this.getSyncContent();
		}
		if (!setupComplete && step === 0) {
			return (
				<>
					<div className="setup-header hbox vcenter">
						<Link sheetClose>
							<i className="bx bx-arrow-back"></i>Back
						</Link>
						<div className="title">You're Almost Done!</div>
					</div>
					<div className="success-text">You now have access to:</div>
					<div className="product-ctn vbox vcenter hcenter">
						<div className="name">{ProductMap[product]?.name}</div>
						<img src={ProductMap[product]?.img} alt={`BehaVR ${product} Icon`} />
					</div>
					<div className="success-text">
						{headsetType === 'pico' ? 'Continute setup to confirm shipping:' : 'Please confirm a few details'}
					</div>
					<div className="btn-ctn">
						<Button fill large className="primary-btn" onClick={this.onSetup}>
							Setup & Confirm
						</Button>
					</div>
				</>
			);
		}
		let currentStep = setupComplete ? 'launch' : _.get(ProductMap, `${product}.steps.${step}`);
		switch (currentStep) {
			case 'deliveryQuestion': {
				return (
					<div className="setup-step-ctn">
						<div className="setup-header hbox vcenter">
							<div className="title">Has your baby arrived yet?</div>
						</div>
						<div className="step-svg">{StorkSVG}</div>
						<div className="btn-ctn vbox vcenter hcenter">
							<Button
								large
								className="primary-btn"
								onClick={() => {
									this.data.step = 2;
									this.data.babyArrived = true;
								}}
							>
								Yes, my baby is here!
							</Button>
							<Button
								large
								className="secondary-btn"
								onClick={() => {
									this.data.step = 3;
									this.data.babyArrived = false;
								}}
							>
								Not Yet
							</Button>
						</div>
					</div>
				);
			}
			case 'deliveryDate': {
				return (
					<div className="setup-step-ctn">
						<div className="setup-header hbox vcenter">
							<div className="title">Please confirm your delivery date</div>
						</div>
						<FormBuilder
							formData={this.data.productSetupData}
							formConfig={{
								deliveryDate: {
									label: 'Delivery Date',
									type: 'date',
									icon: 'bx bx-calendar-heart',
									disabledRange: {
										from: new Date(new Date().getTime())
									},
									validator: {
										type: 'date'
									}
								}
							}}
						></FormBuilder>
						<div className="btn-ctn">
							<Button
								large
								className="primary-btn"
								onClick={() => {
									this.data.step = headsetType === 'pico' ? 4 : 5;
								}}
							>
								Next
							</Button>
							<Button
								large
								className="secondary-btn"
								onClick={() => {
									this.data.step = 1;
								}}
							>
								Back
							</Button>
						</div>
					</div>
				);
			}
			case 'dueDate': {
				return (
					<div className="setup-step-ctn">
						<div className="setup-header hbox vcenter">
							<div className="title">Please confirm your due date</div>
						</div>
						<FormBuilder
							formData={this.data.productSetupData}
							formConfig={{
								dueDate: {
									label: 'Due Date',
									type: 'date',
									icon: 'bx bx-calendar-heart',
									disabledRange: {
										to: new Date(new Date().getTime() - 1000 * 60 * 60 * 24)
									},
									validator: {
										type: 'date'
									}
								}
							}}
						></FormBuilder>
						<div className="btn-ctn">
							<Button
								large
								className="primary-btn"
								onClick={() => {
									this.data.step = headsetType === 'pico' ? 4 : 5;
								}}
							>
								Next
							</Button>
							<Button
								large
								className="secondary-btn"
								onClick={() => {
									this.data.step = 1;
								}}
							>
								Back
							</Button>
						</div>
					</div>
				);
			}
			case 'skinTone': {
				let { skinTone } = this.data.productSetupData;
				return (
					<div className="setup-step-ctn">
						<div className="setup-header hbox vcenter">
							<div className="title">Personalize Your Baby</div>
						</div>
						<div className="sub-text left-align">
							We want you to have the deepest connection with your baby. Please select the option that best suits your baby preference.
							This will customize your baby in VR lessons.
						</div>
						<div className="skin-tone-selector">
							<img
								className={`baby ${skinTone === 1 && 'selected'}`}
								src={baby1PNG}
								alt="Baby skin tone 1"
								onClick={() => {
									this.data.productSetupData.skinTone = 1;
								}}
							/>
							<img
								className={`baby ${skinTone === 2 && 'selected'}`}
								src={baby2PNG}
								alt="Baby skin tone 2"
								onClick={() => {
									this.data.productSetupData.skinTone = 2;
								}}
							/>
							<img
								className={`baby ${skinTone === 3 && 'selected'}`}
								src={baby3PNG}
								alt="Baby skin tone 3"
								onClick={() => {
									this.data.productSetupData.skinTone = 3;
								}}
							/>
							<img
								className={`baby ${skinTone === 4 && 'selected'}`}
								src={baby4PNG}
								alt="Baby skin tone 4"
								onClick={() => {
									this.data.productSetupData.skinTone = 4;
								}}
							/>
							<img
								className={`baby ${skinTone === 5 && 'selected'}`}
								src={baby5PNG}
								alt="Baby skin tone 5"
								onClick={() => {
									this.data.productSetupData.skinTone = 5;
								}}
							/>
							<img
								className={`baby ${skinTone === 6 && 'selected'}`}
								src={baby6PNG}
								alt="Baby skin tone 6"
								onClick={() => {
									this.data.productSetupData.skinTone = 6;
								}}
							/>
						</div>
						<div className="btn-ctn">
							<Button
								large
								className="primary-btn"
								disabled={this.data.productSetupData.skinTone <= 0}
								onClick={() => {
									this.data.step = 5;
								}}
							>
								Next
							</Button>
							<Button
								large
								className="secondary-btn"
								onClick={() => {
									this.data.step = 1;
								}}
							>
								Back
							</Button>
						</div>
					</div>
				);
			}
			case 'pinCode': {
				return (
					<div className="setup-step-ctn">
						<div className="setup-header hbox vcenter">
							<div className="title">Create Headset PIN</div>
						</div>
						<div className="sub-text left-align">This pin will allow you to login to the BehaVR experiences on your headset.</div>
						<FormBuilder
							formData={this.data.productSetupData}
							formConfig={{
								pinCode: {
									label: 'Enter Your 4 Digit PIN:',
									placeholder: 'Enter Code Here',
									type: 'tel',
									className: 'pin-code',
									maxlength: 4,
									validator: {
										type: 'length',
										value: 4
									}
								}
							}}
							setValidationState={(valid) => {
								this.data.pinCodeValid = valid;
							}}
						></FormBuilder>
						<div className="btn-ctn">
							<Button large className="primary-btn" disabled={!this.data.pinCodeValid} onClick={this.saveSetupDetails}>
								Save
							</Button>
							<Button
								large
								className="secondary-btn"
								onClick={() => {
									if (step == 1) {
										this.data.step = 0;
									} else {
										this.data.step = headsetType === 'pico' ? 4 : 1;
									}
								}}
							>
								Back
							</Button>
						</div>
					</div>
				);
			}
			case 'completedSetupPico': {
				return (
					<div className="setup-step-ctn">
						<div className="setup-header hbox vcenter">
							<div className="title">Setup Complete!</div>
						</div>
						<div className="success-text">Your headset is on the way.</div>
						<div className="hbox vcenter address-text">
							<i className="bx bx-home-heart"></i>
							<div className="key-value">
								<div className="key">Shipping Address:</div>
								<div className="value">{this.getAddressText()}</div>
							</div>
						</div>
						<div className="btn-ctn">
							<Button fill large className="primary-btn" onClick={this.handleSheetClose}>
								Done
							</Button>
						</div>
					</div>
				);
			}
			case 'launch': {
				return (
					<div className="setup-step-ctn">
						<div className="setup-header hbox vcenter">
							<div className="title">Ready to Launch!</div>
						</div>
						<div className="product-ctn vbox vcenter hcenter">
							<div className="name">{ProductMap[product].name}</div>
							<img src={ProductMap[product].img} alt={`BehaVR ${product} Icon`} />
						</div>
						<div className="btn-ctn">
							<div className="sub-text">Have your headset nearby?</div>
							{headsetType === 'pico' || headsetSyncMode === 'legacy' ? (
								<div className="btn-ctn">
									<Button fill large className="primary-btn" onClick={this.onSyncHeadset}>
										Sync Headset
									</Button>
									<Button
										large
										className="secondary-btn"
										onClick={() => {
											appStore.openVideoKey =
												headsetType === 'pico'
													? 'https://player.vimeo.com/video/549319579?h=733fbb3e5b'
													: 'https://player.vimeo.com/video/658778860?h=16c56d9347';
											this.handleSheetClose();
										}}
									>
										View Tutorial
									</Button>
								</div>
							) : (
								<Button fill large className="primary-btn" onClick={this.onOpenOculusLab}>
									Oculus App Lab
									<i className="bx bx-link-external"></i>
								</Button>
							)}
							<div className="btn-btn">
								<div className="sub-text">Not Ready Yet?</div>
								<Button large className="secondary-btn" onClick={this.handleSheetClose}>
									Launch Later
								</Button>
							</div>
						</div>
					</div>
				);
			}
			default: {
				return (
					<>
						<div className="setup-header hbox vcenter">
							<h2>Unknown Product {_.capitalize(product)}</h2>
						</div>
						<div className="btn-ctn vbox vcenter hcenter">
							<Link sheetClose>Close</Link>
						</div>
					</>
				);
			}
		}
	}

	render() {
		let { opened } = this.props;
		return (
			<Sheet
				className={`product-setup-sheet-cmp ${!appStore.isMobile ? 'desktop' : 'mobile'}`}
				position={!appStore.isMobile ? 'top' : 'bottom'}
				opened={opened}
				closeByBackdropClick={false}
				closeOnEscape={false}
				onSheetClose={this.handleSheetClose}
				onSheetOpen={this.handleSheetOpen}
				fullscreen
				backdrop
				push
			>
				<div className="setup-content">{opened && this.getInitialContent()}</div>
			</Sheet>
		);
	}
}
