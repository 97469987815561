import React, { Component } from 'react';
import _ from 'lodash';
import { observer, observable } from '@/utils/State';
import { autoBind, validateEmail, validateUrl } from '@/utils/GeneralUtils';
import BasicInput from '@/components/basic-input/BasicInput';
import { f7, Input, ListItem, Preloader, Toggle } from 'framework7-react';
import DropDown from '@/components/drop-down/DropDown';
import './form-builder.scss';
import { hidden } from 'chalk';

@observer
export default class FormBuilder extends Component {
	constructor(props) {
		super(props);
		this.data = observable({ emailValid: false, invalidFields: [], runningValidator: false });
		autoBind(this);
	}

	componentDidMount() {
		if (this.props.formData && Object.keys(this.props.formData).length > 0) {
			if (!this.data.runningValidator) {
				this.data.runningValidator = true;
				this.runValidator();
			}
		}
	}

	validateField(validator, value) {
		switch (validator.type) {
			case 'length': {
				if (value && value.length >= validator.value) {
					return true;
				}
				return false;
			}
			case 'numberString': {
				if (value && value.length >= validator.value) {
					return true;
				}
				return false;
			}
			case 'zipCode': {
				if (value && value.length === 5) {
					return true;
				}
				return false;
			}
			case 'date': {
				//assuming all dates via picker are valid for now
				return true;
			}
			case 'phone': {
				return value && value.length === 12;
				// value = value.replace(/\D/g, '');
				// if (value && value.length === 10) {
				// 	return true;
				// }
				// return false;
			}
			case 'email': {
				return this.data.emailValid || validateEmail(value);
			}
			case 'url': {
				return validateUrl(value);
			}
			case 'password': {
				var strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
				const isStrong = strongRegex.test(value);
				if (!isStrong) {
					return false;
				}
				return true;
			}
		}
	}

	appendDefaultValidation(field) {
		switch (field.type) {
			case 'zipCode': {
				field.validator = {
					type: 'zipCode'
				};
				return field;
			}
			default: {
				return field;
			}
		}
	}

	runValidator() {
		let { formData, formConfig, customValidator } = this.props;
		if (!customValidator) {
			let isValid = true;
			let invalidFields = [];
			_.forEach(Object.keys(formConfig), (key) => {
				if (key.indexOf('row') == 0) {
					_.forEach(Object.keys(_.get(this, `props.formConfig[${key}]`, {})), (rowKey) => {
						//row fields validator
						let field = _.get(formConfig, `${key}.${rowKey}`);
						field = this.appendDefaultValidation(field);
						if (field.validator && !field.hidden) {
							let valid = this.validateField(field.validator, formData[rowKey]);
							if (!valid && !field.validator.notRequired) {
								isValid = false;
								invalidFields.push(`${key}.${rowKey}`);
							}
						}
					});
				} else {
					//root field validator
					let field = _.get(formConfig, `${key}`);
					field = this.appendDefaultValidation(field);
					if (field.validator && !field.hidden) {
						let valid = this.validateField(field.validator, formData[key]);
						if (!valid && !field.validator.notRequired) {
							isValid = false;
							invalidFields.push(key);
						}
					}
				}
			});
			this.data.invalidFields = invalidFields;
			if (this.props.setValidationState) {
				this.props.setValidationState(isValid);
			}
		}
		this.data.runningValidator = false;
	}

	onInputChange(evt) {
		let target = evt.currentTarget;
		let value = target.value;
		if (target.name === 'pinCode') {
			value = value.replace(/\D/g, '');
		}
		if (target.name === 'zip') {
			value = value.replace(/\D/g, '');
		}
		let dataProperty = _.clone(target.name);
		if (dataProperty.indexOf('.') >= 0) {
			dataProperty = dataProperty.split('.')[dataProperty.split('.').length - 1];
		}
		this.props.formData[dataProperty] = value;

		if (this.props.afterChange) {
			this.props.afterChange(dataProperty);
		}
		if (!this.data.runningValidator) {
			this.data.runningValidator = true;
			this.runValidator();
		}
	}

	onDateInputChange(value) {
		if (this.props.afterChange) {
			this.props.afterChange(value);
		}
		if (!this.data.runningValidator) {
			this.data.runningValidator = true;
			this.runValidator();
		}
	}

	handleEmailCheck(isValid) {
		const email = this.props.formData.email || '';
		this.props.formData.emailValid = isValid && email.length > 0;
	}

	buildDropDown(key, config) {
		let { formData } = this.props;
		let dataProperty = key;
		if (dataProperty.indexOf('.') >= 0) {
			dataProperty = dataProperty.split('.')[dataProperty.split('.').length - 1];
		}
		let selected = formData[dataProperty];

		let listItems = [];
		_.forEach(Object.keys(config.listItems), (listItemKey) => {
			if (key) {
				let label = config.listItems[listItemKey];
				let value = _.clone(label);
				if (config.displayValues && typeof config.displayValues === 'function') {
					value = config.displayValues(listItemKey, config.listItems);
				}
				listItems.push(
					<ListItem
						link="#"
						popoverClose
						className="drop-down-list-item"
						key={`${dataProperty}-${listItemKey}-dropdown-item`}
						onClick={() => {
							this.props.formData[dataProperty] = listItemKey;
							if (this.props.afterChange) {
								this.props.afterChange(dataProperty);
							}
							if (!this.data.runningValidator) {
								this.data.runningValidator = true;
								this.runValidator();
							}
						}}
					>
						<div className="dd-label">{value}</div>
					</ListItem>
				);
			}
		});
		let displayValue = config.listItems[selected];
		if (config.displayValues && typeof config.displayValues === 'function') {
			displayValue = config.displayValues(selected, config.listItems);
		}
		return (
			<DropDown
				key={`app-dropdown-${dataProperty}`}
				name={`formbuilder-dropdown-${dataProperty}`}
				label={config.label}
				icon={config.icon}
				items={listItems}
				className={`app-dropdown md ${config.className}`}
				displayValue={displayValue}
			/>
		);
	}

	onPhoneChange(dataProperty, value) {
		this.props.formData[dataProperty] = value;
		if (this.props.afterChange) {
			this.props.afterChange(dataProperty);
		}
		if (!this.data.runningValidator) {
			this.data.runningValidator = true;
			this.runValidator();
		}
	}

	buildField(key) {
		let { formData } = this.props;
		let { hidden, label, placeholder, type, className, validator, disabled, disabledRange, resizable, icon, maxlength } = _.get(
			this.props,
			`formConfig.${key}`
		);
		if (hidden) {
			return;
		}
		let dataProperty = key;
		if (dataProperty.indexOf('.') >= 0) {
			dataProperty = dataProperty.split('.')[dataProperty.split('.').length - 1];
		}

		let invalidField = false;
		if (this.props.showErrorState) {
			invalidField = this.data.invalidFields.indexOf(key) >= 0;
		}
		switch (type) {
			case 'loader': {
				return (
					<div className="form-loader vbox vcenter hcenter" key="app-form-loader-key">
						<Preloader color="blue"></Preloader>
					</div>
				);
			}
			case 'text': {
				return (
					<BasicInput
						key={`app-form-input-${key}`}
						keyId={`app-form-input-${key}`}
						validate
						label={label}
						name={key}
						type={type}
						placeholder={placeholder}
						value={formData[dataProperty]}
						onChange={this.onInputChange}
						disabled={disabled || false}
						className={`${className || ''}`}
						invalid={invalidField}
					/>
				);
			}
			case 'zipCode': {
				return (
					<BasicInput
						key={`app-form-input-${key}`}
						keyId={`app-form-input-${key}`}
						validate
						label={label}
						name={key}
						type={'text'}
						placeholder={placeholder}
						value={formData[dataProperty]}
						onChange={this.onInputChange}
						disabled={disabled || false}
						className={`${className || ''}`}
						invalid={invalidField}
					/>
				);
			}
			case 'tel': {
				return (
					<BasicInput
						key={`app-form-input-${key}`}
						keyId={`app-form-input-${key}`}
						validate
						label={label}
						name={key}
						type={type}
						maxlength={maxlength}
						autocomplete="tel"
						placeholder={placeholder}
						value={formData[dataProperty]}
						onChange={this.onInputChange}
						disabled={disabled || false}
						className={`${className || ''}`}
						invalid={invalidField}
					/>
				);
			}
			case 'textarea': {
				return (
					<BasicInput
						key={`app-form-input-${key}`}
						keyId={`app-form-input-${key}`}
						validate
						label={label}
						name={key}
						type={type}
						placeholder={placeholder}
						value={formData[dataProperty]}
						onChange={this.onInputChange}
						disabled={disabled || false}
						className={`${className || ''} ${resizable ? 'resizable' : ''}`}
						invalid={invalidField}
					/>
				);
			}
			case 'number': {
				return (
					<BasicInput
						key={`app-form-input-${key}`}
						keyId={`app-form-input-${key}`}
						label={label}
						name={key}
						type={type}
						placeholder={placeholder}
						value={formData[dataProperty]}
						onChange={this.onInputChange}
						disabled={disabled || false}
						className={`${className || ''}`}
						invalid={invalidField}
					/>
				);
			}
			case 'toggle': {
				return (
					<div className="app-form-toggle hbox vcenter" key={`app-form-toggle-${key}`}>
						<label className="label">{label}</label>
						<Toggle
							checked={formData[dataProperty]}
							onChange={(e) => {
								formData[dataProperty] = e.target.checked;
							}}
						></Toggle>
					</div>
				);
			}
			case 'phone': {
				return (
					<BasicInput
						key={`app-form-input-${key}`}
						keyId={`app-form-input-${key}`}
						label={label}
						name={key}
						type="phone"
						maxlength={10}
						minlength={10}
						validate={true}
						placeholder={placeholder}
						value={formData[dataProperty]}
						onChange={(value) => {
							this.onPhoneChange(dataProperty, value);
						}}
						disabled={disabled || false}
						className={`${className || ''}`}
						invalid={invalidField}
					/>
				);
			}
			case 'email': {
				return (
					<BasicInput
						key={`app-form-input-${key}`}
						keyId={`app-form-input-${key}`}
						label={label}
						validate={true}
						name={key}
						type={type}
						placeholder={placeholder}
						value={formData[dataProperty]}
						disabled={disabled || false}
						onValidate={
							validator
								? (isValid) => {
										this.data.emailValid = isValid;
								  }
								: this.handleEmailCheck
						}
						onChange={this.onInputChange}
						className={`form-builder-email-input ${className || ''}`}
						invalid={invalidField}
					/>
				);
			}
			case 'password': {
				return (
					<BasicInput
						key={`app-form-input-${key}`}
						keyId={`app-form-input-${key}`}
						label={label}
						name={key}
						type={type}
						placeholder={placeholder}
						value={formData[dataProperty]}
						onChange={this.onInputChange}
						disabled={disabled || false}
						className={`${className || ''}`}
						invalid={invalidField}
					/>
				);
			}
			case 'dropdown': {
				return this.buildDropDown(key, _.get(this, `props.formConfig.${key}`));
			}
			case 'date': {
				let id = `basic-date-input-${key}`;
				let calendarParams = {
					openIn: 'customModal',
					header: true,
					dateFormat: 'mm/dd/yyyy',
					sheetPush: true,
					closeOnSelect: true
				};
				if (disabledRange) {
					calendarParams.disabled = disabledRange;
				}
				return (
					<div className="basic-input basic-date-input" id={id} key={`basic-date-input-${key}`}>
						{label && (
							<label htmlFor={`app-form-date-input-${key}`} className="label">
								{label}
							</label>
						)}
						<div className="gradiant">
							<i
								className={icon || 'bx bx-calendar'}
								onClick={() => {
									document.querySelector(`#${id} .date-modal-input input`).click();
								}}
							></i>
							<Input
								type="datepicker"
								key={`date-input-${key}`}
								inputId={`app-form-date-input-${key}`}
								onCalendarChange={(e) => {
									this.props.formData[dataProperty] = e[0];
									this.onDateInputChange(e[0]);
								}}
								title={label}
								name={key}
								value={[formData[dataProperty]]}
								placeholder="MM/DD/YYYY"
								disabled={disabled || false}
								className={`date-modal-input ${className || ''}`}
								calendarParams={calendarParams}
								readonly
							></Input>
						</div>
					</div>
				);
			}
			default: {
				return;
			}
		}
	}

	formBuilder() {
		let formInputs = [];
		_.forEach(Object.keys(this.props.formConfig), (key) => {
			if (key.indexOf('row') == 0) {
				let rowFields = [];
				_.forEach(Object.keys(_.get(this, `props.formConfig[${key}]`, {})), (rowKey) => {
					let field = this.buildField(`${key}.${rowKey}`);
					if (field) {
						rowFields.push(field);
					}
				});
				formInputs.push(
					<div className="form-row hbox vtop" key={`form-row-${key}`}>
						{rowFields}
					</div>
				);
			} else {
				let field = this.buildField(key);
				if (field) {
					formInputs.push(field);
				}
			}
		});
		return formInputs;
	}

	render() {
		return <div className="app-form-builder">{this.formBuilder()}</div>;
	}
}
