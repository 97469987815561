import React from 'react';
import { Page } from 'framework7-react';
import behavrLogoPNG from '@/assets/behavrlogo.png';
export function Splash() {
	return (
		<Page name="bvr-splash">
			<div className="vbox vcenter hcenter" style={{ width: '100vw', height: '100vh' }}>
				<img src={behavrLogoPNG} style={{ width: '80%', maxWidth: '300px' }} alt="BehaVR Logo" />
			</div>
		</Page>
	);
}
