import React from 'react';
import { Button, f7, Fab, Icon, Page, Preloader } from 'framework7-react';
import { observer, ReactiveComponent } from '@/utils/State';
import backgroundImage from '@/assets/bvrgoals.png';
import appStore, { getUserProductConfig } from '@/stores/AppStore';
import { autoBind, getDaysDelta, showError } from '@/utils/GeneralUtils';
import { Splash } from '../../pages/Splash';
import APIService from '@/services/APIService';
import BVRNav from '@/components/bvr-nav/BVRNav';
import goalsPNG from '@/assets/goalsIcon.png';
import './goal-widgit.scss';
import _ from 'lodash';
import GoalCapturePopup from '@/components/goal-capture-popup/GoalCapturePopup';
import { format, differenceInDays } from 'date-fns';
import GoalDetailsPopup from '@/components/goal-details-popup/GoalDetailsPopup';
import GoalEditPopup from '@/components/goal-edit-popup/GoalEditPopup';

class GoalWidgit extends ReactiveComponent {
	constructor(props) {
		super(props);
		this.setData({
			goalList: [],
			filter: 'todo',
			loading: true,
			activeGoal: null,
			goalToEdit: null,
			goalDetailsOpen: false,
			goalCaptureOpen: false,
			goalEditOpen: false,
			reloadCount: props.reloadCount
		});
		autoBind(this);
	}

	componentDidMount() {
		this.loadGoals();
	}

	updateGoalStatus(id, completed) {
		f7.dialog.preloader('Loading...');
		APIService.updateGoalStatus(id, completed)
			.then(async (res) => {
				await this.loadGoals();
				f7.dialog.close();
			})
			.catch((err) => {
				console.log(err);
				showError(err, 'Unable to update goal');
			});
	}

	async loadGoals() {
		return new Promise((resolve) => {
			this.data.loading = true;
			let { goals } = getUserProductConfig('first-resort');
			if (goals) {
				APIService.getGoals()
					.then((res) => {
						this.data.goalList = res.sort((a, b) => {
							return new Date(a.deadline.deadlineDate) - new Date(b.deadline.deadlineDate);
						});
						this.data.loading = false;
						resolve();
					})
					.catch((err) => {
						showError(err);
						resolve();
					});
			} else {
				this.data.loading = false;
				resolve();
			}
		});
	}

	GoalList() {
		//* If no goals show empty message
		let { goalList, filter } = this.data;
		let goalsToDo = goalList.filter((g) => g.status === 'todo');
		if (goalList.length <= 0 || goalsToDo.length === 0) {
			return (
				<div className="empty-message">
					<div className="image-ctn">
						<img src={goalsPNG} alt="Lantern Icon" />
					</div>
					<h2>No Goals to Work On</h2>
					<Button
						className="add-goal-btn"
						onClick={() => {
							this.data.goalCaptureOpen = true;
						}}
					>
						Let's add a SMART GOAL
					</Button>
				</div>
			);
		}
		const goalListItems = [];
		_.forEach(goalsToDo, (goal) => {
			let { shortTermGoalId, summary, deadline, repeat, completedDate, status } = goal;
			let daysDelta = !_.isEmpty(deadline.deadlineDate) ? getDaysDelta(new Date(), new Date(deadline.deadlineDate)) : 0;
			let overdue = daysDelta > 0 ? true : false;
			let dueMessage = null;
			if (overdue) {
				dueMessage = `${daysDelta} ${daysDelta === 1 ? 'Day' : 'Days'} Overdue. ${
					deadline.repeatable ? 'Did you do it?' : 'Need more time?'
				}`;
			} else if (daysDelta === 0) {
				dueMessage = 'Due Today';
			}
			if (filter === 'completed') {
				dueMessage = null;
			}
			let showGoal = status == filter;
			if (status === 'todo' && daysDelta > 0) {
				showGoal = false;
			}
			if (daysDelta > 0 && filter === 'pastdue' && status !== 'completed') {
				showGoal = true;
			}

			if (showGoal) {
				goalListItems.push(
					<div className="goal-card-ctn hbox vcenter" key={`goal-card-${shortTermGoalId}`}>
						<div
							className="status"
							onClick={() => {
								if (goal.status === 'completed') {
									this.updateGoalStatus(goal.shortTermGoalId, false);
								} else {
									//Mark completed
									this.updateGoalStatus(goal.shortTermGoalId, true);
								}
							}}
						>
							{status !== 'completed' ? (
								<div className="empty-checkbox"></div>
							) : (
								<div className="checkbox-checked">
									<i className="bx bx-check"></i>
								</div>
							)}
						</div>
						<div
							className="goal-card"
							onClick={(e) => {
								this.onGoalClicked(e, goal);
							}}
						>
							<div className="date vbox hcenter">
								<div className="day">{format(new Date(deadline.deadlineDate), 'E')}</div>
								<div className="daynum">{format(new Date(deadline.deadlineDate), 'd')}</div>
								<div className="month">{format(new Date(deadline.deadlineDate), 'MMM')}</div>
							</div>
							<div className="goal-body grow-1">
								<div className={`goal-type ${deadline.repeatable === false ? 'onetime' : 'repeatable'}`}>
									{!deadline.repeatable
										? 'ONE TIME'
										: deadline.repeat.frequency == 'day'
										? 'DAILY'
										: deadline.repeat.frequency == 'week'
										? 'WEEKLY'
										: 'MONTHLY'}
								</div>
								<div className="text-body grow-1">{summary.length > 65 ? `${summary.substring(0, 65)}...` : summary}</div>
								{dueMessage && <div className={`due-message ${overdue ? 'red' : ''}`}>{dueMessage}</div>}
							</div>
						</div>
					</div>
				);
			}
		});
		return (
			<div className="goals-list">
				<h1 className="text-gradiant">Upcoming Goals</h1>
				{goalListItems.length > 0 ? goalListItems : <div className="empty-message">No goals match this filter.</div>}
				<div
					className="goal-list-cta"
					onClick={() => {
						appStore.bvrActiveTab = 'goals';
						setTimeout(() => {
							f7.view.main.router.navigate('/bvr-goals');
						}, 200);
					}}
				>
					<div className="list-cta">View All Goals</div>
				</div>
			</div>
		);
	}

	onGoalClicked(event, goal) {
		if (
			event.target.classList.contains('status') ||
			event.target.classList.contains('empty-checkbox') ||
			event.target.classList.contains('checkbox-checked') ||
			event.target.classList.contains('bx-check')
		) {
			return;
		}
		this.data.activeGoal = goal;
		this.data.goalDetailsOpen = true;
	}

	render() {
		if (!appStore.isLoggedIn) {
			return <Splash></Splash>;
		}
		let { goals } = getUserProductConfig('first-resort');
		if (!goals) {
			return <div></div>;
		}
		let { loading } = this.data;

		if (this.props.reloadCount != this.data.reloadCount) {
			setTimeout(() => {
				this.data.reloadCount = this.props.reloadCount;
				this.loadGoals();
			}, 0);
		}
		return (
			<div className={`goal-widgit ${!appStore.isMobile ? 'desktop' : 'mobile'}`}>
				<h2>Your Short-Term Goals</h2>
				{loading && (
					<div className="hbox vcenter hcenter">
						<Preloader size={32} color="blue"></Preloader>
					</div>
				)}
				{!loading && this.GoalList()}
				<GoalCapturePopup
					ket="goal-capture-from-upcoming"
					opened={this.data.goalCaptureOpen}
					onEdit={(goal) => {
						this.data.activeGoal = null;
						this.data.goalDetailsOpen = false;
						this.data.goalToEdit = goal;
						this.data.goalEditOpen = true;
					}}
					onPopupClosed={() => {
						this.data.goalCaptureOpen = false;
						setTimeout(() => {
							this.loadGoals();
						}, 0);
					}}
				></GoalCapturePopup>
				<GoalEditPopup
					opened={this.data.goalEditOpen}
					goal={this.data.goalToEdit}
					onPopupClosed={async () => {
						this.data.goalEditOpen = false;
						this.data.goalToEdit = null;
						await this.loadGoals();
						if (this.data.activeGoal) {
							let updatedGoal = _.cloneDeep(_.find(this.data.goalList, { shortTermGoalId: this.data.activeGoal.shortTermGoalId }));
							if (!updatedGoal) {
								this.data.activeGoal = null;
								this.data.goalDetailsOpen = false;
							} else {
								this.data.activeGoal = updatedGoal;
							}
						}
					}}
				></GoalEditPopup>
				<GoalDetailsPopup
					opened={this.data.goalDetailsOpen}
					goal={this.data.activeGoal}
					onEdit={(goal) => {
						this.data.goalToEdit = goal;
						this.data.goalEditOpen = true;
					}}
					onDoItAgain={(goal) => {
						this.data.activeGoal = null;
						this.data.goalDetailsOpen = false;
						delete goal.shortTermGoalId;
						this.data.goalToEdit = goal;
						this.data.goalEditOpen = true;
					}}
					onPopupClosed={() => {
						this.data.activeGoal = null;
						this.data.goalDetailsOpen = false;
						this.loadGoals();
					}}
				/>
			</div>
		);
	}
}

export default observer(GoalWidgit);
