import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import behavrLogo from '@/assets/logo.png';
import savemomsPNG from '@/assets/savemoms.png';
import { Button, Link, Popup, f7 } from 'framework7-react';
import appStore from '@/stores/AppStore';
import APIService from '@/services/APIService';
import _ from 'lodash';
import './donation-page.scss';

@observer
export default class DonationPage extends Component {
	constructor(props) {
		super(props);
		autoBind(this);
	}

	closeLinkPopup() {
		if (this.props.onClose) {
			this.props.onClose();
		}
	}

	onDonate() {
		f7.dialog.preloader('Updating...');
		APIService.donatedHeadset()
			.then(() => {
				f7.dialog.close();
				f7.dialog.alert('Thank you for your donation. A shipping label will be emailed to you within 48 hours.', 'Success!', () => {
					this.closeLinkPopup();
					setTimeout(() => {
						appStore.user.donatedHeadset = true;
					}, 1000);
				});
			})
			.catch((err) => {
				console.log(err);
				f7.dialog.close();
			});
	}

	handlePopupOpen() {}

	render() {
		return (
			<Popup
				className={`donation-page-popup ${!appStore.isMobile ? 'desktop' : 'mobile'}`}
				opened={this.props.opened}
				closeByBackdropClick={false}
				closeOnEscape={false}
				onPopupClosed={this.closeLinkPopup}
				onPopupOpened={this.handlePopupOpen}
				backdrop={false}
			>
				<div className="popup-header hbox vcenter">
					<Link popupClose className="back-btn hbox vcenter">
						<i className="bx bx-arrow-back"></i> Back
					</Link>
					<div className="grow-1"></div>
					<img src={behavrLogo} className="header-logo" alt="BehaVR Logo" />
				</div>
				<div className="main-content y-scroll">
					<h1>
						Donate NurtureVR<br></br> to a Mommy in Need
					</h1>
					<p>
						We hope NurtureVR was there to support you during your pregnancy journey.
						<br></br>
						<br></br>
						BehaVR offers a "give it back" program where you can donate your headset and program to another mommy in need. If you wish
						to participate, simply click the below button and our NurtureVR Mommy Success team will email you a shipping label to
						return your headset.
						<br></br>
						<br></br>
						We will then clean and refurbish your headset to send to SaveMoms, a maternal health non-profit organization, who will then
						onboard a new mom.
						<br></br>
						<br></br>
						We will also share with you a picture of the mommy and baby you helped support.
					</p>
					<Button fill large className="primary-btn white" onClick={this.onDonate}>
						Donate
					</Button>
					<div className="vbox vcenter hcenter">
						<img
							className="save-moms"
							src={savemomsPNG}
							alt="Save Moms Logo"
							onClick={() => {
								window.open('https://savemoms.us/', '_blank');
							}}
						></img>
					</div>
				</div>
			</Popup>
		);
	}
}
