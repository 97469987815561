import * as React from 'react';

const FirstResortLogo = (props) => (
	<svg width={41} height={40} style={{ marginRight: '8px' }} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M35.44 7.19c-.11 4.122-1.218 8.64-3.392 13.03C26.312 31.8 15.33 38.108 7.519 34.31c-2.023-.983-3.633-2.55-4.812-4.529a19.958 19.958 0 0 0 12.701 9.53C26.158 41.907 37 35.377 39.62 24.724c.753-3.04.766-6.212.04-9.258a19.815 19.815 0 0 0-4.218-8.278Z"
			fill="url(#a)"
		/>
		<path
			d="M.17 18.158c3.028-2.828 7.044-5.238 11.722-6.813 12.343-4.156 24.636-.934 27.454 7.198.73 2.105.75 4.332.17 6.55a19.487 19.487 0 0 0-2.184-15.585C31.572.174 19.222-2.795 9.748 2.882 4.045 6.3.686 12.07.17 18.158Z"
			fill="url(#b)"
		/>
		<path
			d="M11.236 35.355c4.11.5 8.049-1.645 8.049-1.645a35.26 35.26 0 0 1-3.194-2.77C6.823 21.862 4.285 9.536 10.422 3.412 12.012 1.826 14.055.84 16.33.365A19.794 19.794 0 0 0 3.11 9.227C-.962 15.594-.917 23.374 2.55 29.52a12.398 12.398 0 0 0 2.879 3.47c1.452 1.205 3.142 2.04 5.807 2.366Z"
			fill="url(#c)"
		/>
		<defs>
			<linearGradient id="a" x1={6.925} y1={37.804} x2={42.213} y2={15.771} gradientUnits="userSpaceOnUse">
				<stop offset={0.06} stopColor="#fff" />
				<stop offset={0.24} stopColor="#C4B3EE" />
				<stop offset={0.4} stopColor="#9D7DE8" />
				<stop offset={0.61} stopColor="#8257E8" />
				<stop offset={0.82} stopColor="#5E33C1" />
			</linearGradient>
			<linearGradient id="b" x1={40.544} y1={16.55} x2={2.996} y2={6.559} gradientUnits="userSpaceOnUse">
				<stop offset={0.06} stopColor="#E1F8F4" />
				<stop offset={0.25} stopColor="#79E6D4" />
				<stop offset={0.52} stopColor="#49C7B2" />
				<stop offset={0.71} stopColor="#0AA58B" />
				<stop offset={0.91} stopColor="#0C7563" />
			</linearGradient>
			<linearGradient id="c" x1={2.24} y1={3.696} x2={20.135} y2={32.702} gradientUnits="userSpaceOnUse">
				<stop offset={0.06} stopColor="#FDFDFD" />
				<stop offset={0.26} stopColor="#CFE6F4" />
				<stop offset={0.47} stopColor="#97CFF5" />
				<stop offset={0.72} stopColor="#47AFF3" />
				<stop offset={0.98} stopColor="#117DC2" />
			</linearGradient>
		</defs>
	</svg>
);

export default FirstResortLogo;
