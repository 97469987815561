import appStore from '@/stores/AppStore';
import React, { Component } from 'react';
import { Link, Popup } from 'framework7-react';
import './video-popup.scss';

class VideoPopup extends Component {
	render() {
		let { onPopupClosed, opened, videoSrc } = this.props;
		return (
			<Popup
				className={`video-popup ${!appStore.isMobile ? 'desktop' : 'mobile'}`}
				opened={opened}
				closeByBackdropClick={false}
				closeOnEscape={false}
				onPopupClosed={onPopupClosed}
			>
				<div className="popup-header hbox vcenter">
					<Link popupClose className="back-btn hbox vcenter">
						<i className="bx bx-x"></i> Close
					</Link>
					<div className="grow-1"></div>
				</div>
				<div className="page-ctn">
					{opened && <iframe title="vimeo-player" src={videoSrc} frameBorder="0" autoPlay="1" allowFullScreen></iframe>}
				</div>
			</Popup>
		);
	}
}

export default VideoPopup;
