import React from 'react';
import ariPNG from '@/assets/ari.png';
export function AriMessage(props) {
	let { message, className } = props;
	return (
		<div className={`ari-message hbox vcenter ${className}`}>
			<img src={ariPNG} alt="BehaVR Ari Bot" />
			<div className="message-body">{message}</div>
		</div>
	);
}
