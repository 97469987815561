import React from 'react';
import appStore from '@/stores/AppStore';
import { f7, Link, Popup, Toggle, Input, Button } from 'framework7-react';
import { ReactiveComponent, observer } from '@/utils/State';
import { autoBind, getDaysDelta, showError } from '@/utils/GeneralUtils';
import _ from 'lodash';
import APIService from '@/services/APIService';
import { format } from 'date-fns';
import LongTermGoals from '@/assets/LongTermGoals';
import './goal-details-popup.scss';

class GoalDetailsPopup extends ReactiveComponent {
	constructor(props) {
		super(props);
		this.setData({
			goal: null,
			completions: null
		});
		autoBind(this);
	}

	handlePopupOpen() {
		let { goal } = this.props;
		this.data.goal = _.cloneDeep(goal);
		this.loadGoalCompletions();
	}

	componentWillReceiveProps(nextProps) {
		let { opened } = this.props;
		if (opened === nextProps.opened && this.props.goal) {
			this.data.goal = _.cloneDeep(this.props.goal);
		}
	}

	loadGoalCompletions() {
		if (this.data.goal?.deadline?.repeatable) {
			APIService.getGoalCompletions(this.data.goal.shortTermGoalId)
				.then((res) => {
					this.data.completions = res.count > 0 ? res.count : null;
					f7.dialog.close();
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}

	updateGoalStatus(id, completed) {
		f7.dialog.preloader('Loading...');
		APIService.updateGoalStatus(id, completed)
			.then(async (res) => {
				this.data.goal = res;
				if (res.deadline?.repeatable) {
					this.loadGoalCompletions();
				} else {
					f7.dialog.close();
				}
			})
			.catch((err) => {
				console.log(err);
				showError(err, 'Unable to update goal');
			});
	}

	deleteGoal(goal) {
		f7.dialog.preloader('Deleting...');
		APIService.deleteGoal(goal.shortTermGoalId)
			.then(() => {
				f7.dialog.close();
				var toast = f7.toast.create({
					text: 'Success!',
					position: 'center',
					closeTimeout: 2000
				});
				toast.open();
				this.data.goal = null;
				this.data.completions = null;
				this.props.onPopupClosed();
			})
			.catch((err) => {
				showError(err, 'Unable to delete goal, please try again.');
			});
	}

	deleteEntireGoal(goal) {
		f7.dialog.preloader('Deleting...');
		APIService.deleteEntireGoal(goal.shortTermGoalId)
			.then(() => {
				f7.dialog.close();
				var toast = f7.toast.create({
					text: 'Success!',
					position: 'center',
					closeTimeout: 2000
				});
				toast.open();
				this.data.goal = null;
				this.data.completions = null;
				this.props.onPopupClosed();
			})
			.catch((err) => {
				showError(err, 'Unable to delete goal, please try again.');
			});
	}

	deleteGoalPrompt(goal) {
		//* Simple case yes no confirmation
		if (!goal.deadline.repeatable) {
			const prompt = f7.dialog.create({
				text: `Are you sure you want to delete this Short-term goal? You can't undo this action.`,
				destroyOnClose: true,
				buttons: [
					{
						text: 'Cancel',
						onClick: () => {}
					},
					{
						text: 'Delete Goal',
						onClick: () => this.deleteEntireGoal(goal)
					}
				]
			});
			prompt.open();
		} else {
			const prompt = f7.dialog.create({
				text: `Are you sure you want to delete this Short-term goal? You can't undo this action.`,
				destroyOnClose: true,
				verticalButtons: true,
				buttons: [
					{
						text: 'Delete This Goal',
						onClick: () => this.deleteGoal(goal)
					},
					{
						text: 'Delete This and Following Goals',
						onClick: () => this.deleteEntireGoal(goal)
					},
					{
						text: 'Cancel',
						onClick: () => {}
					}
				]
			});
			prompt.open();
		}
	}

	Header() {
		return (
			<Link
				className="header-ctn hbox hright"
				popupClose
				onClick={() => {
					this.data.goal = null;
					this.data.completions = null;
				}}
			>
				<div className="settings-btn">
					<i className="bx bx-x"></i>
				</div>
			</Link>
		);
	}

	OverviewSection() {
		let { goal, completions } = this.data;
		if (!goal) {
			return;
		}
		let { body, measurements, deadline, longTermParentId, status } = goal;

		const getCadence = () => {
			let { frequency, cadence } = deadline.repeat;
			if (frequency === 'week') {
				let { monday, tuesday, wednesday, thursday, friday, saturday, sunday } = cadence;
				let msg = `${monday ? 'Monday, ' : ''}${tuesday ? 'Tuesday, ' : ''}${wednesday ? 'Wednesday, ' : ''}${
					thursday ? 'Thursday, ' : ''
				}${friday ? 'Friday, ' : ''}${saturday ? 'Saturday, ' : ''}${sunday ? 'Sunday, ' : ''}`;
				return msg.slice(0, msg.length - 2);
			}
			return frequency;
		};

		let daysDelta = deadline.deadlineDate ? getDaysDelta(new Date(), new Date(deadline.deadlineDate)) : 0;
		let overdue = daysDelta > 0 ? true : false;
		let dueMessage = null;
		if (overdue) {
			dueMessage = `${daysDelta} ${daysDelta === 1 ? 'Day' : 'Days'} Overdue. ${
				deadline.repeatable ? 'Did you do it?' : 'Need more time?'
			}`;
		} else if (daysDelta === 0) {
			dueMessage = 'Due Today';
		}

		return (
			<>
				{completions !== null && (
					<div className="goal-card hbox vcenter">
						<div className="count">{completions}</div>
						<div className="messagebox vbox vcenter">
							<div className="text1">{completions} Completion(s)</div>
							<div className="text2">Recurring goal streaks to date</div>
						</div>
					</div>
				)}
				<h3>Overview</h3>
				<div className="goal-card">
					<div className="label">MEASURABLE(S)</div>
					<div className="value">
						{`${measurements.map((m) => {
							return ` ${m.amount} ${m.unit}`;
						})}`.trim()}
					</div>
					<div className="divider"></div>
					<div className="label">TIME BOUND</div>
					<div className="value">
						{!deadline.repeatable ? (
							format(new Date(deadline.deadlineDate), 'MM/dd/yy')
						) : (
							<>
								<strong>Repeats</strong> every {getCadence()}
							</>
						)}
						{deadline.repeat?.endDate && (
							<div>
								<strong>Until</strong> {format(new Date(deadline.repeat?.endDate), 'MM/dd/yy')}
							</div>
						)}
					</div>
				</div>
				<h3>Goal Details</h3>
				<div className="goal-card">
					<div className="label">RELATED LONG TERM GOAL</div>
					<div className="value hbox vcenter ltg">
						<img src={LongTermGoals[longTermParentId]?.icon} alt="Long term goal icon" />
						{LongTermGoals[longTermParentId]?.name || `Unknown Long Term Goal ${longTermParentId}`}
					</div>
					<div className="divider"></div>
					<div className="label">GOAL DETAILS</div>
					<div className="value">{_.isEmpty(body) ? 'No Details Recorded' : body}</div>
				</div>
				{dueMessage && status !== 'completed' && <div className={`due-message ${overdue ? 'red' : ''}`}>{dueMessage}</div>}
				{status === 'completed' ? (
					<div className="vbox vcenter hcenter btn-ctn">
						<div className="due-message completed">Completed</div>
						<Button
							className="step-btn vbox vcenter hcenter"
							onClick={() => {
								this.props.onDoItAgain(_.cloneDeep(this.data.goal));
							}}
						>
							Do it Again
						</Button>
						<div className="grow-1"></div>
					</div>
				) : (
					<div className="vbox vcenter hcenter btn-ctn">
						<Button
							className="step-btn vbox vcenter hcenter"
							onClick={() => {
								this.props.onEdit(_.cloneDeep(this.data.goal));
							}}
						>
							Edit Goal
						</Button>
						<Button
							className="step-btn vbox vcenter hcenter"
							onClick={() => {
								this.updateGoalStatus(goal.shortTermGoalId, true);
							}}
						>
							Mark as Complete
						</Button>
						<Button
							className="step-btn vbox vcenter hcenter"
							onClick={() => {
								this.deleteGoalPrompt(goal);
							}}
						>
							Delete Goal
						</Button>
						<div className="grow-1"></div>
					</div>
				)}
			</>
		);
	}

	render() {
		let { onPopupClosed, opened, goal } = this.props;
		return (
			<Popup
				className={`goal-details-popup  ${!appStore.isMobile ? 'desktop' : 'mobile'}`}
				opened={opened}
				closeByBackdropClick={false}
				closeOnEscape={false}
				onPopupClosed={onPopupClosed}
				onPopupOpened={this.handlePopupOpen}
			>
				{opened && goal && (
					<div className="content-ctn y-scroll">
						{this.Header()}
						<div className="tag-ctn">
							<div className={`goal-type ${goal.deadline.repeatable === false ? 'onetime' : 'repeatable'}`}>
								{!goal.deadline.repeatable
									? 'ONE TIME'
									: goal.deadline.repeat.frequency == 'day'
									? 'DAILY'
									: goal.deadline.repeat.frequency == 'week'
									? 'WEEKLY'
									: 'MONTHLY'}
							</div>
							{goal.realLifeEvent && <div className="goal-type irl">IRL EVENT</div>}
						</div>
						<h1 className="text-gradiant">{this.data.goal?.summary}</h1>
						{this.OverviewSection()}
					</div>
				)}
			</Popup>
		);
	}
}

export default observer(GoalDetailsPopup);
