import React from 'react';
import { App, f7ready, View } from 'framework7-react';
import routes from '../js/routes';
import AuthService from '@/services/AuthService';
import appStore from '@/stores/AppStore';
import { Workbox } from 'workbox-window';

const MyApp = () => {
	// Framework7 Parameters
	const f7params = {
		name: 'BehaVR', // App name
		theme: 'auto', // Automatic theme detection
		// App routes
		routes: routes,
		// Register service worker
		serviceWorker:
			process.env.NODE_ENV === 'production'
				? {
						path: '/service-worker.js'
				  }
				: {}
	};

	f7ready((f7) => {
		if (!window.orientationListener) {
			window.orientationListener = window.addEventListener('orientationchange', function (event) {
				console.log('the orientation of the device is now ' + event.target.screen.orientation.angle);
			});
		}
		AuthService.init();
		console.log('Init Update Prompt!');
		if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
			const wb = new Workbox('/service-worker.js');
			const refreshPage = () => {
				//* set up a listener that will reload the page as soon as the previously waiting service worker has taken control.
				wb.addEventListener('controlling', (event) => {
					window.location.reload();
				});
				wb.messageSkipWaiting();
			};
			const showSkipWaitingPrompt = (event) => {
				f7.dialog.alert('In order to update to the latest version we need to refresh the page.', 'An Update is Ready!', refreshPage);
			};

			// Add an event listener to detect when the registered
			// service worker has installed but is waiting to activate.
			wb.addEventListener('waiting', showSkipWaitingPrompt);

			wb.register();
		}
	});

	return (
		<App {...f7params} className={`${appStore.isPWA() && appStore.deviceDetails.iphone ? 'progressive-web-app' : ''}`}>
			{/* If we want hash routing browserHistory={true} */}
			<View main className="safe-areas" url="/" reloadPages={true} />
		</App>
	);
};
export default MyApp;
