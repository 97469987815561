import appStore from '@/stores/AppStore';
import React, { Component } from 'react';
import { Button, Popup } from 'framework7-react';
class TermsPopup extends Component {
	render() {
		let { onPopupClosed, opened, secondaryClick, primaryClick } = this.props;
		return (
			<Popup
				className={`terms-popup ${!appStore.isMobile ? 'desktop' : 'mobile'}`}
				opened={opened}
				closeByBackdropClick={false}
				closeOnEscape={false}
				onPopupClosed={onPopupClosed}
				backdrop={false}
			>
				<div className="terms-content y-scroll">
					<div>
						<center>
							<strong>Terms and Conditions</strong>
						</center>
					</div>
					<div>
						<center>
							<p>
								<strong>BehaVR, LLC </strong>
							</p>
							<p>
								<strong>END USER LICENSE AGREEMENT</strong>
							</p>
						</center>
						<p>
							<strong>Last Updated</strong>: August 10, 2020
						</p>
						<p>
							This Mobile Application End User License Agreement (“<u>License Agreement</u>”) is a binding agreement between BehaVR,
							LLC (“<u>BehaVR</u>,” “<u>we</u>,” “<u>us</u>,” or “<u>our</u>”) and you (“<u>you</u>,” “y<u>our</u>,” or “
							<u>end user</u>”). This License Agreement governs your use of the
							<strong>
								<sup>[BehaVR Experience]</sup>
							</strong>
							, an educational virtual reality experience designed to help you learn stress resilience, emotion regulation skills,
							mindfulness, and certain aspects of common behavioral health challenges, during which you will view and experience
							virtually enhanced images and sounds by utilizing a virtual reality headset (together with all related documentation, the
							“<u>Application</u>”).
						</p>
						<p>
							The Application is made available through third-party application stores and is available for download, installation, and
							use on a mobile phone or tablet device (“<u>Mobile Device</u>”). For purposes of clarity, THIS APPLICATION IS LICENSED,
							<strong>NOT SOLD</strong>, TO YOU. Read this License Agreement carefully before you begin using the Application. The
							terms contained herein apply to all end users of the Application.
						</p>
						<p>
							BY<strong> </strong>CLICKING “I AGREE” OR BY DOWNLOADING, INSTALLING, OR USING THE APPLICATION, YOU: (A) ACKNOWLEDGE THAT
							YOU HAVE READ AND THAT YOU UNDERSTAND THIS LICENSE AGREEMENT; (B) REPRESENT THAT YOU ARE EIGHTEEN (18) YEARS OF AGE OR
							OLDER; AND (C) ACCEPT THIS LICENSE AGREEMENT AND ACKNOWLEDGE THAT YOU OR YOUR PARENT ARE LEGALLY BOUND BY ITS TERMS. IF
							YOU DO NOT AGREE TO THESE TERMS, DO NOT DOWNLOAD, INSTALL, OR USE THE APPLICATION. IF YOU HAVE ALREADY DOWNLOADED AND/OR
							INSTALLED THE APPLICATION, BUT YOU DO NOT AGREE TO THIS LICENSE AGREEMENT, THEN YOU SHOULD DELETE THE APPLICATION FROM
							YOUR MOBILE DEVICE.
						</p>
						<p>
							Note that the License Agreement may be updated from time to time, and any end user’s continued use of the Application
							after we have made updates to the terms shall be considered acceptance of those updates. For clarity, all updates are
							effective immediately when posted. It is your responsibility to check the License Agreement periodically for updates.
						</p>
						<ol>
							<li>
								<u>Health and Safety</u>. You acknowledge and agree that, by accessing or using the Application or participating in any
								activities or experiences offered on the Application, you may experience adverse side effects, including without
								limitation, symptoms similar to motion sickness; dizziness; fatigue; disorientation; eye strain; impaired hand-eye
								coordination; altered, blurred, or double vision or other visual abnormalities; loss of awareness; impaired balance;
								nausea; lightheadedness; and discomfort or pain in the head or eyes. You understand that some people (approximately one
								in four thousand) may experience SEVERE DIZZINESS, SEIZURES, EYE OR MUSCLE TWITCHING OR BLACKOUTS TRIGGERED BY LIGHT
								FLASHES OR PATTERNS while experiencing virtual reality, even if they have no history of seizures or epilepsy.
							</li>
						</ol>
						<p>
							<strong>
								You acknowledge that the Application is not intended for anyone who is tired; needs sleep; is under the influence of
								alcohol or drugs; is hung-over; has digestive problems; or is suffering from cold or flu symptoms, headaches,
								migraines, or earaches, as these conditions can increase your susceptibility to adverse symptoms. Additionally, you
								acknowledge that you should consult with your doctor before using the Application if you are pregnant, elderly, have a
								pacemaker or other implanted medical device, have pre-existing binocular vision abnormalities, or psychiatric
								disorders, have a history of seizures or epilepsy, or suffer from a heart condition or other serious medical condition.
							</strong>
						</p>
						<p>
							During this experience you will not be able to see the physical space around you, and there is a risk of colliding with
							objects in your vicinity such as chairs, tables, lamps, pets and people. THE PROPER USE OF THIS EXPERIENCE IS SEATED IN A
							COMFORTABLE CHAIR AND TO REMAIN SEATED DURING THE EXPERIENCE.
						</p>
						<p>
							By accessing or using the application or participating in any activities or experiences offered on the Application, you,
							on your own behalf or, as applicable, on behalf of your child, represent and warrant that:
						</p>
						<ol>
							<li>
								You fully understand that the Application includes the risk of accidental physical injury or death, and the risk of
								adverse side effects that may be serious and may result in injury or death;
							</li>
						</ol>
						<li>
							You knowingly assume all of the risks associated with the Application and take full responsibility for any damages,
							liabilities, losses, or expenses incurred by you in connection with the Application; and
						</li>

						<li>
							YOU WAIVE, RELEASE, COVENANT NOT TO SUE, AND FOREVER DISCHARGE ANY CLAIMS AGAINST BEHAVR AND ITS OWNERS, CO-VENTURERS,
							FINANCING SOURCES, PARTNERS, OFFICERS, DIRECTORS, MANAGERS, EMPLOYEES, AGENTS, AFFILIATES, SUCCESSORS, ASSIGNS,
							CONTRACTORS, AND LICENSORS (“<u>REPRESENTATIVES</u>”) ARISING OUT OF OR IN CONNECTION WITH THE APPLICATION, INCLUDING
							WITHOUT LIMITATION, PERSONAL, BODILY, OR MENTAL INJURY, AND ECONOMIC LOSS, AND YOU SHALL INDEMNIFY, DEFEND, AND HOLD
							HARMLESS BEHAVR AND ITS REPRESENTATIVES FROM AND AGAINST ANY HARM, INJURY, DAMAGE, CLAIMS, DEMANDS, ACTIONS, CAUSES OF
							ACTION, COSTS, AND EXPENSES OF ANY NATURE THAT YOU OR YOUR ASSIGNS, HEIRS, NEXT OF KIN, SPOUSE, OR LEGAL REPRESENTATIVES
							MAY HAVE OR THAT MAY HEREAFTER ACCRUE ARISING OUT OF OR IN CONNECTION WITH THE APPLICATION. FOR CLARITY, NEITHER BEHAVR
							NOR ITS REPRESENTATIVES SHALL BE LIABLE FOR ANY DAMAGES, LIABILITIES, LOSSES, OR EXPENSES INCURRED IN CONNECTION WITH THE
							APPLICATION, AND SHALL BE UNDER NO OBLIGATION TO MONITOR, SUPERVISE, OR OTHERWISE OVERSEE THE APPLICATION.
						</li>

						<p>
							This provision is intended to be as broad and inclusive as permitted by applicable law, and you agree that if a portion
							of this
							<u>Section 1</u> is held invalid, the balance shall remain in full force and effect. You understand that BehaVR is
							relying on the representations and agreements in this <u>Section 1</u> in agreeing to allow you to use the Application.
							You acknowledge that you have given up substantial rights by making the foregoing representations and agreements, and you
							accept them freely and voluntarily, on your own behalf or on behalf of your child, without inducement. If you do not
							agree to this <u>Section 1</u>, you may not access or use the Application or participate in any activities or experiences
							offered on the Application.
						</p>
						<p>
							For information about the VR Headset, itself, visit the Pico Safety Center, at
							<u>https://www.pico-interactive.com/us/terms/user_safety.html</u>, for more information on the safe use of your VR
							system.
						</p>
						<ol>
							<li>
								<u>Not Medical Advice</u>. You understand that any information or advice provided in connection with the Application is
								not meant to be medical advice, and you acknowledge and agree that you are not using or relying upon it as such. You
								should consult your physician or other health care professional before making any lifestyle or behavior changes or
								discontinuing any prescription medications that may affect any medical plan of care, as established by your physician
								or other health care professional.
							</li>
							<li>
								<u>Scope of License Grant</u>. Subject to the terms of this License Agreement, BehaVR grants you a limited,
								non-exclusive, and nontransferable license to download, install, and use the Application for your personal,
								non-commercial use on a single Mobile Device, strictly in accordance with this License Agreement. This License
								Agreement does not allow you to use the Application on any electronic device that is not owned or otherwise controlled
								by you. This License Agreement shall govern any upgrades provided by BehaVR to the Application, unless otherwise
								specified by BehaVR at the time the upgrade is made available to an end user.
							</li>
							<li>
								<u>Term</u>. This License Agreement shall commence on the date that you install the Application and is perpetual until:
								(a) you or BehaVR provides to the other a notification of an intent to terminate this License Agreement; (b) the end
								user ceases use of and deletes and/or otherwise removes the Application from the end user’s Mobile Device; or (c) the
								License Agreement otherwise terminates pursuant to
								<u>Section 10</u> below.
							</li>
							<li>
								<u>End User Restrictions</u>. The Application may be used and accessed for lawful purposes only. You agree to abide by
								all applicable local, state, national, and foreign laws, treaties, and regulations in connection with your use of the
								Application and its content. In addition, without limitation, you agree that you shall not:
							</li>
							<ol>
								<li>
									Download and/or install the Application onto a Mobile Device that is not owned or otherwise controlled by the end
									user;
								</li>
								<li>
									In any manner transmit or submit any content to which you do not have the lawful right to copy, transmit, and display
									(including any content that would violate any confidentiality or fiduciary obligations that you might have with
									respect to the content);
								</li>
								<li>
									In any manner transmit or submit any content that infringes the intellectual property rights or violates the privacy
									rights of any third party (including, without limitation, copyright, trademark, patent, trade secret, or other
									intellectual property right, or moral right, or right of publicity);
								</li>
								<li>
									In any manner transmit or submit harmful, threatening, abusive, harassing, defamatory, deceptive, fraudulent,
									obscene, indecent, vulgar, lewd, violent, hateful, or otherwise objectionable content or material;
								</li>
								<li>
									Copy, reproduce, republish, upload, post, transmit, or distribute the Application, except as expressly permitted by
									this License Agreement;
								</li>
								<li>Share or sell information derived from or related to the Application, or any content thereof;</li>
								<li>
									Rent, lease, lend, sell, sublicense, assign, distribute, re-distribute, publish, transfer, or otherwise make
									available the Application, or any features or functionality of the Application, to any third party for any reason,
									including but not limited to, making the Application available on a network where it is capable of being accessed by
									more than one (1) Mobile Device at any time;
								</li>
								<li>
									Modify, translate, adapt, or otherwise create derivative works or improvements, whether or not patentable, of the
									Application;
								</li>
								<li>
									Reverse engineer, disassemble, decompile, decode, or otherwise attempt to derive or gain access to the source code of
									the Application or any part thereof;
								</li>
								<li>
									Remove, disable, circumvent, or otherwise create or implement any workaround to any copy protection, rights
									management, or security features in or protecting the Application;
								</li>
								<li>
									Transmit or upload any material to the Application that contains viruses, trojan horses, worms, time bombs, or any
									other harmful or deleterious programs;
								</li>
								<li>
									Remove, delete, alter, or obscure any trademarks or any copyright, trademark, patent, or other intellectual property
									or proprietary rights notices from the Application, including any copy thereof; or
								</li>
								<li>
									USE THE APPLICATION IN, OR IN ASSOCIATION WITH, THE DESIGN, CONSTRUCTION, MAINTENANCE, OR OPERATION OF ANY HAZARDOUS
									ENVIRONMENTS OR SYSTEMS, INCLUDING ANY POWER GENERATION SYSTEMS; AIRCRAFT NAVIGATION OR COMMUNICATION SYSTEMS, AIR
									TRAFFIC CONTROL SYSTEMS, OR ANY OTHER TRANSPORT MANAGEMENT SYSTEMS; ANY SAFETY-CRITICAL APPLICATIONS, INCLUDING
									MEDICAL OR LIFE-SUPPORT SYSTEMS, VEHICLE OPERATION APPLICATIONS OR ANY POLICE, FIRE, OR OTHER SAFETY RESPONSE
									SYSTEMS; AND ANY MILITARY OR AEROSPACE APPLICATIONS, WEAPONS SYSTEMS, OR ENVIRONMENTS.
								</li>
							</ol>
						</ol>
						<p>
							ANY ATTEMPT TO DO ANY OF THE FOREGOING PROHIBITED ACTS OR TO OTHERWISE UNDERMINE THE OPERATION OF THE APPLICATION MAY BE
							A VIOLATION OF CRIMINAL AND CIVIL LAW. SHOULD SUCH AN ATTEMPT BE MADE, WE RESERVE THE RIGHT, IN ADDITION TO OUR OTHER
							REMEDIES, TO SEEK DAMAGES (INCLUDING WITHOUT LIMITATION ATTORNEYS’ FEES) FROM ANY SUCH INDIVIDUAL OR ENTITY TO THE
							FULLEST EXTENT PERMITTED BY LAW.
						</p>
						<p>
							We reserve the right, in our sole discretion, to audit or otherwise monitor any communication transmitted using the
							Application. We further reserve the right at all times to review, retain, and/or disclose any information as necessary to
							satisfy any applicable law, regulation, legal process, governmental request, or business assessment. NOTWITHSTANDING THE
							FOREGOING, WE HEREBY DISCLAIM ANY OBLIGATION TO MONITOR USE OF THIS APPLICATION OR TO RETAIN THE CONTENT ON THIS
							APPLICATION UNLESS OTHERWISE AGREED OR REQUIRED BY LAW.
						</p>
						<ol>
							<li>
								<u>Intellectual Property</u>. You DO NOT acquire any ownership interest in the Application under this License
								Agreement, or any other rights thereto, other than to use the Application in accordance with the limited license
								granted hereunder, and subject to all terms, conditions, and restrictions, under this License Agreement. BehaVR
								reserves and shall retain its entire right, title, and interest in and to the Application, including all patent,
								copyright, trademark, trade secret, and other intellectual property rights therein or relating thereto, except as
								expressly granted to You under these terms.
							</li>
						</ol>
						<p>
							All information, feedback, suggestions, ideas, and other submissions disclosed, submitted, or offered to us or otherwise
							disclosed, submitted, or offered concerning the Application in connection with your use of the Application (collectively,
							“<u>Feedback</u>”) will be BehaVR’s property, whether provided through an Application form, an e-mail, a written letter,
							a phone call, or other. Such disclosure, submission, or offer of any Feedback will constitute an assignment to BehaVR of
							all worldwide rights, titles, and interests in all copyrights and other intellectual property in the Feedback. BehaVR
							will be under no obligation to (i) maintain any Feedback in confidence; (ii) pay any compensation for any Feedback; or
							(iii) respond to any Feedback.
						</p>
						<ol>
							<li>
								<u>Collection and Use of Your Information; Consent</u>. You acknowledge that when you download, install, and/or
								subsequently use this Application, we will collect information about you and about your Mobile Device. We may use
								automatic means (by way of example only, phone number tracking, geolocation software, cookies, web beacons, and/or
								software command scripts) to collect information about your use of the Application such as technical data, information
								about your Mobile Device, system software, and peripherals.
							</li>
						</ol>
						<p>
							Additionally, you may provide, and BehaVR may collect, certain personal information about you and your experiences in
							order to customize your experience on the Application and to better understand and improve the Application for all end
							users. Such personal information may include, without limitation, demographic information (such as name, date of birth,
							ethnicity, and mobile telephone number), biometric information (such as heart rate, skin temperature, and galvanic skin
							response), and responses to questions related to your health history and motivation to make changes or improve your
							health.
						</p>
						<p>
							You acknowledge and agree that BehaVR may collect and use such information in accordance with the terms set forth in our
							Privacy Policy [
							<strong>
								<a href="https://behavr.com/privacy">https://behavr.com/privacy</a>
							</strong>
							], which may include sharing such information with BehaVR’s affiliates and with certain vendors, service providers,
							researchers, and other partners who support the equipment or services provided in connection with the Application. You
							hereby authorize BehaVR to use and disclose your information for such purposes. You understand that the information
							obtained about you will be held in confidence and will not be used or disclosed without your permission except as
							described in this
							<u>Section 7</u> or the Privacy Policy, or as otherwise required by law. Notwithstanding the foregoing, you further
							acknowledge and agree that BehaVR and its affiliates may use and disclose for any lawful purpose any information that
							cannot reasonably be used to identify you, even if such information has been derived from your personal information.
							BehaVR shall own such information, and you shall have no right, title, or ownership interest in or to any such
							information.
						</p>
						<p>
							For purposes of clarity, ANY AND ALL INFORMATION THAT WE COLLECT THROUGH OR IN CONNECTION WITH THIS APPLICATION, IS
							SUBJECT TO OUR PRIVACY POLICY. By downloading, installing, using, or providing information to or through this
							Application, you consent to all actions taken by us with respect to your information in compliance with our Privacy
							Policy and all applicable laws and regulations.
						</p>
						<ol>
							<li>
								<u>Updates</u>. From time to time, in its sole discretion, BehaVR may develop and make available software updates to
								the Application, which could include upgrades, bug fixes, patches, error corrections, new features, and/or modification
								or deletion of existing features and functionality (collectively<strong>, </strong>including related documentation, “
								<u>Update(s)</u>”). You acknowledge and agree that BehaVR has no obligation to provide any Updates or to continue to
								provide or enable any particular features or functionality. Updates shall be delivered to you based on the Mobile
								Device settings, when the Mobile Device is connected to the Internet, by either: (a) an automatic Update initiated by
								the Application, which shall download and install any available Update; or (b) a notification Update in which you
								receive notice of the availability of an Update prior to choosing to download and install the Update accordingly. Your
								decision not to Update, or your failure to promptly update the Application may result in the inability of the
								Application, or portions thereof, to operate properly. YOU HEREBY ACKNOWLEDGE AND AGREE THAT ALL UPDATES WILL BE DEEMED
								TO BE PART OF THE APPLICATION AND, AS SUCH, ALL UPDATES ARE SUBJECT TO THE TERMS AND CONDITIONS OF THIS LICENSE
								AGREEMENT.
							</li>
							<li>
								<u>Geographic Restrictions</u>. BehaVR is based in the United States, and the Application is provided for access and
								use ONLY BY PERSONS LOCATED IN THE UNITED STATES. Anyone outside the United States may not be able to access certain
								features of the Application, and such access may not be legal by certain persons or in certain countries. If you access
								the Application from outside the United States, you are responsible for compliance with local laws. By using this
								Application, you represent and warrant that you are a lawful end user of this Application.
							</li>
							<li>
								<u>Your Termination Requirements; BehaVR Termination Rights</u>. The term of this License Agreement commences and
								continues as set forth in
								<u>Section 4</u>. When this License Agreement is terminated, you agree that you shall delete the Application and all
								copies thereof from your Mobile Device. Notwithstanding the provisions of<i> </i>
								<u>Section 4</u>, BehaVR may terminate this License Agreement at any time without notice and without any obligations to
								you whatsoever. In addition, this License Agreement will terminate immediately and automatically without any notice, if
								you violate any of the terms and conditions of this License Agreement; provided, however, that the restrictions and
								obligations hereunder shall continue to apply to you as long as you continue to access and use the Application. Upon
								termination: (i) all rights granted to you under this License Agreement will also terminate; and (ii) you must cease
								all use of the Application and delete all copies of the Application from your Mobile Device. TERMINATION WILL NOT LIMIT
								ANY OF BEHAVR’S RIGHTS OR REMEDIES AT LAW OR IN EQUITY.
							</li>
							<li>
								<u>Disclaimer of Warranties</u>. THIS APPLICATION IS PROVIDED TO YOU “AS IS,” “AS AVAILABLE,” AND “WITH ALL FAULTS AND
								DEFECTS,” WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, BEHAVR EXPRESSLY
								DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, WITH RESPECT TO THE APPLICATION, INCLUDING
								ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, FREEDOM FROM DEFECTS, AND
								NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE, OR TRADE
								PRACTICE. BEHAVR MAKES NO WARRANTIES WITH REGARD TO THE ACCURACY, RELIABILITY, COMPLETENESS, QUALITY, FUNCTIONALITY,
								TIMELINESS, SPEED, OR ACCESSIBILITY OF ANY INFORMATION SUPPLIED, OR IN CONNECTION WITH THE SERVICES OFFERED WITH THE
								APPLICATION. BEHAVR DOES NOT WARRANT THAT THE APPLICATION WILL BE OPERATIONAL, SECURE, ERROR-FREE, OR VIRUS-FREE. YOU
								FURTHER ACKNOWLEDGE THAT BEHAVR MAKES NO REPRESENTATION, CLAIM, OR GUARANTEE REGARDING ANY PARTICULAR RESULTS OR
								OUTCOMES FROM PARTICIPATING IN THE EXPERIENCE. YOU ACKNOWLEDGE AND AGREE THAT USE OF THE APPLICATION IS AT YOUR SOLE
								RISK AND YOU RECOGNIZE THAT BEHAVR DOES NOT WARRANT AGAINST INTERFERENCE WITH YOUR ENJOYMENT OF THE APPLICATION, OR
								THAT THE FUNCTIONS CONTAINED IN, OR SERVICES PERFORMED BY, THE APPLICATION WILL MEET YOUR REQUIREMENTS.
							</li>
						</ol>
						<p>
							We reserve the right to modify the Application in our sole discretion without notice. We will not be liable if, for any
							reason, any part of the Application or the entire Application is unavailable for any period of time. Periodically, we may
							restrict access to portions of the Application or the entire Application. We may make these modifications at any time and
							for any reason without prior notice. You assume any and all risk for decisions based on information contained within this
							Application. The information presented on or through the Application is made available solely for general information
							purposes. We do not warrant the accuracy, completeness, or usefulness of this information. Any reliance you place, or
							decisions you make, on such information is strictly at your own risk. We disclaim all liability and responsibility
							arising from any reliance placed on these Application materials by you or any other user of the Application, or by anyone
							who may be informed of any of its contents.
						</p>
						<ol>
							<li>
								<u>Limitation of Liability</u>. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU HEREBY RELEASE BEHAVR FROM ALL
								LIABILITY ASSOCIATED WITH YOUR USE OF THE APPLICATION. IN NO EVENT SHALL BEHAVR BE LIABLE FOR PERSONAL INJURY, DIRECT
								DAMAGES, OR ANY INCIDENTAL, SPECIAL, INDIRECT, CONSEQUENTIAL, OR PUNITIVE DAMAGES, WHATSOEVER, INCLUDING WITHOUT
								LIMITATION, DAMAGES FOR LOSS OF PROFITS, LOSS OF DATA, BUSINESS INTERRUPTION, LOSS OF GOODWILL, COMPUTER FAILURE OR
								MALFUNCTION, OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO YOUR USE, OR INABILITY TO USE, THE
								APPLICATION, HOWEVER CAUSED, REGARDLESS OF THE THEORY OF LIABILITY (WHETHER IN TORT, CONTRACT, OR OTHERWISE),
								REGARDLESS IF BEHAVR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
							</li>
							<li>
								<u>Indemnification</u>.<strong>YOU HEREBY AGREE TO INDEMNIFY, DEFEND, AND HOLD HARMLESS </strong>BehaVR and its
								Representatives and their respective officers, directors, employees, agents, shareholders, members, managers,
								contractors, licensors, and any information providers, from and against any and all third-party demands, claims,
								actions, proceedings, damages, liabilities, losses, fees, costs or expenses (
								<strong>including without limitation reasonable attorneys’ fees</strong>
								and the costs of any investigation) arising out of, in connection with, or resulting from any violation or alleged
								violation of or your breach of or default under the terms or conditions of this License Agreement, your use or misuse
								of the Application, or any negligence, gross negligence or willful misconduct by or on behalf of you or your employees
								or agents.
							</li>
							<li>
								<u>Export Regulation</u>. The Application may be subject to U.S. export control laws, including the U.S. Export
								Administration Act and its associated regulations. You shall not, directly or indirectly, export, re-export, or release
								the Application to, or make the Application accessible from, any jurisdiction or country to which export, re-export, or
								release is prohibited by law, rule, or regulation (including but not limited to any U.S.-embargoed countries, to anyone
								on the U.S. Treasury Department’s Specially Designated Nationals List, or the U.S. Department of Commerce Denied
								Persons List or Entity List). You shall comply with all applicable federal laws, regulations, and rules, and complete
								all required undertakings (including obtaining any necessary export license or other governmental approval), prior to
								exporting, re-exporting, releasing, or otherwise making the Application available outside the United States.
							</li>
							<li>
								<u>U.S. Government Rights</u>. The Application is commercial computer software, as such term is defined in 48 C.F.R. §
								2.101. Any end user who is an agency of the U.S. Government, or any contractor therefor, shall receive only those
								rights with respect to the Application as are granted to all other end users under this License Agreement, in
								accordance with (a) 48 C.F.R. §§ 227.7201 - 7204, with respect to the Department of Defense and their contractors, or
								(b) 48 C.F.R. § 12.212, with respect to all other U.S. Government licensees and their contractors.
							</li>
							<li>
								<u>Additional Terms for Users Who Download This Application through the Apple Store</u>. The following additional terms
								apply to you, if you download this Application through the Apple Store:
							</li>
							<ol>
								<li>
									This License Agreement is applicable between BehaVR and you, but not Apple, Inc. (“<u>Apple</u>”). BehaVR, not Apple,
									offers the Application to you, and Apple has no responsibility to you for any use of, or information related to, the
									Application and this License Agreement.
								</li>
								<li>
									Apple has no obligation to provide maintenance and support services with respect to the Application, as used on your
									iPhone or iPod touch Mobile Devices. In the event of any failure of the Application to conform with any applicable
									warranty, you may notify Apple regarding a refund of your purchase price of this Application; Apple shall have no
									other obligations with respect to failures of the Application. For reference, review <u>Section 11</u>, above, for
									our “Disclaimer of Warranties.”
								</li>
								<li>
									Should you have any claim, whether your own claim or whether a third-party claim has been filed against you, relating
									to your possession or use of the Application, Apple shall have no responsibility to you.
								</li>
								<li>
									You and BehaVR hereby acknowledge and agree that Apple and its subsidiaries are third-party beneficiaries of this
									License Agreement. Upon your acceptance of these terms, Apple, as a third-party beneficiary, will receive (and
									otherwise shall be deemed to have received) the right to enforce this License Agreement against you. Additionally,
									you agree to comply with applicable third party terms of agreement when using the Application.
								</li>
								<li>
									You represent and warrant that (a) you are not located in a country that is subject to a United States Government
									embargo, or that has been designated by the United States Government as a “terrorist supporting” country; and (b) you
									are not listed on any United States Government list of prohibited or restricted parties.
								</li>
							</ol>
						</ol>
						<p></p>
						<li>
							<u>Additional Terms for Users Who Download This Application through Google Play</u>. The following additional terms apply
							to you, if you download this Application through Google Play:
						</li>
						<ol>
							<li>
								This License Agreement is applicable between BehaVR and you, but not Google, Inc. (“<u>Google</u>”). BehaVR, not
								Google, offers the Application to you, and Google has no responsibility to you for any use of, or information related
								to, the Application and this License Agreement.
							</li>
							<li>
								Google has no obligation to undertake or provide maintenance or support services with respect to the Application. In
								the event of any defect or performance issue in the Application, the end user should notify BehaVR, not Google. Google
								shall not be responsible to you for any complaints you have regarding this Application, as used on your Android Mobile
								Device.
							</li>
						</ol>
						<li>
							<u>Miscellaneous</u>.
						</li>
						<ol>
							<li>
								<u>Severability</u>. In the event that any provision of this License Agreement, or the application of any provision of
								this License Agreement, is held to be contrary to law by a tribunal or court of competent jurisdiction, the remaining
								provisions of this License Agreement shall continue in full force and effect, and this License Agreement shall be
								interpreted as if such invalid provision was omitted.
							</li>
							<li>
								<u>Governing Law</u>. The construction, interpretation and performance of this License Agreement shall be construed in
								accordance with and governed by the laws of the State of Delaware, and any dispute regarding this License Agreement or
								arising hereunder shall be resolved in the state courts, located in Hardin County, Kentucky or the federal court
								located in the Western District of Kentucky.
							</li>
							<li>
								<u>Limitation of Time to File Claims</u>. ANY CAUSE OF ACTION OR CLAIM AN END USER MAY HAVE ARISING OUT OF OR RELATING
								TO THIS LICENSE AGREEMENT OR THE APPLICATION MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES
								OTHERWISE SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
							</li>
							<li>
								<u>Our Compliance with COPPA</u>. THIS APPLICATION IS NOT INTENDED FOR USERS WHO ARE YOUNGER THAN THIRTEEN (13) YEARS
								OF AGE. You can learn more about our compliance with the Children’s Online Privacy Protection Act (“<u>COPPA</u>”) by
								reviewing our Privacy Policy.
							</li>
							<li>
								<u>Force Majeure</u>. BehaVR will not be liable to you for any failure to perform any of its obligations under this
								License Agreement during any period in which such performance is delayed by circumstances beyond its reasonable
								control, including, but not limited to, disease, outbreak, pandemic, and/or related government recommendations and/or
								orders, fire, flood, war, embargo, strike, riot, unavailability of the Internet, or the intervention of any
								governmental authority.
							</li>
							<li>
								<u>Relationship</u>. No joint venture, partnership, employment, or agency relationship exists between you and BehaVR as
								a result of the License Agreement or your use of the Application.
							</li>
							<li>
								<u>No Waiver</u>. The failure of BehaVR to enforce any right or provision in the License Agreement will not constitute
								a waiver of such right or provision unless acknowledged and agreed to by BehaVR in writing.
							</li>
							<li>
								<u>Entire Agreement</u>. This License Agreement and the associated Privacy Policy constitutes the entire Agreement
								between you and BehaVR, relating to the subject matter hereof.
							</li>
							<li>
								<u>Assignment</u>. An end user may not assign, transfer, or delegate any of its obligations under this License
								Agreement, without the prior written consent of BehaVR. Any attempted assignment, transfer, or other conveyance in
								violation of the foregoing shall be null and void.
							</li>
							<li>
								<u>Notices</u>. Notices given to BehaVR shall be sent to the attention of:
							</li>
						</ol>
						<p>BehaVR, LLC</p>
						<p>1690 Ring Rd., Suite 120</p>
						<p>Elizabethtown, Kentucky 42701</p>
					</div>
				</div>
				<div className="btn-ctn hbox vcenter">
					<Button large className="secondary-btn" onClick={secondaryClick}>
						Decline
					</Button>
					<Button fill large className="primary-btn" onClick={primaryClick}>
						Accept
					</Button>
				</div>
			</Popup>
		);
	}
}

export default TermsPopup;
