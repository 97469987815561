import { Input as F7Input } from 'framework7-react';
import React, { Component } from 'react';
import { autoBind } from '../../utils/GeneralUtils';
import { observer, observable } from '../../utils/State';
import Input from 'react-phone-number-input/input';
import './basic-input.scss';
@observer
export default class BasicInput extends Component {
	constructor(props) {
		super();
		autoBind(this);
	}

	onValidate(data) {
		if (this.props.onValidate) {
			this.props.onValidate(data);
		}
	}

	onFocus(evt) {
		if (this.props.onFocus) {
			this.props.onFocus();
		}
	}

	onBlur() {
		if (this.props.onBlur) {
			this.props.onBlur();
		}
	}

	handleIconClick(evt) {
		if (this.props.iconAction) {
			this.props.iconAction(evt);
		}
	}

	render() {
		let {
			errorMessage,
			label,
			className,
			type,
			placeholder,
			onChange,
			name,
			maxlength,
			minlength,
			min,
			max,
			validate,
			value,
			onKeyDown,
			disabled,
			readOnly,
			icon,
			autoComplete,
			autofocus,
			invalid,
			keyId
		} = this.props;
		return (
			<div className={`basic-input ${className ? className : ''} ${type === 'textarea' && 'textarea'}`}>
				{label && (
					<label htmlFor={keyId} className="label">
						{label}
					</label>
				)}

				<div className={`gradiant ${invalid ? 'invalid' : ''}`}>
					{type === 'phone' ? (
						<Input
							defaultCountry="US"
							name={name}
							value={value || ''}
							placeholder={placeholder}
							disabled={disabled}
							onChange={onChange}
							title={label || 'Phone Input'}
						/>
					) : (
						<F7Input
							className={`${readOnly ? 'read-only' : ''}`}
							type={type}
							inputId={keyId}
							placeholder={placeholder}
							onChange={onChange}
							name={name}
							maxlength={maxlength || 999}
							minlength={minlength || 0}
							min={min || 0}
							max={max || 999}
							onValidate={this.onValidate}
							readonly={readOnly}
							value={value || ''}
							disabled={disabled}
							validate={!!validate}
							onFocus={this.onFocus}
							onBlur={this.onBlur}
							autoComplete={autoComplete || 'off'}
							autofocus={!!autofocus}
							onKeyDown={(e) => {
								if (onKeyDown) {
									onKeyDown(e);
								}
							}}
						/>
					)}

					{icon && (
						<div className="input-icon-holder" onClick={this.handleIconClick}>
							{icon}
						</div>
					)}
				</div>
				<div className="error-message">{errorMessage}</div>
			</div>
		);
	}
}
