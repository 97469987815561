import { getStripeKey } from '@/utils/GeneralUtils';
import { loadStripe } from '@stripe/stripe-js';
import { f7 } from 'framework7-react';
import appStore, { resetAppStore } from '../stores/AppStore';
import APIService from './APIService';
import _ from 'lodash';
import AnalyticsService, { TrackedEvents } from './AnalyticsService';

class AuthService {
	init() {
		firebase.auth().onAuthStateChanged((authUser) => this.handleAuthChange(authUser));
	}

	disableRecaptcha() {
		//Only run on these testing domains && if trigger has been enabled
		if (
			appStore.disableRecaptcha &&
			['localhost', 'dev.behavr.app', 'staging.behavr.app', 'irbehavr.web.app'].indexOf(window.location.hostname) >= 0
		) {
			console.log('Disabling Recaptcha For Automated Testing');
			firebase.auth().settings.appVerificationDisabled = true;
		}
	}

	launchHomeExperience() {
		let hasFirstResort = _.find(_.get(appStore, 'user.productStatus', []), { product: 'first-resort' });
		if (hasFirstResort && hasFirstResort.setup) {
			f7.view?.main?.router?.navigate('/bvr-landing');
		} else {
			f7.view?.main?.router?.navigate('/home');
		}
	}

	async handleAuthChange(authUser) {
		console.log('HANDLE AUTH CHANGE CALLED');
		if (authUser) {
			//get user and check if they need to login
			f7.dialog.preloader('Securing Session');
			const token = await firebase.auth().currentUser.getIdToken(true);
			appStore.userToken = token;
			appStore.tokenRefreshed = new Date().getTime();
			appStore.firebaseUser = firebase.auth().currentUser;
			appStore.stripePromise = loadStripe(getStripeKey());
			const isUserSignedUp = await this.cloudAPILogin();
			if (isUserSignedUp === 'inactive') {
				return;
			}
			if (isUserSignedUp) {
				this.disableRecaptcha();
				f7.dialog.close();
				appStore.isLoggedIn = true;
				//* User is signed up so only entity attached to user can be used
				appStore.referringEntityId = appStore.user.referringEntityId;
				console.log(`Loggin into ${appStore.referringEntityId}`);
				console.log(`Starting Session ${appStore.sessionId}`);
				AnalyticsService.track(TrackedEvents.LOGIN);
				this.launchHomeExperience();
			} else {
				f7.dialog.close();
			}
		} else if (!authUser) {
			console.log('Signing Out User');
			appStore.userToken = null;
		}
	}

	async signOut() {
		AnalyticsService.track(TrackedEvents.LOGOUT);
		firebase.auth().signOut();
		location.reload();
	}

	showInactiveAlert() {
		f7.dialog.alert(
			'Your account has been deactivated, if you believe this was in error please contact our support line at: (866)-923-4287',
			'Error:',
			() => {
				this.signOut();
			}
		);
	}

	async cloudAPILogin() {
		try {
			console.log('Logging into CloudAPI');
			const user = await APIService.login();
			appStore.user = user;
			if (_.isEmpty(user.timezone)) {
				await APIService.updateUserTimezone();
			}
			if (user && user.status === 'inactive') {
				console.warn('Inactive User Attempting to Login');
				f7.dialog.close();
				this.showInactiveAlert();
				return 'inactive';
			}
			if (user && user.signupComplete) {
				appStore.referringEntityId = user.referringEntityId;
				appStore.user = user;
				return true;
			} else if (user && !user.signupComplete && user.existingBehavrUser) {
				//user is behavr data only and they still need to compelte signup but prepop
				console.log(`SETTING EXISTING USER BEFORE REGISTRATION`);
				console.log(JSON.stringify(user, null, 2));
				appStore.existingBehaUser = user;
			}
		} catch (err) {
			console.log(err);
		}
		console.log('User needs to signup');
		return false;
	}

	async sendSigninCode(phone) {
		if (!window.recaptchaListener) {
			this.initRecaptchaListener();
		}
		return new Promise((resolve) => {
			firebase
				.auth()
				.signInWithPhoneNumber(`+1${phone}`, appStore.reCaptchaVerifier)
				.then((confirmationResult) => {
					console.log('Code sent successfully.');
					//this is the object that allows us t0 pass the code to it
					appStore.confirmationResult = confirmationResult;
					resolve({ sent: true });
				})
				.catch((err) => {
					console.log('recaptcha error happened: ', err);
					const errMessage = err.message;
					//example fb block message: We have blocked all requests from this device due to unusual activity. Try again later.
					if (errMessage && errMessage.indexOf('blocked all requests') > -1) {
						f7.dialog.alert(errMessage, () =>
							resolve({
								sent: false,
								errorCode: 'blocked'
							})
						);
					} else {
						resolve({ sent: false, errorCode: err.code });
					}
				});
		});
	}

	async confirmSigninCode(code) {
		return new Promise((resolve) => {
			if (!appStore.confirmationResult) {
				console.log('CODE UNCONFIRMED');
				resolve({ success: false, error: 'no confirmation result' });
			}
			appStore.confirmationResult
				.confirm(code)
				.then(async (result) => {
					const token = await firebase.auth().currentUser.getIdToken(true);
					appStore.userToken = token;
					appStore.tokenRefreshed = new Date().getTime();
					appStore.firebaseUser = firebase.auth().currentUser;
					appStore.confirmationResult = null;
					appStore.isLoggedIn = true;
					this.onRecaptchaClose(true);
					console.log('CODE CONFIRMED');
					resolve({ success: true });
				})
				.catch((err) => {
					console.log(err);
					resolve({ success: false, error: err.toString() });
				});
		});
	}

	initRecaptcha(id) {
		console.log('init recaptcha called: ', id);
		const verifierId = id ? id : 'recaptcha';
		const alreadyRendered = appStore.recaptchaId === verifierId;
		if (!alreadyRendered) {
			appStore.reCaptchaVerifier = new firebase.auth.RecaptchaVerifier(verifierId, {
				size: 'invisible',
				callback: function (response) {
					console.log('Sending Code...');
					window.grecaptcha.reset();
				}
			});
			appStore.recaptchaId = verifierId;
			appStore.reCaptchaVerifier.render();
		}
	}

	initRecaptchaListener() {
		const recaptchaWindow = document.getElementsByTagName('body')[0].lastChild;
		window.recaptchaListener = new MutationObserver((x) => this.onRecaptchaClose(f7, recaptchaWindow.style.visibility === 'hidden'));
		window.recaptchaListener.observe(recaptchaWindow, { attributes: true, attributeFilter: ['style'] });
	}

	onRecaptchaClose(closed) {
		console.log('Recaptcha was closed: ', closed);
		if (closed) {
			window.recaptchaListener.disconnect();
			window.recaptchaListener = null;
			if (!appStore.confirmationResult) {
				console.log('no confirmation result');
				f7.dialog.close();
			} else {
				console.log('has confirmation result');
			}
		}
	}
}

export default new AuthService();
