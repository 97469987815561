import React from 'react';
import { Link, Popup } from 'framework7-react';
import iosSharePNG from '@/assets/iosshare.png';
import iosPWAInstallPNG from '@/assets/iospwaadd.png';
import './ios-install.scss';

export default function IOSInstallPrompt(props) {
	let { opened, onClose } = props;
	return (
		<Popup opened={opened} onPopupClosed={onClose}>
			<div className={`install-pwa-popup y-scroll`}>
				<div className="popup-header hbox vcenter">
					<Link popupClose className="back-btn hbox vcenter">
						<i className="bx bx-arrow-back"></i> Back
					</Link>
					<div className="grow-1"></div>
				</div>
				<h1>How to Install</h1>
				<h3>First click here</h3>
				<img src={iosSharePNG} className="share" alt="iOS Install Instructions" />
				<h3>Once this screen appears</h3>
				<img src={iosPWAInstallPNG} className="install" alt="iOS Install Button" />
				<h3 style={{ color: 'white' }}>Simply click here to complete the installation!</h3>
			</div>
		</Popup>
	);
}
