// Import React and ReactDOM
import React from 'react';
import ReactDOM from 'react-dom';

// Import Framework7
import Framework7 from 'framework7/lite-bundle';

// Import Framework7-React Plugin
import Framework7React from 'framework7-react';

// Import Framework7 Styles
import 'framework7/framework7-bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/boxicons.min.css';
import '../css/app.scss';
import { v4 as uuidv4 } from 'uuid';

// Import App Component
import App from '../components/app.jsx';
import appStore from '@/stores/AppStore';
import { getUrlParams } from '../utils/GeneralUtils';
import _ from 'lodash';
import CacheStorageService from '@/services/CacheStorageService';
const CS = new CacheStorageService();
appStore.deviceDetails = Framework7.device;
appStore.isMobile = !Framework7.device.desktop;
appStore.sessionId = uuidv4();

console.log(`PWA VERSION 1.4`);

if ('virtualKeyboard' in navigator) {
	navigator.virtualKeyboard.overlaysContent = true;
}

// Initialize deferredPrompt for use later to show browser install prompt.
window.deferredPrompt = null;

window.addEventListener('beforeinstallprompt', (e) => {
	// Prevent the mini-infobar from appearing on mobile
	e.preventDefault();
	// Stash the event so it can be triggered later.
	window.deferredPrompt = e;
	// Optionally, send analytics event that PWA install promo was shown.
	console.log(`captured pwa install prompt`);
});

//* ==================================
//* Handle any custom url params
//* ==================================
const urlParams = getUrlParams();
if (!_.isEmpty(urlParams.ref)) {
	appStore.referringEntityId = urlParams.ref;
	const existingValue = await CS.getValue('entities', 'referringEntityId');
	if (!existingValue) {
		console.log('Setting Cached Entity Id');
		CS.saveValue('entities', 'referringEntityId', urlParams.ref);
	} else {
		console.log('Setting Entry Entity');
		appStore.entryEntityId = urlParams.ref;
	}
}
if (!_.isEmpty(urlParams.target)) {
	appStore.bvrTarget = urlParams.target;
}
//* ==================================

const initFirebase = async () => {
	let firebaseConfig;
	switch (window.location.hostname) {
		case 'localhost': {
			firebaseConfig = {
				apiKey: 'AIzaSyBuOheKWnPBzT9yb9QehEH9wMRcB5T9WQo',
				authDomain: 'irbehavr.firebaseapp.com',
				projectId: 'irbehavr',
				storageBucket: 'irbehavr.appspot.com',
				messagingSenderId: '25667246892',
				appId: '1:25667246892:web:f8b4e870f4d8bfcea0ea89'
			};
			break;
		}
		case 'tmppwa.web.app': {
			firebaseConfig = {
				apiKey: 'AIzaSyCJmPnh-pEHfvqSxkfr9DLK0PC3Z9BNV6M',
				authDomain: 'tmppwa.firebaseapp.com',
				projectId: 'tmppwa',
				storageBucket: 'tmppwa.appspot.com',
				messagingSenderId: '604307552423',
				appId: '1:604307552423:web:c154525ec62035875c0382'
			};
			break;
		}
		case 'dev.behavr.app': {
			firebaseConfig = {
				apiKey: 'AIzaSyBzl8Y7ofinXAoZRHeEVJtWFoCkoDlGJnc',
				authDomain: 'behavr-pwa-dev.firebaseapp.com',
				projectId: 'behavr-pwa-dev',
				storageBucket: 'behavr-pwa-dev.appspot.com',
				messagingSenderId: '703407333564',
				appId: '1:703407333564:web:0aa54c8d86e1e557d970b9'
			};
			break;
		}
		case 'staging.behavr.app': {
			firebaseConfig = {
				apiKey: 'AIzaSyA0WobNJLHomZHfgVBhKzk0kwQv9Er9lAU',
				authDomain: 'behavr-pwa-staging-1.firebaseapp.com',
				projectId: 'behavr-pwa-staging-1',
				storageBucket: 'behavr-pwa-staging-1.appspot.com',
				messagingSenderId: '335961187106',
				appId: '1:335961187106:web:3233fb2fde1e878d667b7b'
			};
			break;
		}
		case 'behavr-pwa-dev.web.app': {
			firebaseConfig = {
				apiKey: 'AIzaSyBzl8Y7ofinXAoZRHeEVJtWFoCkoDlGJnc',
				authDomain: 'behavr-pwa-dev.firebaseapp.com',
				projectId: 'behavr-pwa-dev',
				storageBucket: 'behavr-pwa-dev.appspot.com',
				messagingSenderId: '703407333564',
				appId: '1:703407333564:web:0aa54c8d86e1e557d970b9'
			};
			break;
		}
		case 'irbehavr.web.app': {
			firebaseConfig = {
				apiKey: 'AIzaSyBuOheKWnPBzT9yb9QehEH9wMRcB5T9WQo',
				authDomain: 'irbehavr.firebaseapp.com',
				projectId: 'irbehavr',
				storageBucket: 'irbehavr.appspot.com',
				messagingSenderId: '25667246892',
				appId: '1:25667246892:web:f8b4e870f4d8bfcea0ea89'
			};
			break;
		}
		case 'behavr.app': {
			firebaseConfig = {
				apiKey: 'AIzaSyA6eRD0A3fPPhj8wu73IOZTTeHPjNLxmjE',
				authDomain: 'behavr-pwa.firebaseapp.com',
				projectId: 'behavr-pwa',
				storageBucket: 'behavr-pwa.appspot.com',
				messagingSenderId: '86095611049',
				appId: '1:86095611049:web:61f147cbc6aaeb6b0455b8'
			};
			break;
		}
		default: {
			firebaseConfig = {
				apiKey: 'AIzaSyBuOheKWnPBzT9yb9QehEH9wMRcB5T9WQo',
				authDomain: 'irbehavr.firebaseapp.com',
				projectId: 'irbehavr',
				storageBucket: 'irbehavr.appspot.com',
				messagingSenderId: '25667246892',
				appId: '1:25667246892:web:f8b4e870f4d8bfcea0ea89'
			};
			break;
		}
	}
	firebase.initializeApp(firebaseConfig);
};

const getEnvironmentDefaultEntity = () => {
	switch (window.location.hostname) {
		case 'localhost': {
			return null;
		}
		case 'tmppwa.web.app': {
			return null;
		}
		case 'dev.behavr.app': {
			return null;
		}
		case 'staging.behavr.app': {
			return null;
		}
		case 'irbehavr.web.app': {
			return null;
		}
		case 'behavr-pwa-dev.web.app': {
			return null;
		}
		case 'behavr.app': {
			return null;
		}
		default: {
			return null;
		}
	}
};

const initApplication = async () => {
	if (_.isEmpty(appStore.referringEntityId)) {
		console.log('No referring entity in url / appStore, checking cache...');
		const refId = await CS.getValue('entities', 'referringEntityId');
		if (!_.isEmpty(refId)) {
			console.log(`Setting Local Entity Id ${refId}`);
			appStore.referringEntityId = refId;
		} else {
			console.log('Checking for Default Entity Id');
			appStore.referringEntityId = getEnvironmentDefaultEntity();
		}
	}
	//* ============================START=====================================
	//* Special Logic for Direct to Consumer ONLY for nurture at the moment
	//* Localhost *tmp-staging *production
	if (['60ebd101e131a967040b5d9e', '611a83263b73175f63ddf8af', '610afea9a01e672153527171'].indexOf(appStore.referringEntityId) >= 0) {
		console.log('Setting D2C Instance');
		appStore.isD2CInstance = true;
	}
	//* ============================END======================================
	// Init F7 React Plugin
	Framework7.use(Framework7React);
	// Mount React App
	ReactDOM.render(React.createElement(App), document.getElementById('app'));
};
console.log(`============ INITIALIZE APP & FIREBASE =================`);
initFirebase();
initApplication();
