import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind, showError } from '@/utils/GeneralUtils';
import { Button, f7, Link, Popup } from 'framework7-react';
import behavrLogo from '@/assets/logo.png';
import appStore from '@/stores/AppStore';
import Toggle from 'react-toggle';
import './communication.scss';
import 'react-toggle/style.css';
import _ from 'lodash';
import { ProductMap } from '@/components/product-setup-sheet/ProductSetupSheet';
import APIService from '@/services/APIService';

@observer
export default class CommPreferences extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			challengeReminders: true,
			eventCheckIns: true,
			newFeatureUnlocks: true,
			smartGoalReminders: true,
			allChecked: true
		});
		autoBind(this);
	}

	onSavePreferences() {
		console.log('Saving...');
		f7.dialog.preloader('Saving...');
		let prefs = _.cloneDeep(this.data);
		delete prefs.allChecked;
		APIService.saveCommunicationPreferences(this.props.product, prefs)
			.then((res) => {
				f7.dialog.close();
				var toast = f7.toast.create({
					text: 'Success!',
					position: 'center',
					closeTimeout: 2000
				});
				toast.open();
				_.set(
					appStore,
					`user.productStatus[${_.findIndex(appStore.user.productStatus, { product: this.props.product })}].commPreferences`,
					res
				);
				this.closeLinkPopup();
			})
			.catch((err) => {
				showError(err);
				this.closeLinkPopup();
			});
	}

	resetAllToggles(value) {
		Object.keys(this.data).forEach((k) => {
			_.set(this.data, k, value);
		});
	}

	closeLinkPopup() {
		this.resetAllToggles(true);
		if (this.props.onClose) {
			this.props.onClose();
		}
	}

	handlePopupOpen() {
		console.log('Comm Preferences Popup Opened');
		//* Set values from user object
		let userProducts = _.get(appStore, 'user.productStatus', []);
		const product = _.find(userProducts, { product: this.props.product });
		if (product) {
			const prefs = _.get(product, 'commPreferences', {});
			let allTrue = true;
			//* Init preferences
			Object.keys(prefs).forEach((pref) => {
				this.data[pref] = prefs[pref];
				if (!prefs[pref]) {
					allTrue = false;
				}
			});
			if (allTrue) {
				this.data.allChecked = true;
			} else {
				this.data.allChecked = false;
			}
		}
	}

	onToggleChange(evt) {
		let key = evt.target.name;
		let checked = evt.target.checked;
		if (!checked) {
			this.data.allChecked = false;
		}
		if (key === 'allChecked' && checked) {
			this.resetAllToggles(true);
			this.data.allChecked = true;
		} else if (key === 'allChecked' && !checked) {
			this.resetAllToggles(false);
			this.data.allChecked = false;
		} else {
			this.data[key] = checked;
		}
		let allTrue = true;
		Object.keys(this.data).forEach((k) => {
			if (k !== 'allChecked' && !this.data[k]) {
				allTrue = false;
			}
		});
		if (!this.data.allChecked && allTrue) {
			this.data.allChecked = true;
		}
	}

	buildProductToggles() {
		switch (this.props.product) {
			case 'first-resort':
			case 'bvr100': {
				return (
					<div className="product-preferences">
						<div className="toggle-ctn hbox vcenter">
							<div className="toggle-text grow-1">SMART Goal Reminders</div>
							<Toggle
								icons={false}
								name="smartGoalReminders"
								checked={this.data.smartGoalReminders}
								onChange={this.onToggleChange}
							></Toggle>
						</div>
						{/* <div className="toggle-ctn hbox vcenter">
							<div className="toggle-text grow-1">Social Challenge Reminders</div>
							<Toggle
								icons={false}
								name="challengeReminders"
								checked={this.data.challengeReminders}
								onChange={this.onToggleChange}
							></Toggle>
						</div> */}
						{/* <div className="toggle-ctn hbox vcenter">
							<div className="toggle-text grow-1">Real Life Event Check-Ins</div>
							<Toggle icons={false} name="eventCheckIns" checked={this.data.eventCheckIns} onChange={this.onToggleChange}></Toggle>
						</div> */}
						<div className="toggle-ctn hbox vcenter">
							<div className="toggle-text grow-1">New Features Unlocked</div>
							<Toggle
								icons={false}
								name="newFeatureUnlocks"
								checked={this.data.newFeatureUnlocks}
								onChange={this.onToggleChange}
							></Toggle>
						</div>
						<div className="toggle-ctn hbox vcenter">
							<div className="toggle-text grow-1">All Messages</div>
							<Toggle icons={false} name="allChecked" checked={this.data.allChecked} onChange={this.onToggleChange}></Toggle>
						</div>
					</div>
				);
			}
			default: {
				return '';
			}
		}
	}

	render() {
		let { opened } = this.props;
		return (
			<Popup
				className={`communication-page-popup ${!appStore.isMobile ? 'desktop' : 'mobile'}`}
				opened={opened}
				closeByBackdropClick={false}
				closeOnEscape={false}
				backdrop={false}
				onPopupClosed={this.closeLinkPopup}
				onPopupOpened={this.handlePopupOpen}
			>
				<div className="popup-header hbox vcenter">
					<Link popupClose className="back-btn hbox vcenter">
						<i className="bx bx-arrow-back"></i> Back
					</Link>
					<div className="grow-1"></div>
					<img src={behavrLogo} className="header-logo" alt="BehaVR Logo" />
				</div>
				<div className="main-content y-scroll">
					<h1>{ProductMap[this.props.product].name} Communication Preferences</h1>
					<p>If enabled the following activities and actions may result in text message communications</p>
					{this.buildProductToggles()}
					<Button onClick={this.onSavePreferences}>SAVE</Button>
				</div>
			</Popup>
		);
	}
}
