import React from 'react';
import appStore from '@/stores/AppStore';
import { f7, Button, Link, Popup, Input, Preloader } from 'framework7-react';
import Toggle from 'react-toggle';
import { ReactiveComponent, observer } from '@/utils/State';
import Slider from 'rc-slider';
import { autoBind, showError } from '@/utils/GeneralUtils';
import BasicInput from '../basic-input/BasicInput';
import waveBG from '@/assets/wavebg.png';
import checkSVG from '@/assets/check.svg';
import APIService from '@/services/APIService';
import { format } from 'date-fns';
import _, { flow } from 'lodash';
import LongTermGoals from '@/assets/LongTermGoals';
import './goal-edit-popup.scss';
import { node } from 'prop-types';

class GoalEditPopup extends ReactiveComponent {
	constructor(props) {
		super(props);
		this.setData({
			currentStep: 0,
			advancedCheck: { yes1: false, yes2: false },
			goal: this.emptyGoal(),
			initialGoal: null,
			longTermGoals: [],
			flowComplete: false,
			savedGoal: null,
			activeCategoryInfo: null,
			tempThoughtMap: {},
			loading: true,
			flowType: null,
			helpOverlay: null,
			sampleGoalOpen: false,
			showLongTermSelection: false,
			editingDeadling: false
		});
		autoBind(this);
	}

	onPopupOpened() {
		this.data.advancedCheck = { yes1: false, yes2: false };
		APIService.getLongTermGoals()
			.then((res) => {
				this.data.longTermGoals = res;
				this.initializeGoal();
				this.data.loading = false;
			})
			.catch((err) => {
				showError(err);
			});
	}

	initializeGoal() {
		let { goal } = this.props;
		let localGoal = _.cloneDeep(goal);

		//* Handle start date
		if (_.isEmpty(localGoal.deadline?.repeat?.startDate)) {
			_.set(localGoal, 'deadline.repeat.hasStart', false);
			_.set(localGoal, 'deadline.repeat.startDate', new Date());
		} else {
			_.set(localGoal, 'deadline.repeat.hasStart', true);
			_.set(localGoal, 'deadline.repeat.startDate', new Date(localGoal.deadline.repeat.startDate));
		}

		if (_.isEmpty(localGoal.deadline?.repeat?.endDate)) {
			_.set(localGoal, 'deadline.repeat.hasEnd', false);
			_.set(localGoal, 'deadline.repeat.endDate', new Date());
		} else {
			_.set(localGoal, 'deadline.repeat.hasEnd', true);
			_.set(localGoal, 'deadline.repeat.endDate', new Date(localGoal.deadline.repeat.endDate));
		}

		//* Missing cadence
		if (!_.get(localGoal, 'deadline.repeat.cadence', null)) {
			_.set(localGoal, 'deadline.repeat.cadence', {
				sunday: false,
				monday: false,
				tuesday: false,
				wednesday: false,
				thursday: false,
				friday: false,
				saturday: false
			});
		}

		//* Missing frequency
		if (!_.get(localGoal, 'deadline.repeat.frequency', null)) {
			_.set(localGoal, 'deadline.repeat.frequency', 'day');
		}

		//* Missing timing
		if (_.isEmpty(localGoal.deadline.timing)) {
			localGoal.deadline.timing = 'today';
		}

		if (!localGoal.reminder) {
			localGoal.reminder = {
				bestTime: null
			};
		}

		this.data.goal = localGoal;
		this.data.initialGoal = _.cloneDeep(this.data.goal);
	}

	emptyGoal() {
		return {
			longTermParentId: null,
			summary: '',
			body: '',
			realLifeEvent: false,
			measurements: [
				{
					amount: 1,
					unit: ''
				}
			],
			deadline: {
				timing: 'today',
				deadlineDate: new Date(),
				repeatable: false,
				repeat: {
					frequency: 'day',
					hasStart: false,
					hasEnd: false,
					startDate: new Date(),
					endDate: new Date(),
					cadence: { sunday: false, monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false }
				}
			},
			reminder: {
				enabled: false,
				bestTime: null
			}
		};
	}

	onSaveGoal() {
		f7.dialog.preloader('Saving...');
		let goal = _.cloneDeep(this.data.goal);
		if (!_.isEmpty(goal.shortTermGoalId)) {
			APIService.updateGoal(goal)
				.then((res) => {
					f7.dialog.close();
					this.closeSheet();
					this.props.onPopupClosed();
				})
				.catch((err) => {
					showError(err);
				});
		} else {
			APIService.saveGoal(goal)
				.then((res) => {
					f7.dialog.close();
					this.closeSheet();
					this.props.onPopupClosed();
				})
				.catch((err) => {
					showError(err);
				});
		}
	}

	onInputChange(evt) {
		let target = evt.currentTarget;
		_.set(this.data, `goal.${target.name}`, target.value);
	}

	GoalSummary() {
		return (
			<>
				<BasicInput
					key={`app-goal-input-summary`}
					validate
					name="summary"
					type="textarea"
					placeholder="Quick summary or goal title"
					label="Short-term goal summary"
					value={this.data.goal.summary}
					onChange={this.onInputChange}
					className="goal-summary-input"
				/>
				{/* <div className="toggle-ctn hbox vcenter">
					<div className="toggle-text grow-1">Mark as "In Real-Life Event</div>
					<Toggle
						icons={false}
						className="md"
						checked={this.data.goal.realLifeEvent ? true : false}
						onChange={(e) => {
							this.data.goal.realLifeEvent = e.target.checked;
						}}
					></Toggle>
				</div> */}
			</>
		);
	}

	GoalDetails() {
		return (
			<>
				<div
					className="info-icon-other"
					onClick={() => {
						this.data.helpOverlay = 'specific';
					}}
				>
					i
				</div>
				<BasicInput
					key={`app-goal-input-body`}
					validate
					name="body"
					type="textarea"
					placeholder="Detail of goal goes here"
					label="Specify Goal"
					value={this.data.goal.body}
					onChange={this.onInputChange}
					className="goal-input details"
				/>
			</>
		);
	}

	GoalMeasurement() {
		return (
			<>
				<div className="info-label hbox vcenter">
					<h3>Set Measurable Target</h3>
					<div
						className="info-icon"
						onClick={() => {
							this.data.helpOverlay = 'measure';
						}}
					>
						i
					</div>
				</div>
				{this.data.goal.measurements.map((measurement, i) => {
					return (
						<div className="pair-input hbox vcenter" key={`app-goal-measurement-${i}`}>
							<BasicInput
								validate
								name={`measurements[${i}].amount`}
								type="number"
								placeholder="e.g. 15"
								label={i === 0 ? 'Amount' : null}
								value={this.data.goal.measurements[i].amount}
								onChange={this.onInputChange}
								className="goal-amount-input"
							/>
							<BasicInput
								validate
								name={`measurements[${i}].unit`}
								type="text"
								placeholder="e.g. minutes"
								label={i === 0 ? 'Unit' : null}
								value={this.data.goal.measurements[i].unit}
								onChange={this.onInputChange}
								className="goal-unit-input"
							/>
							{i === 0 ? (
								<div className="trash-placeholder"></div>
							) : (
								<i
									className="bx bx-trash"
									onClick={() => {
										this.data.goal.measurements.splice(i, 1);
									}}
								></i>
							)}
						</div>
					);
				})}
				<div
					className="add-btn hbox vcenter"
					onClick={() => {
						this.data.goal.measurements.push({ amount: 0, unit: '' });
					}}
				>
					<div className="plus-icon">
						<i className="bx bx-plus"></i>
					</div>
					Add more measurements
				</div>
			</>
		);
	}

	GoalDeadline() {
		let {
			deadline: {
				timing,
				deadlineDate,
				repeatable,
				repeat: { frequency, hasStart, hasEnd, startDate, endDate, cadence }
			}
		} = this.data.goal;

		return (
			<>
				<div className="toggle-ctn repeatable hbox vcenter">
					<div className="toggle-text grow-1">Make this goal repeatable</div>
					<Toggle
						className="md"
						icons={false}
						checked={repeatable ? true : false}
						onChange={(e) => {
							this.data.goal.deadline.repeatable = e.target.checked;
							if (!e.target.checked) {
								//reset repeatable items
								this.data.goal.deadline.repeat = {
									frequency: 'day',
									hasStart: false,
									hasEnd: false,
									startDate: new Date(),
									endDate: new Date(),
									cadence: {
										sunday: false,
										monday: false,
										tuesday: false,
										wednesday: false,
										thursday: false,
										friday: false,
										saturday: false
									}
								};
							} else {
								//reset deadline settings
								this.data.goal.deadline.timing = 'today';
								this.data.goal.deadline.deadlineDate = new Date();
							}
						}}
					></Toggle>
				</div>
				{repeatable && (
					<>
						<div className="message">How Often?</div>
						<div className="date-card">
							<div
								className={`selection hbox vcenter ${frequency === 'day' ? 'checked' : ''}`}
								onClick={() => {
									this.data.goal.deadline.repeat.frequency = 'day';
								}}
							>
								<div className="day grow-1">Every Day</div>
								<div className="checkbox-ctn">{frequency === 'day' && <i className="bx bx-check"></i>}</div>
							</div>
							<div
								className={`selection ${frequency === 'week' ? 'checked' : ''}`}
								onClick={() => {
									this.data.goal.deadline.repeat.frequency = 'week';
								}}
							>
								<div className="hbox vcenter grow-1">
									<div className="day grow-1">Every Week</div>
									<div className="checkbox-ctn">{frequency === 'week' && <i className="bx bx-check"></i>}</div>
								</div>
								{frequency === 'week' && (
									<div className="cadence-picker hbox vcenter">
										{Object.keys(cadence).map((dayOfWeek) => {
											return (
												<div
													key={`cadence-picker-${dayOfWeek}`}
													className={`day-icon hbox vcenter hcenter ${cadence[dayOfWeek] === true ? 'selected' : ''}`}
													onClick={() => {
														this.data.goal.deadline.repeat.cadence[dayOfWeek] = !this.data.goal.deadline.repeat.cadence[dayOfWeek];
													}}
												>
													{dayOfWeek[0]}
												</div>
											);
										})}
									</div>
								)}
							</div>
							<div
								className={`selection hbox vcenter ${frequency === 'month' ? 'checked' : ''}`}
								onClick={() => {
									this.data.goal.deadline.repeat.frequency = 'month';
								}}
							>
								<div className="day grow-1">Every Month</div>
								<div className="checkbox-ctn">{frequency === 'month' && <i className="bx bx-check"></i>}</div>
							</div>
						</div>
						{frequency === 'month' && (
							<div className="start-date-ctn hbox vcenter">
								<div
									className="hbox vcenter grow-1"
									onClick={() => {
										let cal = f7.calendar.create({
											destroyOnClose: true,
											minDate: new Date(),
											openIn: 'customModal',
											header: true,
											footer: true,
											dateFormat: 'M dd',
											backdrop: true,
											value: new Date(),
											on: {
												closed: (e) => {
													this.data.goal.deadline.repeat.hasStart = true;
													this.data.goal.deadline.repeat.startDate = new Date(e.value[0] || new Date());
												}
											}
										});
										cal.open();
									}}
								>
									<div className="cal-icon">
										<i className="bx bx-calendar-alt"></i>
									</div>
									<div className="message">Add Start Date</div>
								</div>
								{hasStart && <div className="date">{format(new Date(startDate), 'MM/dd/yy')}</div>}
								{hasStart && (
									<i
										className="bx bx-trash"
										onClick={() => {
											this.data.goal.deadline.repeat.hasStart = false;
										}}
									></i>
								)}
							</div>
						)}
						<div className="end-date-ctn hbox vcenter">
							<div
								className="hbox vcenter grow-1"
								onClick={() => {
									let cal = f7.calendar.create({
										destroyOnClose: true,
										minDate: new Date(),
										openIn: 'customModal',
										header: true,
										footer: true,
										dateFormat: 'M dd',
										backdrop: true,
										value: new Date(),
										on: {
											closed: (e) => {
												this.data.goal.deadline.repeat.hasEnd = true;
												this.data.goal.deadline.repeat.endDate = new Date(e.value[0] || new Date());
											}
										}
									});
									cal.open();
								}}
							>
								<div className="cal-icon">
									<i className="bx bx-calendar-alt"></i>
								</div>
								<div className="message">Add end date (optional)</div>
							</div>
							{hasEnd && <div className="date">{format(new Date(endDate), 'MM/dd/yy')}</div>}
							{hasEnd && (
								<i
									className="bx bx-trash"
									onClick={() => {
										this.data.goal.deadline.repeat.hasEnd = false;
									}}
								></i>
							)}
						</div>
					</>
				)}

				{!repeatable && <div className="message">Choose a date</div>}
				{!repeatable && (
					<div className="date-card">
						<div
							className={`selection hbox vcenter ${timing === 'today' ? 'checked' : ''}`}
							onClick={() => {
								this.data.goal.deadline.timing = 'today';
								this.data.goal.deadline.deadlineDate = new Date();
							}}
						>
							<div className="day">Today</div>
							<div className="date grow-1">{format(new Date(), 'MM/dd/yyyy')}</div>
							<div className="checkbox-ctn">{timing === 'today' && <i className="bx bx-check"></i>}</div>
						</div>
						<div
							className={`selection hbox vcenter ${timing === 'tomorrow' ? 'checked' : ''}`}
							onClick={() => {
								this.data.goal.deadline.timing = 'tomorrow';
								this.data.goal.deadline.deadlineDate = new Date(new Date().getTime() + 1000 * 60 * 60 * 24);
							}}
						>
							<div className="day">Tomorrow</div>
							<div className="date grow-1">{format(new Date(new Date().getTime() + 1000 * 60 * 60 * 24), 'MM/dd/yyyy')}</div>
							<div className="checkbox-ctn">{timing === 'tomorrow' && <i className="bx bx-check"></i>}</div>
						</div>
						<div
							className={`selection hbox vcenter ${timing === 'nextweek' ? 'checked' : ''}`}
							onClick={() => {
								this.data.goal.deadline.timing = 'nextweek';
								this.data.goal.deadline.deadlineDate = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7);
							}}
						>
							<div className="day">Next Week</div>
							<div className="date grow-1">{format(new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7), 'MM/dd/yyyy')}</div>
							<div className="checkbox-ctn">{timing === 'nextweek' && <i className="bx bx-check"></i>}</div>
						</div>
						<div
							className={`selection hbox vcenter ${timing === 'custom' ? 'checked' : ''}`}
							onClick={() => {
								this.data.goal.deadline.timing = 'custom';
								let cal = f7.calendar.create({
									destroyOnClose: true,
									minDate: new Date(),
									openIn: 'customModal',
									header: true,
									footer: true,
									dateFormat: 'M dd',
									backdrop: true,
									value: new Date(),
									on: {
										closed: (e) => {
											this.data.goal.deadline.deadlineDate = new Date(e.value[0] || new Date());
										}
									}
								});
								cal.open();
							}}
						>
							<div className="day">Custom Date</div>
							<div className="date grow-1">{timing === 'custom' && format(new Date(deadlineDate), 'MM/dd/yyyy')}</div>
							<div className="checkbox-ctn">{timing === 'custom' && <i className="bx bx-check"></i>}</div>
						</div>
					</div>
				)}
			</>
		);
	}

	ConfidencePrompt() {
		return (
			<>
				<div className="title">Do you feel confident that you will do it?</div>
				<div
					className="card-btn"
					onClick={() => {
						this.data.currentStep++;
					}}
				>
					Yes
				</div>
				<div
					className="card-btn"
					onClick={() => {
						const prompt = f7.dialog.create({
							text: `We suggest that your  Short-term goal should be <strong>attainable</strong> to you, please review your initial goal so you'll be confident achieving it.`,
							destroyOnClose: true,
							buttons: [
								{
									text: 'Review Goal',
									onClick: () => {
										this.data.currentStep = 0;
									}
								}
							]
						});
						prompt.open();
					}}
				>
					No
				</div>
				<div className="info-label hbox vcenter">
					<h3>Attainable Goal</h3>
					<div
						className="info-icon"
						onClick={() => {
							this.data.helpOverlay = 'attainable';
						}}
					>
						i
					</div>
				</div>
				<div className="hbox vcenter hcenter">
					<Button
						className="step-btn vbox vcenter hcenter"
						onClick={() => {
							this.data.currentStep--;
						}}
					>
						Back
					</Button>
					<div className="grow-1"></div>
				</div>
			</>
		);
	}

	MeaningPrompt() {
		return (
			<>
				<div className="title">Is this goal meaningful to you?</div>
				<div
					className="card-btn"
					onClick={() => {
						this.data.currentStep++;
					}}
				>
					Yes
				</div>
				<div
					className="card-btn"
					onClick={() => {
						const prompt = f7.dialog.create({
							text: `We suggest that your Short-term goal should be <strong>relevant</strong> to you, please review your initial goal and adjust it to be meaningful to you.`,
							destroyOnClose: true,
							buttons: [
								{
									text: 'Review Goal',
									onClick: () => {
										this.data.currentStep = 0;
									}
								}
							]
						});
						prompt.open();
					}}
				>
					No
				</div>
				<div className="info-label hbox vcenter">
					<h3>Relevant Goal</h3>
					<div
						className="info-icon"
						onClick={() => {
							this.data.helpOverlay = 'relevant';
						}}
					>
						i
					</div>
				</div>
				<div className="hbox vcenter hcenter">
					<Button
						className="step-btn vbox vcenter hcenter"
						onClick={() => {
							this.data.currentStep--;
						}}
					>
						Back
					</Button>
					<div className="grow-1"></div>
				</div>
			</>
		);
	}

	GoalReminder() {
		let {
			reminder: { bestTime }
		} = this.data.goal;
		return (
			<>
				<div className="message small">Pick the best time to receive reminder text</div>
				<div className="date-card">
					<div
						className={`selection hbox vcenter ${bestTime === 'dayOf' ? 'checked' : ''}`}
						onClick={() => {
							if (bestTime !== 'dayOf') {
								this.data.goal.reminder.bestTime = 'dayOf';
							} else if (bestTime === 'dayOf') {
								this.data.goal.reminder.bestTime = null;
							}
						}}
					>
						<div className="day grow-1">The day of</div>
						<div className="checkbox-ctn">{bestTime === 'dayOf' && <i className="bx bx-check"></i>}</div>
					</div>
					<div
						className={`selection hbox vcenter ${bestTime === 'dayBefore' ? 'checked' : ''}`}
						onClick={() => {
							if (bestTime !== 'dayBefore') {
								this.data.goal.reminder.bestTime = 'dayBefore';
							} else if (bestTime === 'dayBefore') {
								this.data.goal.reminder.bestTime = null;
							}
						}}
					>
						<div className="day grow-1">A day before</div>
						<div className="checkbox-ctn">{bestTime === 'dayBefore' && <i className="bx bx-check"></i>}</div>
					</div>
				</div>
			</>
		);
	}

	getHelpOverlay() {
		const closeHelp = () => {
			this.data.helpOverlay = null;
			this.data.sampleGoalOpen = false;
		};
		const SampleGoal = () => {
			return (
				<>
					<Button
						outline
						className="example-btn"
						onClick={() => {
							this.data.sampleGoalOpen = !this.data.sampleGoalOpen;
						}}
					>
						View SMART Goals Example
						{this.data.sampleGoalOpen ? <i className="f7-icons">chevron_up</i> : <i className="f7-icons">chevron_down</i>}
					</Button>
					{this.data.sampleGoalOpen && (
						<div className="example-goal">
							<h3>Short-Term Goals Title</h3>
							<div className="goal-card">
								<h3>RELATED LONG TERM GOAL</h3>

								<div className="value hbox vcenter ltg">
									<img src={LongTermGoals[this.data.goal.longTermParentId]?.icon} alt="Long term goal icon" />
									{LongTermGoals[this.data.goal.longTermParentId]?.name || `Unknown Long Term Goal ${this.data.goal.longTermParentId}`}
								</div>

								<div className="divider-line"></div>
								<h3>SPECIFY GOAL</h3>
								<p>TODO Sample of a short term goal</p>
								<div className="divider-line"></div>
								<h3>MEASUREABLE TARGET</h3>
								<p>15 minutes, 3 people</p>
								<div className="divider-line"></div>
								<h3>ATTAINABLE</h3>
								<p>Yes</p>
								<div className="divider-line"></div>
								<h3>RELEVANT</h3>
								<p>Yes</p>
								<div className="divider-line"></div>
								<h3>TIME BOUND</h3>
								<p>Today 06/30/22</p>
							</div>
						</div>
					)}
				</>
			);
		};
		switch (this.data.helpOverlay) {
			case 'specific': {
				return (
					<div className="help-overlay">
						<div className="header hbox vcenter">
							<div className="grow-1"></div>
							<div className="close" onClick={closeHelp}>
								<i className="f7-icons">xmark</i>
							</div>
						</div>
						<div className="title">Specific Goal</div>
						<p>Specify your goal by making it quantifiable. Consider these points below to help you.</p>
						<p className="number-list">
							{`1. What do I want to accomplish?\n2. Why is this goal important?\n3. Who is involved?\n4. Where is it located?\n5. Which resources/limitations are involved?`}
						</p>
						{SampleGoal()}
					</div>
				);
			}
			case 'measure': {
				return (
					<div className="help-overlay">
						<div className="header hbox vcenter">
							<div className="grow-1"></div>
							<div className="close" onClick={closeHelp}>
								<i className="f7-icons">xmark</i>
							</div>
						</div>
						<div className="title">How to measure your goal?</div>
						<p>
							Determine unit or metric so you can track progress, stay motivated, and recognize when you have achieved it. A measurable
							goal should address questions such as:
						</p>
						<p className="number-list">{`1. How much?\n2. How many?\n3. How will I know when it is accomplished?`}</p>
						{SampleGoal()}
					</div>
				);
			}
			case 'attainable': {
				return (
					<div className="help-overlay">
						<div className="header hbox vcenter">
							<div className="grow-1"></div>
							<div className="close" onClick={closeHelp}>
								<i className="f7-icons">xmark</i>
							</div>
						</div>
						<div className="title">How is the goal attainable?</div>
						<p>Let's make sure that your goal is actually realistic and attainable given your unique circumstances.</p>
						<p>An achievable goal will usually answer questions such as:</p>
						<p className="number-list">{`1. How can I accomplish this goal?\n2. Does achievement of this goal depend on someone else?\n3. How realistic is the goal, based on other constraints, such as financial factors?`}</p>
						{SampleGoal()}
					</div>
				);
			}
			case 'relevant': {
				return (
					<div className="help-overlay">
						<div className="header hbox vcenter">
							<div className="grow-1"></div>
							<div className="close" onClick={closeHelp}>
								<i className="f7-icons">xmark</i>
							</div>
						</div>
						<div className="title">How relevant is this goal?</div>
						<p>Let's make sure that your goal really matters to you, and that it aligns with other relevant goals.</p>
						<p>Try answer the following questions:</p>
						<p className="number-list">{`1. Does pursuing the goal seem worthwhile?\n2. Is it the right time?\n3. Am I the right person to reach this goal?\n4. How is this goal connected to other goals?`}</p>
						{SampleGoal()}
					</div>
				);
			}
			case 'deadline': {
				return (
					<div className="help-overlay">
						<div className="header hbox vcenter">
							<div className="grow-1"></div>
							<div className="close" onClick={closeHelp}>
								<i className="f7-icons">xmark</i>
							</div>
						</div>
						<div className="title">What is your goal time frame?</div>
						<p>
							The reason why your goal should be time-bound is relatively self-explanatory, because every goal needs a target date; a
							deadline to work towards.
						</p>
						{SampleGoal()}
					</div>
				);
			}
		}
	}

	closeSheet() {
		this.data.flowComplete = false;
		this.data.showLongTermSelection = false;
		this.data.editingDeadling = false;
		this.data.savedGoal = null;
		this.data.flowType = null;
		this.data.initialGoal = null;
		this.data.goal = this.emptyGoal();
		this.data.currentStep = 0;
	}

	DeadlineDisplay() {
		let { deadline } = this.data.goal;
		const getCadence = () => {
			let { frequency, cadence } = deadline.repeat;
			if (frequency === 'week') {
				let { monday, tuesday, wednesday, thursday, friday, saturday, sunday } = cadence;
				let msg = `${monday ? 'Monday, ' : ''}${tuesday ? 'Tuesday, ' : ''}${wednesday ? 'Wednesday, ' : ''}${
					thursday ? 'Thursday, ' : ''
				}${friday ? 'Friday, ' : ''}${saturday ? 'Saturday, ' : ''}${sunday ? 'Sunday, ' : ''}`;
				return msg.slice(0, msg.length - 2);
			}
			return frequency;
		};

		return (
			<div
				className="value"
				onClick={() => {
					this.data.editingDeadling = true;
				}}
			>
				{!deadline.repeatable ? (
					<div className="deadline-view hbox vcenter">
						<div className="grow-1">
							<strong>Due Date</strong> {format(new Date(deadline.deadlineDate), 'MM/dd/yy')}
						</div>
						<div className="cta-text">Change Date</div>
					</div>
				) : (
					<>
						<strong>Repeats</strong> every {getCadence()}
					</>
				)}
				{deadline.repeatable && deadline.repeat.hasEnd ? (
					<div className="deadline-view hbox vcenter">
						<div className="grow-1">
							<strong>Until</strong> {format(new Date(deadline.repeat?.endDate), 'MM/dd/yy')}
						</div>
						<div className="cta-text">Change Frequency</div>
					</div>
				) : deadline.repeatable ? (
					<div className="deadline-view hbox vcenter">
						<div className="grow-1"></div>
						<div className="cta-text">Change Frequency</div>
					</div>
				) : (
					''
				)}
			</div>
		);
	}

	EditGoalSheet() {
		const isDisabled = () => {
			let noDelta = JSON.stringify(_.cloneDeep(this.data.goal)) === JSON.stringify(_.cloneDeep(this.data.initialGoal));
			if (noDelta) {
				return true;
			}

			let { body, summary, measurements, deadline } = this.data.goal;
			let valid = true;

			measurements.forEach((m) => {
				if (m.amount <= 0 || m.unit.length < 1) {
					valid = false;
				}
			});
			if (!valid) {
				return true;
			}
			if (summary.length < 5) {
				return true;
			}
			let frequency = _.get(this.data.goal, 'deadline.repeat.frequency', null);
			if (frequency === 'month' && !_.get(this.data.goal, 'deadline.repeat.hasStart')) {
				return true;
			}
			if (frequency === 'week') {
				let hasSelected = false;
				let cadence = _.get(this.data.goal, 'deadline.repeat.cadence', null);
				Object.keys(cadence).forEach((day) => {
					if (cadence[day] === true) {
						hasSelected = true;
					}
				});
				if (!hasSelected) {
					return true;
				}
			}
			return false;
		};

		return (
			<div className="advanced-flow">
				<Link
					className="header-ctn hbox hright"
					popupClose
					onClick={() => {
						this.closeSheet();
					}}
				>
					<div className="settings-btn">
						<i className="bx bx-x"></i>
					</div>
				</Link>
				<div className="title">Edit your Short-term goal</div>
				{this.LongTermGoalPicker()}
				{this.GoalSummary()}
				{this.GoalDetails()}
				<div className="section-divider"></div>
				{this.GoalMeasurement()}
				<div className="section-divider"></div>
				<div className="info-label hbox vcenter">
					<h3>Set your deadline</h3>
					<div
						className="info-icon"
						onClick={() => {
							this.data.helpOverlay = 'deadline';
						}}
					>
						i
					</div>
				</div>
				{!this.data.editingDeadling && this.DeadlineDisplay()}
				{this.data.editingDeadling && this.GoalDeadline()}
				<div className="section-divider"></div>
				<div className="info-label hbox vcenter">
					<h3>Set an SMS Reminder (Optional)</h3>
				</div>
				{this.GoalReminder()}
				<div className="section-divider"></div>
				<div className="hbox vcenter hcenter mb-100">
					<Link
						popupClose
						className="step-btn vbox vcenter hcenter"
						onClick={() => {
							this.closeSheet();
						}}
					>
						Cancel
					</Link>
					<div className="grow-1"></div>
					<Button disabled={isDisabled()} className="step-btn vbox vcenter hcenter" onClick={this.onSaveGoal}>
						Finish
					</Button>
				</div>
			</div>
		);
	}

	LongTermGoalPicker() {
		let { goal, longTermGoals, showLongTermSelection, loading } = this.data;
		if (loading || !LongTermGoals[goal.longTermParentId]) {
			return;
		}
		if (!showLongTermSelection) {
			return (
				<>
					<div className="info-label hbox vcenter">
						<h3>Choose Long-term Goal</h3>
					</div>
					<div
						onClick={() => {
							this.data.showLongTermSelection = true;
						}}
					>
						<div
							key={`ltg-${goal.longTermParentId}-card-selected`}
							className="ltg card-btn hbox vcenter hleft"
							onClick={() => {
								this.data.showLongTermSelection = true;
							}}
						>
							<img src={LongTermGoals[goal.longTermParentId].icon} alt="Long term goal icon" />
							{LongTermGoals[goal.longTermParentId].name}
						</div>
					</div>
				</>
			);
		}
		return (
			<>
				<div className="info-label hbox vcenter">
					<h3>Choose Long-term Goal</h3>
				</div>
				{!loading &&
					longTermGoals.length > 0 &&
					longTermGoals.map((ltGoal) => {
						return (
							<div
								key={`ltg-${ltGoal}-card`}
								className="ltg card-btn hbox vcenter hleft"
								onClick={() => {
									this.data.goal.longTermParentId = ltGoal;
									this.data.showLongTermSelection = false;
								}}
							>
								<img src={LongTermGoals[ltGoal].icon} alt="Long term goal icon" />
								{LongTermGoals[ltGoal].name}
							</div>
						);
					})}
			</>
		);
	}

	constructFlow() {
		if (this.data.helpOverlay !== null) {
			return this.getHelpOverlay(this.data.helpOverlay);
		}
		return this.EditGoalSheet();
	}

	getStepContent() {
		let { loading } = this.data;
		if (loading) {
			return (
				<div className="hbox vcenter hcenter h-100">
					<Preloader color="blue" size={44}></Preloader>
				</div>
			);
		}
		return this.constructFlow();
	}

	render() {
		let { onPopupClosed, opened } = this.props;
		return (
			<Popup
				className={`goal-edit-popup ${!appStore.isMobile ? 'desktop' : 'mobile'}`}
				opened={opened}
				closeByBackdropClick={false}
				closeOnEscape={false}
				onPopupOpened={this.onPopupOpened}
				onPopupClosed={onPopupClosed}
				backdrop={false}
			>
				<div className={`page-ctn y-scroll`}>{opened && this.getStepContent()}</div>
			</Popup>
		);
	}
}

export default observer(GoalEditPopup);
