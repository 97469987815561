import React from 'react';
import { Button, f7, Fab, Icon, Page, Preloader } from 'framework7-react';
import { observer, ReactiveComponent } from '@/utils/State';
import backgroundImage from '@/assets/bvrtree.png';
import appStore, { getUserProductConfig } from '@/stores/AppStore';
import { autoBind, showError } from '@/utils/GeneralUtils';
import _ from 'lodash';
import torchOff from '@/assets/torch1.svg';
import torchOn from '@/assets/torch2.svg';
import { Splash } from '../Splash';
import APIService from '@/services/APIService';
import lanternPNG from '@/assets/thougthlantern.png';
import './bvr-thoughts.scss';
import { format } from 'date-fns';
import ThoughtDetailsPopup from '@/components/thought-details-popup/ThoughtDetailsPopup';
import ThoughtCapturePopup from '@/components/thought-capture-popup/ThoughtCapturePopup';
import BVRNav from '@/components/bvr-nav/BVRNav';

class BVRThoughts extends ReactiveComponent {
	constructor(props) {
		super(props);
		this.setData({
			thoughtList: [],
			filter: 'all',
			dateFilter: 'all',
			activeThought: null,
			loading: true,
			thoughtCaptureOpen: false
		});
		autoBind(this);
		this.imgRef = React.createRef();
	}

	componentDidMount() {
		this.loadThoughts();
	}

	onDataChanged() {
		this.data.loading = true;
		this.loadThoughts();
	}

	async loadThoughts() {
		let { thoughts } = getUserProductConfig('first-resort');
		if (thoughts) {
			APIService.getThoughts()
				.then((res) => {
					this.data.thoughtList = res;
					this.data.loading = false;
				})
				.catch((err) => {
					showError(err);
				});
		} else {
			this.data.loading = false;
		}
	}

	onThoughtClicked(e, thought) {
		if (e.target.classList.contains('priority') || e.target.localName === 'img') {
			this.togglePriority(thought);
		} else {
			this.data.activeThought = thought;
		}
	}

	togglePriority(thought) {
		f7.dialog.preloader('Saving...');
		const thoughtIndex = _.findIndex(this.data.thoughtList, { id: thought.id });
		APIService.updateThought({ ...thought, isPriority: !thought.isPriority })
			.then(() => {
				this.data.thoughtList[thoughtIndex].isPriority = !thought.isPriority;
				f7.dialog.close();
				var toast = f7.toast.create({
					text: this.data.thoughtList[thoughtIndex].isPriority ? 'Thought is prioritized!' : 'Success!',
					position: 'center',
					closeTimeout: 2000
				});
				toast.open();
			})
			.catch((err) => {
				console.log(err.message);
				showError(err);
			});
	}

	Filters() {
		let { filter } = this.data;
		let { thoughts } = getUserProductConfig('first-resort');
		if (!thoughts) {
			return <div className="divider-line"></div>;
		}
		return (
			<div className="filter-list hbox vcenter x-scroll">
				<div
					className={`filter ${filter === 'all' && 'active'}`}
					onClick={() => {
						this.data.filter = 'all';
					}}
				>
					<div>ALL</div>
				</div>
				<div
					className={`filter ${filter === 'anxious' && 'active'}`}
					onClick={() => {
						this.data.filter = 'anxious';
					}}
				>
					<div>DISTORTED</div>
				</div>
				<div
					className={`filter ${filter === 'positive' && 'active'}`}
					onClick={() => {
						this.data.filter = 'positive';
					}}
				>
					<div>AFFIRMATION/POSITIVE</div>
				</div>
				<div
					className={`filter ${filter === 'reframed' && 'active'}`}
					onClick={() => {
						this.data.filter = 'reframed';
					}}
				>
					<div>REFRAMED</div>
				</div>
				<div
					className={`filter ${filter === 'priority' && 'active'}`}
					onClick={() => {
						this.data.filter = 'priority';
					}}
				>
					<div>PRIORITY</div>
				</div>
			</div>
		);
	}

	ThoughtListItem(thought) {
		let { type, body, id, isPriority, triggeringDate, reframe } = thought;
		let reframed = !_.isEmpty(_.get(reframe, 'body', null));
		let displayBody = reframed ? reframe.body.substring(0, 65) : body.substring(0, 65);
		return (
			<div
				className="thought-card"
				key={`thought-card-${id}`}
				onClick={(e) => {
					this.onThoughtClicked(e, thought);
				}}
			>
				<div className="date vbox hcenter">
					<div className="day">{format(new Date(triggeringDate), 'E')}</div>
					<div className="daynum">{format(new Date(triggeringDate), 'd')}</div>
					<div className="month">{format(new Date(triggeringDate), 'MMM')}</div>
				</div>
				<div className="thought-body grow-1">
					<div className={`thought-type ${reframed ? 'reframed' : type}`}>
						{type === 'positive' ? 'AFFIRMATION/ POSITIVE' : reframed ? 'REFRAMED' : 'DISTORTED'}
					</div>
					<div className="body-ctn hbox vcenter">
						<div className="text-body ellipse">"{displayBody.length === 65 ? `${displayBody}...` : displayBody}"</div>
						{displayBody.length === 65 && <span>"</span>}
					</div>
				</div>
				{!reframed && type === 'anxious' && (
					<div className="priority">
						{!isPriority && <img src={torchOff} alt="Torch Off Icon" />}
						{isPriority && <img src={torchOn} alt="Torch On Icon" />}
					</div>
				)}
			</div>
		);
	}

	ThoughtList() {
		//* If not unlocked show coming soon
		let { thoughts } = getUserProductConfig('first-resort');
		if (!thoughts) {
			return (
				<div className="empty-message">
					<div className="image-ctn">
						<img src={lanternPNG} alt="Lantern Icon" />
					</div>
					<h2>Coming Soon!</h2>
					<p>Stay on track with your VR practice to unlock this feature.</p>
				</div>
			);
		}

		//* If no thoughts show empty message
		let { thoughtList, filter, loading } = this.data;
		if (!loading && thoughtList.length <= 0) {
			return (
				<div className="empty-message">
					<div className="image-ctn">
						<img src={lanternPNG} alt="Lantern Icon" />
					</div>
					<h2>No Thought Captured Yet</h2>
					<p>Add and keep track of your thought(s) here</p>
					<Button
						onClick={() => {
							this.data.thoughtCaptureOpen = true;
						}}
					>
						Add a Thought
					</Button>
				</div>
			);
		}

		//* Otherwise return thought list
		const thoughtListItems = _.cloneDeep(thoughtList)
			.sort(function (a, b) {
				return b.createdDate - a.createdDate;
			})
			.map((thought) => {
				switch (filter) {
					case 'all': {
						return this.ThoughtListItem(thought);
					}
					case 'anxious': {
						if (thought.type === 'anxious') {
							return this.ThoughtListItem(thought);
						}
						return null;
					}
					case 'positive': {
						if (thought.type === 'positive') {
							return this.ThoughtListItem(thought);
						}
						return null;
					}
					case 'reframed': {
						if (!_.isEmpty(_.get(thought, 'reframe.body', null))) {
							return this.ThoughtListItem(thought);
						}
						return null;
					}
					case 'priority': {
						if (thought.isPriority) {
							return this.ThoughtListItem(thought);
						}
						return null;
					}
					default: {
						return null;
					}
				}
			})
			.filter((t) => t !== null);
		return (
			<div className="thoughts-list">
				{thoughtListItems.length > 0 ? thoughtListItems : <div className="empty-message">No thoughts match this filter.</div>}
			</div>
		);
	}

	render() {
		if (!appStore.isLoggedIn) {
			return <Splash></Splash>;
		}
		let { thoughts } = getUserProductConfig('first-resort');
		let { loading } = this.data;
		const imageHeight = this.imgRef?.current?.clientHeight || 300;
		return (
			<Page name="bvr-thoughts" className={`bvr-thoughts-page-cmp ${!appStore.isMobile ? 'desktop' : 'mobile'}`}>
				<div className={`background`}></div>
				<div className="content-ctn y-scroll">
					<div className="img-ctn vbox hcenter" ref={this.imgRef}>
						<img src={backgroundImage} className="bg-image" alt="background waves image" />
					</div>
					<h1 className="text-gradiant" style={{ marginTop: -imageHeight / 3 }}>
						Your Thoughts {this.data.thoughtList.length > 0 ? `(${this.data.thoughtList.length})` : ''}
					</h1>
					{this.Filters()}
					{this.ThoughtList()}
					{loading && (
						<div className="hbox vcenter hcenter">
							<Preloader size={32} color="blue"></Preloader>
						</div>
					)}
				</div>
				<ThoughtCapturePopup
					opened={this.data.thoughtCaptureOpen}
					onPopupClosed={() => {
						this.data.thoughtCaptureOpen = false;
					}}
				></ThoughtCapturePopup>
				<ThoughtDetailsPopup
					opened={!!this.data.activeThought}
					thought={this.data.activeThought}
					onPopupClosed={() => {
						this.data.activeThought = null;
						this.data.thoughtList = [];
						this.data.loading = true;
						this.loadThoughts();
					}}
				></ThoughtDetailsPopup>
				<BVRNav onDataChanged={this.onDataChanged}></BVRNav>
			</Page>
		);
	}
}

export default observer(BVRThoughts);
