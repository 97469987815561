import React, { Component } from 'react';
import { Stripe } from '@stripe/stripe-js';
import _ from 'lodash';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import { Button, Link, Popup, f7 } from 'framework7-react';
import nurtureBgPNG from '@/assets/nurturebg.png';
import nurtureLogoPNG from '@/assets/nurtureLogo.png';
import './product-page.scss';
import appStore from '@/stores/AppStore';
import CheckoutSheet from '@/components/checkout-sheet/CheckoutSheet';
import APIService from '@/services/APIService';

@observer
export default class ProductPage extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			checkoutOpened: false,
			stripeProduct: null
		});
		autoBind(this);
	}

	closeLinkPopup() {
		if (this.props.onClose) {
			this.props.onClose();
		}
	}

	handlePopupOpen() {
		/**
		 * * TODO currently only accounts for one price on this product
		 * * when we move to a cart design it will be different
		 */
		let { product } = this.props;
		console.log('Product Page Popup Opened');
		console.log('Fetching Product Details');
		if (product && product.prices.length > 0) {
			APIService.getProductPrice(product.prices[0])
				.then((res) => {
					this.data.stripeProduct = res;
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}

	onGetStarted() {
		console.log('Opening Checkout');
		this.data.checkoutOpened = true;
	}

	onCheckoutClosed(subscription) {
		this.data.checkoutOpened = false;
		if (subscription) {
			setTimeout(() => {
				this.closeLinkPopup();
				f7.dialog.close();
			}, 500);
		}
	}

	getProductHeader() {
		switch (this.props.product?.type) {
			case 'nurture': {
				return (
					<div className="popup-header">
						<div className="image-container">
							<img src={nurtureBgPNG} className="header-image" alt="Mother holder her child" />
						</div>
						<div className="controls hbox vcenter">
							<Link popupClose className="back-btn hbox vcenter">
								<i className="bx bx-arrow-back"></i> Back
							</Link>
							<div className="grow-1"></div>
							<img src={nurtureLogoPNG} className="header-logo" alt="NurtureVR Product Icon" />
						</div>
					</div>
				);
			}
			default: {
				return (
					<div className="popup-header">
						<div className="controls hbox vcenter">
							<Link popupClose className="back-btn hbox vcenter">
								<i className="bx bx-arrow-back"></i> Back
							</Link>
						</div>
					</div>
				);
			}
		}
	}

	getProductContent() {
		switch (this.props.product?.type) {
			case 'nurture': {
				return (
					<>
						<div className="title-card hbox vcenter">
							<div className="details vbox vcenter grow-1 ">
								<div className="title">NurtureVR</div>
								<div className="subtitle">Journey through pregnancy and into motherhood</div>
							</div>
						</div>
						<div className="product-description y-scroll">
							<p>
								<strong>NurtureVR</strong> a maternal health and wellness virtual reality (VR) program designed to support expectant
								moms and their families through the third trimester, labor and delivery experience, and postpartum phases.
								<br></br>
								<br></br>
								This 22 week VR program offers education and resilience training for moms. Some example topics include:
							</p>
							<ul>
								<li>The impacts of acute and chronic stress on pregnancy</li>
								<li>Easy coping tools and cognitive behavioral therapy techniques</li>
								<li>3D views to help moms visualize the weekly growth of the baby in the womb</li>
								<li>
									Pain management experiences designed in collaboration with world-renowned VR pain relief expert Hunter Hoffman, PhD.
								</li>
								<li>Mindfulness sessions to ease labor and delivery</li>
								<li>"Fourth trimester" education on topics like hormonal change, nutrition, and emotional regulation</li>
								<li>Breastfeeding and lactation training and VR-enabled practices</li>
								<li>Ability to involve family and other loved ones in the pregnancy experience. </li>
							</ul>
							<p>Welcome to the NurtureVR family!</p>
						</div>
						<div className="btn-ctn">
							<Button fill large className="primary-btn" onClick={this.onGetStarted} disabled={!this.data.stripeProduct}>
								Get Started
							</Button>
						</div>
					</>
				);
			}
			default: {
				return (
					<>
						<div className="title-card hbox vcenter">
							<div className="details vbox vcenter grow-1 ">
								<div className="title">Product Not Found</div>
								<div className="subtitle">Tap back to go back to the home page</div>
							</div>
						</div>
					</>
				);
			}
		}
	}

	render() {
		let { product } = this.props;
		return (
			<Popup
				className={`product-page-popup ${!appStore.isMobile ? 'desktop' : 'mobile'}`}
				opened={!!product}
				closeByBackdropClick={false}
				closeOnEscape={false}
				onPopupClosed={this.closeLinkPopup}
				onPopupOpened={this.handlePopupOpen}
			>
				{this.getProductHeader()}
				<div className="main-content">{this.getProductContent()}</div>
				{!!product && (
					<CheckoutSheet
						opened={this.data.checkoutOpened}
						product={_.cloneDeep(this.data.stripeProduct)}
						productType={product?.type}
						onClosedCheckout={this.onCheckoutClosed}
					></CheckoutSheet>
				)}
			</Popup>
		);
	}
}
