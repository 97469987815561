import React, { Component } from 'react';
import { Button, f7, Page, Preloader } from 'framework7-react';
import { observable, observer } from '@/utils/State';
import appStore from '@/stores/AppStore';
import { autoBind, formatPhoneNumber, showError } from '@/utils/GeneralUtils';
import behavrLogoPNG from '@/assets/behavrlogo.png';
import savBgPNG from '@/assets/savbackground.png';
import stressBg from '@/assets/stressbg.png';
import pneBg from '@/assets/pnebg.png';
import nurtureBg2PNG from '@/assets/nurturebg2.png';
import nurtureLogoPNG from '@/assets/nurtureLogo.png';
import AuthService from '@/services/AuthService';
import APIService from '@/services/APIService';
import CacheStorageService from '@/services/CacheStorageService';
import ProductPage from '../product/ProductPage';
import AccountManagement from '../account-management/AccountManagement';
import ProductSetupSheet from '@/components/product-setup-sheet/ProductSetupSheet';
import DonationPage from '../donation/DonationPage';
import VideoPopup from '@/components/video-popup/VideoPopup';
import './home.scss';
import _ from 'lodash';
import { Splash } from '../Splash';

const CS = new CacheStorageService();
const PRODUCT_DETAILS = {
	nurture: {
		subtitle: 'Journey through pregnancy and into motherhood',
		background: nurtureBg2PNG
	},
	sav: {
		title: 'Anxiety Regulation',
		subtitle: 'Tackling the Epidemic of Anxiety-Related Conditions',
		background: savBgPNG
	},
	bvr100: {
		title: 'BVR-100',
		subtitle: 'Tackling the Epidemic of Anxiety-Related Conditions',
		background: savBgPNG
	},
	'first-resort': {
		title: 'First Resort',
		subtitle: 'Tackling the Epidemic of Anxiety-Related Conditions',
		background: savBgPNG
	},
	pne2: {
		title: 'PNE+',
		subtitle: 'Reducing Chronic Pain and Catastrophizing through Neuroscience Education',
		background: pneBg
	},
	stress: {
		title: 'Coping with Stress',
		subtitle: 'Mindfulness-based interventions to help alleviate stress',
		background: stressBg
	}
};

@observer
class Home extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			activeProduct: null,
			accountMgmtOpen: false,
			productToSetup: null,
			openSetupSheet: false,
			openUpdatePIN: false,
			openDonationScreen: false,
			videoKey: null,
			devicesyncs: [],
			promptedSetup: false
		});
		autoBind(this);
	}

	async handleBVRChecks(syncs) {
		try {
			let bvrStatus = _.find(_.get(appStore.user, 'productStatus', []), { product: 'bvr100' });
			//* BVR first then check first-resort TODO @BVR account for all types in future
			if (!bvrStatus) {
				bvrStatus = _.find(_.get(appStore.user, 'productStatus', []), { product: 'first-resort' });
				appStore.bvrProductType = 'first-resort';
			} else {
				appStore.bvrProductType = 'bvr100';
			}
			if (bvrStatus?.setup) {
				//* Ensure setup sheet is closed
				this.data.openSetupSheet = false;
				f7.view.main.router.navigate('/bvr-landing');
			}
		} catch (err) {
			console.log(err);
		}
	}

	async componentDidMount() {
		try {
			const syncs = await APIService.getDeviceSyncs();
			const entity = await APIService.getReferringEntity(appStore.referringEntityId);
			console.log(`Fetching entity ID ${appStore.referringEntityId}`);
			const progressData = await APIService.getUserProgress(appStore.user);
			appStore.userProgress = progressData;
			appStore.referringEntity = entity;
			this.data.devicesyncs = syncs;
			CS.saveValue('entities', `referringEntityId`, appStore.referringEntityId);
			let promptedSetup = await CS.getValue('entities', `promptedSetup-${appStore.user._id}`);
			if (!promptedSetup) {
				await CS.saveValue('entities', `promptedSetup-${appStore.user._id}`, false);
				promptedSetup = false;
			}
			//* Checking if sav is setup & headset has been synced, after that we always default to landing page of BVR
			this.handleBVRChecks(syncs);
		} catch (err) {
			console.log(err);
			showError(err);
			appStore.userToken = null;
			location.reload();
		}
	}

	checkDeviceSyncs() {
		APIService.getDeviceSyncs()
			.then((res) => {
				console.log(`Device Syncs ${res.length}`);
				this.data.devicesyncs = res;
				this.handleBVRChecks(res);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	openAccountPopup() {
		this.data.accountMgmtOpen = true;
	}

	openUpdatePIN() {
		this.data.openUpdatePIN = true;
		this.data.openSetupSheet = true;
	}

	onProductPageClosed() {
		let productType = _.get(this.data, 'activeProduct.type', null);
		let purchased = _.get(appStore, 'user.purchased', []);
		if (purchased.indexOf(productType) >= 0) {
			console.log('Openeing Product Setup');
			this.data.productToSetup = productType;
			this.data.openSetupSheet = true;
		}
		this.data.activeProduct = null;
		this.checkDeviceSyncs();
	}

	async promptSetup(product) {
		const promptedSetup = await CS.getValue('entities', `promptedSetup-${appStore.user._id}`);
		console.log(`Have I Prompted Setup Before? ${promptedSetup}`);
		if (!promptedSetup) {
			setTimeout(async () => {
				this.openProduct(false, true, product);
			}, 200);
			await CS.saveValue('entities', `promptedSetup-${appStore.user._id}`, true);
		}
	}

	openProduct(setupComplete, purchased, product) {
		if (setupComplete || (purchased && !this.data.openSetupSheet)) {
			this.data.productToSetup = product.type;
			this.data.openSetupSheet = true;
		} else if (!purchased) {
			this.data.activeProduct = product;
		}
	}

	buildProductCards() {
		let { productConfig } = appStore?.referringEntity;
		let { user } = appStore;
		if (productConfig && productConfig.products.length > 0) {
			const productCards = [];
			_.forEach(productConfig.products, (p) => {
				const details = PRODUCT_DETAILS[p.type];
				if (!details) {
					return;
				}
				let headsetType = _.get(appStore, `referringEntity.headsetType`, null) === 'pico' ? 'pico' : 'quest';
				let headsetSyncMode = _.get(appStore, `referringEntity.headsetSyncMode`, null) === 'none' ? 'none' : 'legacy';
				let setupComplete = false;
				let purchased = false;
				//check for purchase and setup data
				if (_.get(user, 'purchased', []).indexOf(p.type) >= 0) {
					let status = _.find(_.get(user, 'productStatus', []), { product: p.type });
					if (status && status.setup) {
						purchased = true;
						setupComplete = true;
						details.subtitle = (
							<div className="hbox vcenter">
								<i className="bx bx-check-circle"></i>
								{`Active: ${headsetType === 'pico' || headsetSyncMode === 'legacy' ? 'Sync My Headset' : 'Setup Complete'}`}
							</div>
						);
					} else {
						purchased = true;
						details.subtitle = (
							<div className="hbox vcenter">
								<i className="bx bx-check-circle"></i>
								Active: Product Setup Required
							</div>
						);
					}
				}
				//for free products open setup on first login page load
				if (
					purchased &&
					!setupComplete &&
					(headsetType === 'pico' || headsetSyncMode === 'legacy') &&
					!this.data.promptedSetup &&
					!this.data.openSetupSheet
				) {
					this.promptSetup(p);
				}
				productCards.push(
					<div
						key={p.stripeId || p.type + '-product-card'}
						className="product-card vbox hcenter"
						onClick={() => {
							this.openProduct(setupComplete, purchased, p);
						}}
					>
						<img src={details.background} alt="mother holding her baby" className="product-background" />
						<div className="title-card hbox vcenter">
							<div className="details vbox vcenter grow-1 ">
								{purchased && !setupComplete && headsetType === 'pico' && (
									<div className="setup-warning hbox vcenter">
										<i className="bx bx-error"></i>
										Setup Required
									</div>
								)}
								<div className="title">{details.title}</div>
								<div className="subtitle">{details.subtitle}</div>
							</div>
							<i className="bx bx-right-arrow-alt"></i>
						</div>
					</div>
				);
			});
			return productCards;
		}
		return '';
	}

	buildPINCard() {
		let { user } = appStore;
		let setupProduct = _.find(_.get(user, 'productStatus', []), { setup: true });
		if (setupProduct) {
			return (
				<div className="account-card hbox vcenter" onClick={this.openUpdatePIN}>
					<i className="bx bx-lock"></i>
					<div className="grow-1">Manage Headset PIN</div>
					<i className="bx bx-right-arrow-alt"></i>
				</div>
			);
		}
		return '';
	}

	onDonateNurture() {
		this.data.openDonationScreen = true;
	}

	showPICODonationCard() {
		let headsetType = _.get(appStore, 'referringEntity.headsetType', null);
		if (headsetType !== 'pico') {
			return false;
		}
		let status = _.find(_.get(appStore, 'user.productStatus', []), { product: 'nurture' });
		if (!status || !status.setup) {
			return false;
		}
		if (appStore?.user?.donatedHeadset) {
			return false;
		}
		return true;
	}

	buildDonateCard() {
		if (this.showPICODonationCard()) {
			return (
				<div className="account-card hbox vcenter" onClick={this.onDonateNurture}>
					<i className="bx bx-heart"></i>
					<div className="grow-1">
						Donate NurtureVR<br></br>
						to a Mommy in Need
					</div>
					<i className="bx bx-right-arrow-alt"></i>
				</div>
			);
		}
		return '';
	}

	buildShippingCard() {
		if (appStore.referringEntity?.headsetType === 'pico' && this.data.devicesyncs.length === 0) {
			let { user } = appStore;
			let completedSetup = false;
			_.forEach(user.productStatus, (product) => {
				if (product.setup) {
					completedSetup = true;
				}
			});
			if (completedSetup) {
				return (
					<div
						className="account-card hbox vcenter"
						onClick={() => {
							f7.dialog.alert('Once your headset arrives tap on the card above to sync your headset.', `It's almost there!`);
						}}
					>
						<i className="bx bxs-truck"></i>
						<div className="message-text vbox vcenter">
							<div className="grow-1">Your headset is on its way!</div>
							<p>Once it arrives tap the card above to sync your headset.</p>
						</div>
					</div>
				);
			}
		}
		return '';
	}

	buildHomePageCards() {
		return (
			<div className="card-list y-scroll vbox hcenter">
				{appStore.referringEntity && this.buildProductCards()}
				{this.buildShippingCard()}
				<div className="account-card hbox vcenter" onClick={this.openAccountPopup}>
					<i className="bx bx-user-circle"></i>
					<div className="grow-1">Manage My Account</div>
					<i className="bx bx-right-arrow-alt"></i>
				</div>
				{this.buildPINCard()}
				{this.buildDonateCard()}
			</div>
		);
	}

	render() {
		if (!appStore.isLoggedIn) {
			return <Splash></Splash>;
		}
		return (
			<Page className={`home-page ${!appStore.isMobile ? 'desktop' : 'mobile'} `}>
				<div className={`background`}></div>
				<div className={`home-content`}>
					<div className="homepage-header hbox vcenter hleft">
						<img src={behavrLogoPNG || appStore.referringEntity?.partnerConfig.logoUrl} className="icon-logo" alt="Partner Logo" />
						<div className="grow-1"></div>
						<Button
							small
							className="signout-btn"
							onClick={() => {
								AuthService.signOut();
							}}
						>
							Sign Out
							<i className="bx bx-exit"></i>
						</Button>
					</div>
					{this.buildHomePageCards()}
				</div>
				<ProductPage product={this.data.activeProduct} onClose={this.onProductPageClosed}></ProductPage>
				<AccountManagement
					opened={this.data.accountMgmtOpen}
					onClose={() => {
						console.log('Closing Account Mgmt');
						this.data.accountMgmtOpen = false;
					}}
				></AccountManagement>
				<ProductSetupSheet
					opened={this.data.openSetupSheet}
					product={this.data.productToSetup}
					updatePIN={this.data.openUpdatePIN}
					onClosedSheet={() => {
						console.log(`Closing Setup Sheet`);
						this.data.openUpdatePIN = false;
						this.data.productToSetup = null;
						this.data.openSetupSheet = false;
						this.checkDeviceSyncs();
					}}
				></ProductSetupSheet>
				{this.showPICODonationCard() && (
					<DonationPage
						opened={this.data.openDonationScreen}
						onClose={() => {
							this.data.openDonationScreen = false;
						}}
					></DonationPage>
				)}
				<VideoPopup
					opened={!_.isEmpty(appStore.openVideoKey)}
					videoSrc={appStore.openVideoKey}
					onPopupClosed={() => {
						appStore.openVideoKey = null;
					}}
				></VideoPopup>
			</Page>
		);
	}
}

export default Home;
