export default class CacheStorageService {
	async saveValue(rootKey, key, value) {
		try {
			const cache = await caches.open(rootKey);
			const responseBody = JSON.stringify({
				value: value
			});
			const response = new Response(responseBody);
			await cache.put(`/${key}`, response);
			localStorage.setItem(`${rootKey}/${key}`, responseBody);
			console.log('Token saved! 🎉');
		} catch (error) {
			console.log('saveToken error:', { error });
		}
	}

	async getValue(rootKey, key) {
		try {
			const cache = await caches.open(rootKey);
			const response = await cache.match(`/${key}`);
			let value;
			if (response) {
				const responseBody = await response.json();
				value = responseBody.value;
			}
			if (!value) {
				value = JSON.parse(localStorage.getItem(`${rootKey}/${key}`))?.value;
			}
			return value;
		} catch (error) {
			console.log('getValue error:', { error });
			return null;
		}
	}
}
