import React from 'react';
import { observer } from 'mobx-react';
import { observable, configure } from 'mobx';
configure({
	enforceActions: 'never'
});
export { observable, observer, observable as Store };
export class ReactiveComponent extends React.Component {
	/**
	 * Reactive observable data
	 */
	data = {};

	constructor(props) {
		super(props);
	}

	setData(data) {
		this.data = observable(_.cloneDeep(data));
	}

	getRawData() {
		return _.cloneDeep(this.data);
	}
}
