import React from 'react';
import appStore from '@/stores/AppStore';
import { f7, Link, Popup, Toggle, Input } from 'framework7-react';
import { ReactiveComponent, observer } from '@/utils/State';
import { autoBind, showError } from '@/utils/GeneralUtils';
import torchOff from '@/assets/torch1.svg';
import torchOn from '@/assets/torch2.svg';
import _ from 'lodash';
import './thought-details-popup.scss';
import APIService from '@/services/APIService';
import { format } from 'date-fns';
import { AriMessage } from '../ari-message/AriMessage';
const catMap = {
	mindreading: 'Mindreading',
	catastrophizing: 'Catastrophizing',
	allornothing: 'All-or-Nothing Thinking',
	disqualifying: 'Disqualifying the Positive',
	personalization: 'Personalization and Blame'
};
class ThoughtDetailsPopup extends ReactiveComponent {
	constructor(props) {
		super(props);
		this.setData({
			thought: null,
			showAnxiousThought: false
		});
		autoBind(this);
	}

	handlePopupOpen() {
		let { thought } = this.props;
		this.data.thought = _.cloneDeep(thought);
	}

	togglePriority(e) {
		f7.dialog.preloader('Saving...');
		APIService.updateThought({ ...this.data.thought, isPriority: !this.data.thought.isPriority })
			.then((res) => {
				this.data.thought = res;
				f7.dialog.close();
				var toast = f7.toast.create({
					text: res.isPriority ? 'Thought is prioritized!' : 'Success!',
					position: 'center',
					closeTimeout: 2000
				});
				toast.open();
			})
			.catch((err) => {
				showError(err);
			});
	}

	Header() {
		return (
			<Link
				className="header-ctn hbox hright"
				popupClose
				onClick={() => {
					this.data.thought = null;
				}}
			>
				<div className="settings-btn">
					<i className="bx bx-x"></i>
				</div>
			</Link>
		);
	}

	ReframedItem() {
		let { id, reframe } = this.props.thought;
		return (
			<div className="thought-card reframed" key={`thought-card-${id}-${Math.random()}`}>
				<div className="date vbox hcenter">
					<div className="day">{format(new Date(reframe.reframedDate), 'E')}</div>
					<div className="daynum">{format(new Date(reframe.reframedDate), 'd')}</div>
					<div className="month">{format(new Date(reframe.reframedDate), 'MMM')}</div>
				</div>
				<div className="card-content">
					<div className="thought-body">
						<div className={`thought-type reframed`}>REFRAMED</div>
						<div className="text-body">"{reframe.body}"</div>
					</div>
				</div>
			</div>
		);
	}

	ThoughtListItem() {
		if (!this.data.thought) {
			return <div className="thought-card" key={`thought-card-empty`}></div>;
		}
		let { type, body, id, isPriority, triggeringDate, trigger, impact, categories } = this.data.thought;
		let reframed = !_.isEmpty(_.get(this.data.thought, 'reframe.body', null));
		return (
			<div className="thought-card" key={`thought-card-${id}-${Math.random()}`}>
				<div className="hbox">
					<div className="date vbox hcenter">
						<div className="day">{format(new Date(triggeringDate), 'E')}</div>
						<div className="daynum">{format(new Date(triggeringDate), 'd')}</div>
						<div className="month">{format(new Date(triggeringDate), 'MMM')}</div>
					</div>
					<div className="card-content">
						<div className="thought-body">
							<div className={`thought-type ${type}`}>{type === 'positive' ? 'AFFIRMATION/ POSITIVE' : 'DISTORTED'}</div>
							<div className="text-body">"{body}"</div>
						</div>
						{!_.isEmpty(trigger) && (
							<div className="thought-detail">
								<div className="label">Triggers / Situation</div>
								<div className="text-body">{trigger}</div>
							</div>
						)}
						{
							<div className="thought-detail">
								<div className="label">Impact</div>
								<div className="text-body">{['Not at All', 'Slightly', 'Somewhat', 'Very Much', 'Extremely'][impact - 1]}</div>
							</div>
						}
						{categories && categories.length > 0 && (
							<div className="thought-detail">
								<div className="label">Cognitive Distortion</div>
								<ul className="text-body">
									{categories.map((c) => {
										return (
											<li className="category" key={`detail-cat-${c.type}`}>
												{catMap[c.type]}
											</li>
										);
									})}
								</ul>
							</div>
						)}
					</div>
				</div>
				{!reframed && type === 'anxious' && (
					<div className="priority-ctn hbox vcenter">
						{!isPriority && <img src={torchOff} alt="Torch off icon" />}
						{isPriority && <img src={torchOn} alt="Torch on icon" />}
						<div className="toggle-text grow-1">Set as Priority</div>
						<Toggle
							className="md"
							checked={this.data.thought.isPriority ? true : false}
							value={this.data.thought.isPriority ? true : false}
							onChange={this.togglePriority}
						></Toggle>
					</div>
				)}
			</div>
		);
	}

	AnxiousThoughtToggle() {
		return (
			<div className="vbox vcenter hcenter">
				<div
					className="anxious-toggle hbox vcenter hcenter"
					onClick={() => {
						this.data.showAnxiousThought = !this.data.showAnxiousThought;
					}}
				>
					<div>{this.data.showAnxiousThought ? 'Hide the distorted thought' : 'Show the distorted thought'}</div>
					{this.data.showAnxiousThought ? <i className="bx bx-chevron-up"></i> : <i className="bx bx-chevron-down"></i>}
				</div>
			</div>
		);
	}

	render() {
		let { onPopupClosed, opened, thought } = this.props;
		let reframed = !_.isEmpty(_.get(thought, 'reframe.body', null));
		return (
			<Popup
				className={`thought-details-popup  ${!appStore.isMobile ? 'desktop' : 'mobile'}`}
				opened={opened}
				closeByBackdropClick={false}
				closeOnEscape={false}
				onPopupClosed={onPopupClosed}
				onPopupOpened={this.handlePopupOpen}
			>
				<div className="content-ctn y-scroll">
					{this.Header()}
					<h1 className="text-gradiant">Here is your thought detail</h1>
					{reframed && this.ReframedItem()}
					{!reframed && this.ThoughtListItem()}
					<AriMessage
						message={
							reframed
								? 'I think you did a great job reframing this thought!'
								: this.data.thought?.isPriority
								? 'Awesome! When the thought is prioritized they get done quicker!'
								: 'Tips: set your thought as priority to help you on track in VR'
						}
					></AriMessage>
					{reframed && this.AnxiousThoughtToggle()}
					{reframed && this.data.showAnxiousThought && this.ThoughtListItem()}
				</div>
			</Popup>
		);
	}
}

export default observer(ThoughtDetailsPopup);
