import { observable } from '@/utils/State';
import _ from 'lodash';
const initial = {
	referringEntityId: null,
	isMobile: true,
	referringEntity: null,
	entryEntityId: null,
	deviceDetails: {},
	recaptchaId: null,
	reCaptchaVerifier: null,
	user: null,
	userToken: null,
	tokenRefreshed: new Date().getTime(),
	firebaseUser: null,
	confirmationResult: null,
	isLoggedIn: false,
	stripePromise: null,
	openVideoKey: null,
	disableRecaptcha: false,
	isD2CInstance: false,
	existingBehaUser: null,
	bvrActiveTab: 'home',
	bvrProductType: null,
	bvrCreateTarget: null,
	bvrTarget: null,
	skipInstallCard: false,
	mockData: {
		goals: []
	},
	isPWA() {
		return _.get(this, 'deviceDetails.standalone', false);
	}
};
let appStore = observable(initial);

export function resetAppStore() {
	appStore = observable(initial);
}

export function getUserProductConfig(product) {
	let config = _.get(appStore.user, `config.product[${product}]`, null);
	//bvr fallback to first resort
	if (!config) {
		config = _.get(appStore.user, `config.product['first-resort']`, { thoughts: false, goals: false, progress: false });
		console.log(`Loading Config for product: ${product}: ${JSON.stringify(config, null, 2)}`);
	}

	return config;
}

window.appStore = appStore;
export default appStore;
