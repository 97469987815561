import ltg2 from './ltg2.png';
import ltg3 from './ltg3.png';
import ltg4 from './ltg4.png';
import ltg5 from './ltg5.png';
import ltg6 from './ltg6.png';
import ltg7 from './ltg7.png';
import ltg8 from './ltg8.png';
import ltg9 from './ltg9.png';
import ltg10 from './ltg10.png';
import ltg11 from './ltg11.png';
import ltg12 from './ltg12.png';
import ltg13 from './ltg13.png';
import ltg14 from './ltg14.png';
import ltg15 from './ltg15.png';
import ltg16 from './ltg16.png';
import ltg17 from './ltg17.png';
import ltg18 from './ltg18.png';
import ltg19 from './ltg19.png';
import ltg20 from './ltg20.png';
import ltg21 from './ltg21.png';
import ltg22 from './ltg22.png';
import ltg23 from './ltg23.png';
import ltg24 from './ltg24.png';
import ltg25 from './ltg25.png';
import ltg26 from './ltg26.png';
import ltg27 from './ltg27.png';
import ltg28 from './ltg28.png';
import ltg29 from './ltg29.png';
import ltg30 from './ltg30.png';
import ltg31 from './ltg31.png';
import ltg32 from './ltg32.png';
import ltg33 from './ltg33.png';
import ltg34 from './ltg34.png';
import ltg35 from './ltg35.png';
import ltg36 from './ltg36.png';

const LongTermGoals = {
	1: {
		name: 'Read more books',
		icon: ltg2
	},
	2: {
		name: 'Identify a mentor',
		icon: ltg3
	},
	3: {
		name: 'Develop a communication skill',
		icon: ltg4
	},
	4: {
		name: 'Share my knowledge and experiences with others',
		icon: ltg5
	},
	5: {
		name: 'Become a mentor',
		icon: ltg6
	},
	6: {
		name: 'Learn a new skill (e.g., play an instrument, learn a new sport)',
		icon: ltg7
	},
	7: {
		name: 'Listen to informative audiobooks/podcasts',
		icon: ltg8
	},
	8: {
		name: 'Regulate Social Media Usage',
		icon: ltg9
	},
	9: {
		name: 'Develop a consistent Mindfulness practice',
		icon: ltg10
	},
	10: {
		name: 'Finish the First Resort Program',
		icon: ltg11
	},
	11: {
		name: 'Complete 15 social challenge practices',
		icon: ltg12
	},
	12: {
		name: 'Learn 3 techniques for Cognitive Reframing',
		icon: ltg13
	},
	13: {
		name: 'Complete All Mindfulness Practices',
		icon: ltg14
	},
	14: {
		name: 'Keep a diary or journal',
		icon: ltg15
	},
	15: {
		name: 'Make a new friend',
		icon: ltg16
	},
	16: {
		name: 'Establish a family tradition',
		icon: ltg17
	},
	17: {
		name: 'Be there for your family',
		icon: ltg18
	},
	18: {
		name: 'Be there for your friends',
		icon: ltg19
	},
	19: {
		name: 'Reconnect with family members / improve my family relationships',
		icon: ltg20
	},
	20: {
		name: 'Reconnect with an old friend',
		icon: ltg21
	},
	21: {
		name: 'Eat Healthy',
		icon: ltg22
	},
	22: {
		name: 'Regularly exercise',
		icon: ltg23
	},
	23: {
		name: 'Prepare healthy meals',
		icon: ltg24
	},
	24: {
		name: 'Maintain sobriety',
		icon: ltg25
	},
	25: {
		name: 'Get a higher education degree',
		icon: ltg26
	},
	26: {
		name: 'Attain a job',
		icon: ltg27
	},
	27: {
		name: 'Start a business',
		icon: ltg28
	},
	28: {
		name: 'Become financially independent',
		icon: ltg29
	},
	29: {
		name: 'Create a budget plan',
		icon: ltg30
	},
	30: {
		name: 'Pay off debt',
		icon: ltg31
	},
	31: {
		name: 'Develop my spirituality',
		icon: ltg32
	},
	32: {
		name: 'Improve my relationship with my partner / spouse',
		icon: ltg33
	},
	33: {
		name: 'Go out on regular dates',
		icon: ltg34
	},
	34: {
		name: 'Develop a new hobby',
		icon: ltg35
	},
	35: {
		name: 'See more of the world',
		icon: ltg36
	}
};

export default LongTermGoals;
