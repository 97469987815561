import React, { Component } from 'react';
import _ from 'lodash';
import { observer, observable } from '@/utils/State';
import { autoBind, getStripeKey } from '@/utils/GeneralUtils';
import { Button, Link, Sheet, f7, Preloader } from 'framework7-react';
import nurtureLogoPNG from '@/assets/nurtureLogo.png';
import appStore from '@/stores/AppStore';
import BasicInput from '../basic-input/BasicInput';
import CreditCardInput from '../credit-card-input/CreditCardInput';
import { Elements, ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import './checkout-sheet.scss';
import APIService from '@/services/APIService';
import FormBuilder from '../form-builder/FormBuilder';
import StateAbbrvMap from '@/utils/StateAbbrvMap';

const ProductPurchaseTypes = {
	ONE_TIME: 'one-time',
	SUBSCRIPTION: 'subscription'
};

const FormatCents = (amount) => {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	});
	return formatter.format(amount / 100);
};

@observer
export default class CheckoutSheet extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			showPayments: false,
			showPromoCode: false,
			showShipping: false,
			cardValid: false,
			formComplete: false,
			paymentMethod: null,
			fullName: '',
			promoCode: '',
			addressInput: '',
			address: null,
			addressValid: false,
			addressForm: {
				street: '',
				street2: '',
				city: '',
				state: 'al',
				zip: ''
			},
			searchResults: [],
			subscription: null,
			searching: false
		});
		autoBind(this);
	}

	reset() {
		this.data.showPayments = false;
		this.data.showPromoCode = false;
		this.data.showShipping = false;
		this.data.paymentMethod = null;
		this.data.cardValid = false;
		this.data.formComplete = false;
		this.data.fullName = '';
		this.data.promoCode = '';
		this.data.addressInput = '';
		this.data.subscription = null;
		this.data.address = null;
		this.data.searching = false;
		this.data.searchResults = [];
		this.data.addressForm = {
			street: '',
			street2: '',
			city: '',
			state: 'al',
			zip: ''
		};
	}

	handleSheetClose() {
		let validatedSubscription = this.data.subscription;
		this.reset();
		if (this.props.onClosedCheckout) {
			this.props.onClosedCheckout(validatedSubscription);
		}
	}

	handleSheetOpen() {
		console.log('Checkout Sheet Opened');
		if (_.get(appStore, `referringEntity.headsetType`, null) === 'pico' && !_.isEmpty(appStore, 'user.street1')) {
			let { street1, street2, city, state, zip } = _.cloneDeep(appStore.user);
			this.data.addressForm = {
				street: street1,
				street2,
				city,
				state,
				zip
			};
			this.data.addressValid = true;
		}
	}

	onClosePayments() {
		this.data.showPayments = false;
	}

	onClosePromo() {
		this.data.showPromoCode = false;
	}

	onCloseShipping() {
		this.data.address = null;
		if (
			!this.data.addressValid &&
			_.get(appStore, `referringEntity.headsetType`, null) === 'pico' &&
			!_.isEmpty(appStore, 'user.street1')
		) {
			let { street1, street2, city, state, zip } = _.cloneDeep(appStore.user);
			this.data.addressForm = {
				street: street1,
				street2,
				city,
				state,
				zip
			};
			this.data.addressValid = true;
		}
		this.data.showShipping = false;
	}

	getAddressText() {
		let { street, city, state, zip } = this.data.addressForm;
		return `${_.toUpper(street)} ${_.toUpper(city)} ${_.toUpper(state)}, ${zip}`;
	}

	onSaveAddress() {
		this.data.showShipping = false;
	}

	onSaveCardDetails() {
		this.onClosePayments();
	}

	async finalizeSubscription(id) {
		try {
			const validatedSubscription = await APIService.finalizeSubscription(id);
			console.log(validatedSubscription);
			const updatedUser = await APIService.initializeProduct(
				this.props.productType,
				_.get(validatedSubscription, 'latest_invoice.id', _.get(validatedSubscription, 'latest_invoice', null))
			);
			appStore.user = updatedUser;
			this.data.subscription = validatedSubscription;
			this.handleSheetClose();
		} catch (err) {
			console.log(err);
			f7.dialog.close();
			f7.dialog.alert('Unable to finalize subscription');
		}
	}

	async onPurchase() {
		f7.dialog.preloader('Processing...');
		let { paymentMethod, subscription } = this.data;
		if (_.get(subscription, 'latest_invoice.amount_due', 1) === 0) {
			console.log('Subscription Created & Active');
			await this.finalizeSubscription(subscription.id);
		} else if (paymentMethod) {
			try {
				if (!this.data.subscription) {
					const sub = await APIService.createSubscription(this.props.product?.id);
					this.data.subscription = sub;
				}
				await paymentMethod.stripe.confirmCardPayment(this.data.subscription?.latest_invoice?.payment_intent?.client_secret, {
					payment_method: paymentMethod.card.id
				});
				await this.finalizeSubscription(this.data.subscription.id);
			} catch (err) {
				console.log(err);
				f7.dialog.close();
				f7.dialog.alert('Unable to Process Payment');
			}
		}
	}

	onCreditChange(e) {
		this.data.cardValid = e.complete;
		this.isValid();
	}

	onFullNameChange(evt) {
		let target = evt.currentTarget;
		this.data.fullName = target.value;
		this.data.nameValid = this.data.fullName !== '' && this.data.fullName.indexOf(' ') > 0;
		this.isValid();
	}

	isValid() {
		if (this.data.nameValid && this.data.cardValid) {
			this.data.formComplete = true;
		} else {
			this.data.formComplete = false;
		}
	}

	async createPaymentMethod(evt, stripe, elements) {
		f7.dialog.preloader('Saving Card Details...');
		const cardElement = elements.getElement(CardElement);
		const { paymentMethod, error } = await stripe.createPaymentMethod({
			type: 'card',
			card: cardElement,
			billing_details: {
				name: _.get(this.data, 'fullName', '')
			}
		});

		if (error) {
			f7.dialog.close();
			this.data.paymentMethod = null;
			console.log(error);
			return;
		}
		this.data.paymentMethod = { card: paymentMethod, stripe };
		f7.dialog.close();
		this.onClosePayments();
	}

	buildPaymentForm() {
		let { fullName, formComplete } = this.data;
		return (
			<div className="payment-capture">
				<Elements stripe={appStore.stripePromise}>
					<ElementsConsumer>
						{({ elements, stripe }) => {
							return (
								<div className="stripe-form">
									<BasicInput
										name="fullName"
										label="Full Name"
										value={fullName}
										type="text"
										placeholder="John Doe"
										onChange={this.onFullNameChange}
									></BasicInput>
									<CreditCardInput name="creditCard" label="Card Details" onChange={this.onCreditChange} stripeKey={getStripeKey()} />
									<div className="capture-controls hbox vcenter">
										<Button onClick={this.onClosePayments}>Back</Button>
										<div className="grow-1"></div>
										<Button
											disabled={!formComplete}
											onClick={(evt) => {
												this.createPaymentMethod(evt, stripe, elements);
											}}
										>
											Save Card Details
										</Button>
									</div>
								</div>
							);
						}}
					</ElementsConsumer>
				</Elements>
			</div>
		);
	}

	onPromoChange(evt) {
		let target = evt.currentTarget;
		this.data.promoCode = target.value;
	}

	onApplyPromoCode() {
		console.log('Initializing Subscription...');
		f7.dialog.preloader('Validating Code...');
		APIService.createSubscription(this.props.product?.id, this.data.promoCode)
			.then((sub) => {
				console.log('Subscription Initialized');
				this.data.subscription = sub;
				f7.dialog.close();
				this.onClosePromo();
			})
			.catch((err) => {
				console.log(err);
				f7.dialog.close();
				f7.dialog.alert('Invalid Promo Code');
				this.data.promoCode = '';
			});
	}

	buildPromoCodeForm() {
		let { promoCode } = this.data;
		return (
			<div className="promo-code-form">
				<BasicInput
					name="promoCode"
					label="Enter Promo Code"
					value={promoCode}
					type="text"
					placeholder="123456"
					onChange={this.onPromoChange}
				></BasicInput>
				<div className="capture-controls hbox vcenter">
					<Button onClick={this.onClosePromo}>Back</Button>
					<div className="grow-1"></div>
					<Button disabled={promoCode.length <= 0} onClick={this.onApplyPromoCode}>
						Apply Code
					</Button>
				</div>
			</div>
		);
	}

	doAddressSearch() {
		this.data.searching = true;
		if (this.fireOffSearch) {
			clearTimeout(this.fireOffSearch);
		}
		this.fireOffSearch = setTimeout(() => {
			if (this.data.addressInput.length >= 5) {
				APIService.findAddress(this.data.addressInput)
					.then(async (res) => {
						this.data.searching = false;
						clearTimeout(this.fireOffSearch);
						this.fireOffSearch = null;
						this.data.searchResults = res;
					})
					.catch((err) => {
						this.data.searching = false;
						clearTimeout(this.fireOffSearch);
						this.fireOffSearch = null;
						console.log(err);
					});
			} else {
				this.data.searching = false;
			}
		}, 500);
	}

	onAddressChange(e) {
		this.data.addressInput = e.target.value;
		if (this.data.addressInput.length >= 5) {
			this.doAddressSearch();
		}
	}

	setAddressFields() {
		if (this.data.address) {
			APIService.getAddressDetails(this.data.address.place_id).then((res) => {
				let postalCode = '';
				try {
					let components = _.get(res, 'result.address_components', []);
					for (let i = 0; i < components.length; i++) {
						let part = components[i];
						if (part && part.types[0] === 'postal_code') {
							postalCode = part.short_name;
						}
					}
				} catch (err) {
					console.log(err);
				}
				let parts = this.data.address.description.split(',');
				this.data.addressForm.street = `${_.toLower(_.trim(_.get(parts, '[0]', '')))}`;
				this.data.addressForm.city = `${_.toLower(_.trim(_.get(parts, '[1]', '')))}`;
				this.data.addressForm.state = `${_.toLower(_.trim(_.get(parts, '[2]', '')))}`;
				this.data.addressForm.zip = postalCode;
				this.data.addressValid = true;
			});
		}
	}

	buildShippingAddressForm() {
		return (
			<div className="shipping-form">
				{!this.data.address && (
					<BasicInput
						key={`address-input-key`}
						label="Shipping Address"
						type="text"
						placeholder="Input your shipping address"
						value={this.data.addressInput}
						onChange={this.onAddressChange}
						className="address-input"
						icon={<i className="bx bx-search"></i>}
					/>
				)}
				{this.data.searching && (
					<div className="loader-ctn hbox vcenter hcenter">
						<Preloader color="blue"></Preloader>
					</div>
				)}
				{!this.data.searching && !this.data.address && this.data.searchResults.length > 0 && (
					<>
						<div className="select-text">Select your shipping address</div>
						{this.data.searchResults.map((prediction) => {
							return (
								<div
									className="address-card"
									key={prediction.place_id}
									onClick={() => {
										this.data.address = prediction;
										this.setAddressFields();
									}}
								>
									{prediction.description}
								</div>
							);
						})}
					</>
				)}
				{this.data.address && (
					<FormBuilder
						formData={this.data.addressForm}
						formConfig={{
							street: {
								label: 'Shipping Street 1',
								type: 'text',
								placeholder: 'Street address',
								validator: {
									type: 'length',
									value: 1
								}
							},
							street2: {
								label: 'Shipping Street 2',
								type: 'text',
								placeholder: 'Your Street address'
							},
							city: {
								label: 'City',
								type: 'text',
								placeholder: 'City',
								validator: {
									type: 'length',
									value: 1
								}
							},
							state: {
								label: 'State',
								type: 'dropdown',
								className: 'dropdown-form-input state-dropdown',
								listItems: StateAbbrvMap
							},
							zip: {
								label: 'Postal Code (5-digit)',
								type: 'zipCode',
								placeholder: 'Postal Code',
								validator: {
									type: 'length',
									value: 5
								}
							}
						}}
						setValidationState={(valid) => {
							this.data.addressValid = valid;
						}}
					></FormBuilder>
				)}
				<div className="capture-controls hbox vcenter">
					<Button onClick={this.onCloseShipping}>Back</Button>
					<div className="grow-1"></div>
					<Button onClick={this.onSaveAddress} disabled={!this.data.addressValid}>
						Save Address
					</Button>
				</div>
			</div>
		);
	}

	buildCaptureContent() {
		let { showPayments, showPromoCode, showShipping, paymentMethod, promoCode } = this.data;
		if (showPayments) {
			return this.buildPaymentForm();
		}
		if (showPromoCode) {
			return this.buildPromoCodeForm();
		}
		if (showShipping) {
			return this.buildShippingAddressForm();
		}
		return (
			<>
				{paymentMethod ? (
					<div
						className="capture-btn hbox vcenter captured"
						onClick={() => {
							this.data.showPayments = true;
						}}
					>
						<div className="grow-1 hbox vcenter">
							<i className="bx bx-credit-card"></i>
							<div className="card-data">{`${paymentMethod.card.card?.brand} **** **** **** ${paymentMethod.card.card?.last4}`}</div>
						</div>
						<div className="add-icon vbox vcenter hcenter">
							<i className="bx bx-pencil"></i>
						</div>
					</div>
				) : (
					<div
						className="capture-btn hbox vcenter"
						onClick={() => {
							this.data.showPayments = true;
						}}
					>
						<div className="grow-1">Add Payment Method</div>
						<div className="add-icon vbox vcenter hcenter">
							<i className="bx bx-plus"></i>
						</div>
					</div>
				)}
				{promoCode.length > 0 ? (
					<div
						className="capture-btn hbox vcenter captured"
						onClick={() => {
							//this.data.showPromoCode = true;
						}}
					>
						<div className="grow-1 hbox vcenter">
							<i className="bx bxs-discount"></i>
							<div className="code ellipse">Promo: {this.data.promoCode}</div>
						</div>
						<div className="add-icon vbox vcenter hcenter">
							<i className="bx bx-check-circle"></i>
						</div>
					</div>
				) : (
					<div
						className="capture-btn hbox vcenter"
						onClick={() => {
							this.data.showPromoCode = true;
						}}
					>
						<div className="grow-1">Add Promo Code</div>
						<div className="add-icon vbox vcenter hcenter">
							<i className="bx bx-plus"></i>
						</div>
					</div>
				)}
				{_.get(appStore, `referringEntity.headsetType`, null) === 'pico' && (
					<div
						className={`capture-btn hbox vcenter ${this.data.addressValid ? 'captured' : ''}`}
						onClick={() => {
							this.onCloseShipping();
							this.data.showShipping = true;
						}}
					>
						<div className="grow-1 address-text ellipse">{this.data.addressValid ? this.getAddressText() : 'Shipping Address'}</div>
						<div className="add-icon vbox vcenter hcenter">
							{this.data.addressValid ? <i className="bx bx-check-circle"></i> : <i className="bx bx-plus"></i>}
						</div>
					</div>
				)}
			</>
		);
	}

	buildLineItem() {
		let { productType, product } = this.props;
		let purchaseType =
			_.get(product, 'recurring.interval', 'day') === 'day' ? ProductPurchaseTypes.ONE_TIME : ProductPurchaseTypes.SUBSCRIPTION;
		switch (productType) {
			case 'nurture': {
				return (
					<div className="product hbox vcenter">
						<img src={nurtureLogoPNG} alt="BehaVR Nurture Product Icon" />
						<div className="product-details">
							<div className="name">NurtureVR</div>
							<div className="price">{`${FormatCents(_.get(product, 'unit_amount', 4999))} ${
								purchaseType === ProductPurchaseTypes.SUBSCRIPTION ? '/ Month' : ''
							}`}</div>
							{purchaseType === ProductPurchaseTypes.SUBSCRIPTION && <div className="details">(6 Month Subscription)</div>}
						</div>
					</div>
				);
			}
			default: {
				return <div className="product hbox vcenter">Unable to find product</div>;
			}
		}
	}

	isFormValid() {
		let { subscription, paymentMethod } = this.data;
		if (!subscription && !paymentMethod) {
			return false;
		}
		if (!paymentMethod && _.get(subscription, 'latest_invoice.amount_remaining', 1) > 0) {
			return false;
		}
		if (_.get(appStore, `referringEntity.headsetType`, null) === 'pico' && !this.data.addressValid) {
			return false;
		}
		return true;
	}

	render() {
		let { product } = this.props;
		let { subscription } = this.data;
		return (
			<Sheet
				className={`checkout-sheet-cmp ${!appStore.isMobile ? 'desktop' : 'mobile'}`}
				position={!appStore.isMobile ? 'top' : 'bottom'}
				opened={this.props.opened}
				closeByBackdropClick={false}
				closeOnEscape={false}
				onSheetClose={this.handleSheetClose}
				onSheetOpen={this.handleSheetOpen}
				fullscreen
				backdrop
				push
			>
				<div className="checkout-content">
					<div className="checkout-header hbox vcenter">
						<Link sheetClose>
							<i className="bx bx-arrow-back"></i>Back
						</Link>
						<div className="title">Checkout</div>
					</div>
					<div className="product-list">{this.buildLineItem()}</div>
					<div className="payment-info y-scroll">
						<h1>Payment</h1>
						{this.buildCaptureContent()}
					</div>
					<div className="totals">
						<div className="subtotals">
							<div className="total-pair hbox vcenter">
								<div className="label">Subtotal</div>
								<div className="grow-1"></div>
								<div className="price">{`${FormatCents(
									subscription ? _.get(subscription, 'plan.amount', 0) : _.get(product, 'unit_amount', 0)
								)}`}</div>
							</div>
							{_.get(subscription, 'latest_invoice.discount.coupon', null) && (
								<div className="total-pair hbox vcenter">
									<div className="label">Promotion</div>
									<div className="grow-1"></div>
									<div className="price discount">{`${
										_.get(subscription, 'latest_invoice.discount.coupon.percent_off', null)
											? `${_.get(subscription, 'latest_invoice.discount.coupon.percent_off', null)}% OFF`
											: `-${FormatCents(_.get(subscription, 'latest_invoice.discount.coupon.amount_off', 0))}`
									}`}</div>
								</div>
							)}
						</div>
						<div className="total hbox vcenter">
							<div className="label">Total</div>
							<div className="grow-1"></div>
							<div className="price">{`${FormatCents(
								subscription ? _.get(subscription, 'latest_invoice.amount_remaining', 0) : _.get(product, 'unit_amount', 0)
							)}`}</div>
						</div>
					</div>
					<div className="btn-ctn">
						<Button fill large className="primary-btn" onClick={this.onPurchase} disabled={!this.isFormValid()}>
							Purchase
						</Button>
					</div>
				</div>
			</Sheet>
		);
	}
}
