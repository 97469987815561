import * as React from 'react';

const ProgressSVG = (props) => {
	return (
		<svg
			width="22"
			height="26"
			viewBox="0 0 22 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			aria-label="Progress Icon"
			{...props}
		>
			<path
				d="M3.0625 17H1.9375C1.41973 17 1 17.4477 1 18V24C1 24.5523 1.41973 25 1.9375 25H3.0625C3.58027 25 4 24.5523 4 24V18C4 17.4477 3.58027 17 3.0625 17Z"
				strokeWidth="1.5"
				className="path"
			/>
			<path
				d="M8.5625 14H7.4375C6.91973 14 6.5 14.4397 6.5 14.9821V24.0179C6.5 24.5603 6.91973 25 7.4375 25H8.5625C9.08027 25 9.5 24.5603 9.5 24.0179V14.9821C9.5 14.4397 9.08027 14 8.5625 14Z"
				strokeWidth="1.5"
				className="path"
			/>
			<path
				d="M14.0625 11H12.9375C12.4197 11 12 11.4353 12 11.9722V24.0278C12 24.5647 12.4197 25 12.9375 25H14.0625C14.5803 25 15 24.5647 15 24.0278V11.9722C15 11.4353 14.5803 11 14.0625 11Z"
				strokeWidth="1.5"
				className="path"
			/>
			<path
				d="M19.5625 10H18.4375C17.9197 10 17.5 10.3997 17.5 10.8929V24.1071C17.5 24.6003 17.9197 25 18.4375 25H19.5625C20.0803 25 20.5 24.6003 20.5 24.1071V10.8929C20.5 10.3997 20.0803 10 19.5625 10Z"
				strokeWidth="1.5"
				className="path"
			/>
			<path d="M19.5 2L1.5 13" strokeWidth="1.5" strokeLinecap="round" className="path" />
			<path d="M15.5 1L19.5 2" strokeWidth="1.5" strokeLinecap="round" className="path" />
			<path d="M19.5 2L18.5 7" strokeWidth="1.5" strokeLinecap="round" className="path" />
		</svg>
	);
};
export default ProgressSVG;
