import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind, cleanBehaDate, formatPhoneNumber } from '@/utils/GeneralUtils';
import { Button, Link, Popup, f7 } from 'framework7-react';
import behavrLogo from '@/assets/logo.png';
import baby1PNG from '@/assets/baby1.png';
import baby2PNG from '@/assets/baby2.png';
import baby3PNG from '@/assets/baby3.png';
import baby4PNG from '@/assets/baby4.png';
import baby5PNG from '@/assets/baby5.png';
import baby6PNG from '@/assets/baby6.png';
import appStore from '@/stores/AppStore';
import APIService from '@/services/APIService';
import './account-management.scss';
import _ from 'lodash';
import FormBuilder from '@/components/form-builder/FormBuilder';
import PurchaseHistory from '../purchase-history/PurchaseHistory';
import StateAbbrvMap from '@/utils/StateAbbrvMap';
import SupportPage from '../support/SupportPage';
import AuthService from '@/services/AuthService';
import ProductSetupSheet from '@/components/product-setup-sheet/ProductSetupSheet';
import CommPreferences from '../communication-preferences/CommPreferences';
import VideoPopup from '@/components/video-popup/VideoPopup';
import IOSInstallPrompt from '@/components/ios-install-prompt/IOSInstallPrompt';

@observer
export default class AccountManagement extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			profileOpen: false,
			purchaseHistoryOpen: false,
			supportPageOpen: false,
			profileData: {},
			profileValid: false,
			openSetupSheet: false,
			openUpdatePIN: false,
			commPreferencesOpen: false,
			iosInstallOpened: false
		});
		autoBind(this);
	}

	closeLinkPopup() {
		this.props.onClose();
	}

	closeProfilePopup() {
		this.data.profileOpen = false;
		this.data.profileData = {};
		this.data.profileValid = false;
	}

	handleEditOpen() {
		if (this.data.profileOpen) {
			const user = _.cloneDeep(appStore.user) || {};
			let profileData = {
				firstName: user.firstName || '',
				lastName: user.lastName || '',
				email: user.email || '',
				dateOfBirth: cleanBehaDate(user.dateOfBirth),
				street1: user.street1 || '',
				street2: user.street2 || '',
				city: user.city || '',
				state: user.state?.toLowerCase() || '',
				zip: user.zip || ''
			};
			if (user.dueDate) {
				profileData.dueDate = cleanBehaDate(user.dueDate);
			}
			if (user.deliveryDate) {
				profileData.deliveryDate = cleanBehaDate(user.deliveryDate);
			}
			if (user.dueDate && cleanBehaDate(user.dueDate).getTime() < new Date().getTime()) {
				profileData.deliveryDate = new Date();
			}
			if (user.dueDate || user.deliveryDate) {
				profileData.skinTone = user.skinTone ? Number(user.skinTone) : 1;
			}
			this.data.profileData = profileData;
		}
	}

	handlePopupOpen() {
		console.log('Account Management Page Popup Opened');
	}

	onSupportClick() {
		this.data.supportPageOpen = true;
	}

	openEditProfile() {
		this.data.profileOpen = true;
	}

	onSaveProfile() {
		f7.dialog.preloader('Saving...');
		APIService.saveProfile(this.data.profileData)
			.then((res) => {
				appStore.user = { ..._.cloneDeep(appStore.user), ...res };
				f7.dialog.close();
				this.closeProfilePopup();
				f7.toast.create({ text: 'Success', closeTimeout: 1000, icon: `<i class="f7-icons">checkmark_shield</i>` }).open();
			})
			.catch((err) => {
				console.log(err);
				f7.dialog.close();
				f7.dialog.alert('Unable to save profile', 'Please try again');
			});
	}

	openPurchaseHistory() {
		this.data.purchaseHistoryOpen = true;
	}

	buildSkinTonePicker() {
		let { skinTone } = this.data.profileData;
		return (
			<div className="skintone-ctn">
				<h3>Baby Skin Tone</h3>
				<div className="sub-text left-align">
					We want you to have the deepest connection with your baby. Please select the option that best suits your baby preference.
					This will customize your baby in VR lessons.
				</div>
				<div className="skin-tone-selector">
					<img
						className={`baby ${skinTone === 1 && 'selected'}`}
						src={baby1PNG}
						alt="Baby skin tone 1"
						onClick={() => {
							this.data.profileData.skinTone = 1;
						}}
					/>
					<img
						className={`baby ${skinTone === 2 && 'selected'}`}
						src={baby2PNG}
						alt="Baby skin tone 2"
						onClick={() => {
							this.data.profileData.skinTone = 2;
						}}
					/>
					<img
						className={`baby ${skinTone === 3 && 'selected'}`}
						src={baby3PNG}
						alt="Baby skin tone 3"
						onClick={() => {
							this.data.profileData.skinTone = 3;
						}}
					/>
					<img
						className={`baby ${skinTone === 4 && 'selected'}`}
						src={baby4PNG}
						alt="Baby skin tone 4"
						onClick={() => {
							this.data.profileData.skinTone = 4;
						}}
					/>
					<img
						className={`baby ${skinTone === 5 && 'selected'}`}
						src={baby5PNG}
						alt="Baby skin tone 5"
						onClick={() => {
							this.data.profileData.skinTone = 5;
						}}
					/>
					<img
						className={`baby ${skinTone === 6 && 'selected'}`}
						src={baby6PNG}
						alt="Baby skin tone 6"
						onClick={() => {
							this.data.profileData.skinTone = 6;
						}}
					/>
				</div>
			</div>
		);
	}

	openUpdatePIN() {
		this.data.openUpdatePIN = true;
		this.data.openSetupSheet = true;
	}

	buildPINCard() {
		let { user } = appStore;
		let setupProduct = _.find(_.get(user, 'productStatus', []), { setup: true }) || {};
		if (setupProduct) {
			return (
				<div className="account-card hbox vcenter" onClick={this.openUpdatePIN}>
					<i className="bx bx-lock"></i>
					<div className="grow-1">Manage Headset PIN</div>
				</div>
			);
		}
		return '';
	}

	onSyncClick() {
		this.data.openSetupSheet = true;
	}

	openCommPref() {
		this.data.commPreferencesOpen = true;
	}

	onShowInstall() {
		console.log('Prompting for install');
		if (appStore.deviceDetails.ios) {
			this.data.iosInstallOpened = true;
		} else if (window.deferredPrompt) {
			//show android pwa install prompt
			window.deferredPrompt.prompt();
		}
	}

	render() {
		let { opened } = this.props;
		let headsetSyncMode = _.get(appStore, `referringEntity.headsetSyncMode`, null) === 'none' ? 'none' : 'legacy';
		return (
			<Popup
				className={`account-mgmt-page-popup ${!appStore.isMobile ? 'desktop' : 'mobile'}`}
				opened={opened}
				closeByBackdropClick={false}
				closeOnEscape={false}
				onPopupClosed={this.closeLinkPopup}
				onPopupOpened={this.handlePopupOpen}
			>
				<div className="popup-header hbox vcenter">
					<Link popupClose className="back-btn hbox vcenter">
						<i className="bx bx-arrow-back"></i> Back
					</Link>
					<div className="grow-1"></div>
				</div>
				<div className="main-content y-scroll">
					<h1>Settings</h1>
					<div className="account-card hbox vcenter" onClick={this.openEditProfile}>
						<i className="bx bx-edit"></i>
						<div className="grow-1">Edit Profile Details</div>
					</div>
					{!appStore.isPWA() && (
						<div className="account-card hbox vcenter" onClick={this.onShowInstall}>
							<i className="bx bx-mobile-alt"></i>
							<div className="grow-1">Add to Home Screen</div>
							<i className="f7-icons">exclamationmark_triangle</i>
						</div>
					)}
					{!appStore.bvrProductType ||
						(appStore.bvrProductType !== 'first-resort' && (
							<div className="account-card hbox vcenter" onClick={this.openPurchaseHistory}>
								<i className="bx bx-shopping-bag"></i>
								<div className="grow-1">My Purchases</div>
							</div>
						))}
					{this.buildPINCard()}
					{appStore.bvrProductType !== 'first-resort' && headsetSyncMode === 'legacy' && !_.isEmpty(this.props.productToSetup) && (
						<div className="account-card hbox vcenter" onClick={this.onSyncClick}>
							<i className="bx bx-sync"></i>
							<div className="grow-1">Sync Headset</div>
						</div>
					)}
					<div className="account-card hbox vcenter" onClick={this.onSupportClick}>
						<i className="bx bx-help-circle"></i>
						<div className="grow-1">Help & Support</div>
					</div>
					<div className="account-card hbox vcenter" onClick={this.openCommPref}>
						<i className="bx bx-message"></i>
						<div className="grow-1">Communication Preferences</div>
					</div>
					<div
						className="account-card hbox vcenter"
						onClick={() => {
							AuthService.signOut();
						}}
					>
						<i className="bx bx-exit"></i>
						<div className="grow-1">Sign Out</div>
					</div>
				</div>
				{!_.isEmpty(this.props.productToSetup) && (
					<CommPreferences
						opened={this.data.commPreferencesOpen}
						product={this.props.productToSetup}
						onClose={() => {
							console.log('Closing Communication Preferences');
							this.data.commPreferencesOpen = false;
						}}
					></CommPreferences>
				)}
				<PurchaseHistory
					opened={this.data.purchaseHistoryOpen}
					onClose={() => {
						console.log('Closing Purchase History');
						this.data.purchaseHistoryOpen = false;
					}}
				></PurchaseHistory>
				<SupportPage
					opened={this.data.supportPageOpen}
					onClose={() => {
						console.log('Closing Support Page');
						this.data.supportPageOpen = false;
					}}
				></SupportPage>
				<ProductSetupSheet
					opened={this.data.openSetupSheet}
					product={this.props.productToSetup || null}
					updatePIN={this.data.openUpdatePIN}
					onClosedSheet={() => {
						this.data.openSetupSheet = false;
						this.data.openUpdatePIN = false;
						this.data.productToSetup = null;
					}}
				></ProductSetupSheet>
				<Popup
					className={`manage-profile-popup ${!appStore.isMobile ? 'desktop' : 'mobile'}`}
					opened={this.data.profileOpen}
					closeByBackdropClick={false}
					closeOnEscape={false}
					onPopupOpened={this.handleEditOpen}
					onPopupClosed={this.closeProfilePopup}
					backdrop={false}
					push
				>
					<div className="popup-header hbox vcenter">
						<Link popupClose className="back-btn hbox vcenter">
							<i className="bx bx-arrow-back"></i> Back
						</Link>
						<div className="grow-1"></div>
						<img src={behavrLogo} className="header-logo" alt="BehaVR Logo" />
					</div>
					<div className="main-content y-scroll">
						<h1>
							Edit<br></br> Profile Details
						</h1>
						{this.data.profileOpen && (
							<FormBuilder
								showErrorState={true}
								formData={this.data.profileData}
								formConfig={{
									firstName: {
										label: 'First Name',
										placeholder: 'Your first name',
										type: 'text',
										validator: {
											type: 'length',
											value: 1
										}
									},
									lastName: {
										label: 'Last Name',
										placeholder: 'Your last name',
										type: 'text',
										validator: {
											type: 'length',
											value: 1
										}
									},
									email: {
										label: 'Email',
										placeholder: 'your@email.com',
										type: 'email',
										validator: {
											type: 'email'
										}
									},
									dateOfBirth: {
										label: 'Date of Birth',
										type: 'date',
										validator: {
											type: 'date'
										}
									},
									dueDate: {
										hidden: !_.get(this.data.profileData, 'dueDate', null),
										label: 'Due Date',
										type: 'date',
										icon: 'bx bx-calendar-heart',
										disabledRange: {
											to: new Date(new Date().getTime() - 1000 * 60 * 60 * 24)
										},
										validator: {
											type: 'date'
										}
									},
									deliveryDate: {
										hidden: !_.get(this.data.profileData, 'deliveryDate', null),
										label: 'Delivery Date',
										type: 'date',
										icon: 'bx bx-calendar-heart',
										disabledRange: {
											from: new Date(new Date().getTime())
										},
										validator: {
											type: 'date'
										}
									},
									street1: {
										label: 'Street',
										placeholder: 'Your street address',
										type: 'text',
										hidden: _.isEmpty(_.get(appStore, 'user.street1')),
										validator: {
											type: 'length',
											value: 1
										}
									},
									street2: {
										label: 'Street 2',
										placeholder: 'Your Street address',
										type: 'text',
										hidden: _.isEmpty(_.get(appStore, 'user.street1')),
										validator: {
											notRequired: true,
											type: 'length',
											value: 1
										}
									},
									city: {
										label: 'City',
										type: 'text',
										placeholder: 'City',
										hidden: _.isEmpty(_.get(appStore, 'user.city')),
										validator: {
											type: 'length',
											value: 1
										}
									},
									state: {
										label: 'State',
										type: 'dropdown',
										hidden: _.isEmpty(_.get(appStore, 'user.state')),
										className: 'dropdown-form-input state-dropdown',
										listItems: StateAbbrvMap
									},
									zip: {
										label: 'Postal Code (5-digit)',
										type: 'zipCode',
										placeholder: 'Postal Code',
										hidden: _.isEmpty(_.get(appStore, 'user.zip'))
									}
								}}
								setValidationState={(valid) => {
									this.data.profileValid = valid;
								}}
							></FormBuilder>
						)}
						{_.get(this.data.profileData, 'dueDate', null) && this.buildSkinTonePicker()}
						<Button
							fill
							large
							className="primary-btn"
							id="signup-button"
							disabled={!this.data.profileValid}
							onClick={this.onSaveProfile}
						>
							Save
						</Button>
					</div>
				</Popup>
				<VideoPopup
					opened={!_.isEmpty(appStore.openVideoKey)}
					videoSrc={appStore.openVideoKey}
					onPopupClosed={() => {
						appStore.openVideoKey = null;
					}}
				></VideoPopup>
				<IOSInstallPrompt
					opened={this.data.iosInstallOpened}
					onClose={() => {
						this.data.iosInstallOpened = false;
					}}
				></IOSInstallPrompt>
			</Popup>
		);
	}
}
