import React, { Component } from 'react';
import { observer, observable } from '@/utils/State';
import { autoBind } from '@/utils/GeneralUtils';
import { Link, Popup, Preloader } from 'framework7-react';
import behavrLogo from '@/assets/logo.png';
import appStore from '@/stores/AppStore';
import APIService from '@/services/APIService';
import './purchase-history.scss';
import _ from 'lodash';
import { ReceiptSVG } from '@/components/product-setup-sheet/SVGAssets';
import { format } from 'date-fns';

@observer
export default class PurchaseHistory extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			loading: true,
			invoices: []
		});
		autoBind(this);
	}

	closeLinkPopup() {
		this.data.invoices = [];
		if (this.props.onClose) {
			this.props.onClose();
		}
	}

	handlePopupOpen() {
		console.log('Purchase History Popup Opened');
		APIService.getUserInvoices().then((res) => {
			this.data.invoices = res.invoices;
			this.data.loading = false;
		});
	}

	getProductPrettyName(product) {
		switch (product) {
			case 'nurture': {
				return 'NurtureVR';
			}
			case 'sav': {
				return 'SAV - Anxiety Regulation';
			}
			case 'first-resort': {
				return 'First Resort';
			}
			case 'bvr100': {
				return 'BVR-100';
			}
			default: {
				return product;
			}
		}
	}

	//TODO currently assumeing Nurture is the only product, so default in the scenario where the eneity doesn't have a matching price
	buildInvoiceList() {
		let { invoices } = this.data;
		let { referringEntity } = appStore;
		return invoices.map((invoice, index) => {
			const products = referringEntity?.productConfig?.products;
			let product;
			_.forEach(products, (p) => {
				if (p.prices && p.prices.indexOf(invoice.priceId) >= 0) {
					product = p.type;
				}
			});

			return (
				<div
					key={`invoice-item-${index}`}
					className="invoice hbox vcenter"
					onClick={() => {
						window.open(invoice.url, '_blank');
					}}
				>
					<div className="details">
						<div className="product">{this.getProductPrettyName(product)}</div>
						<div className={`status ${invoice.paid && 'paid'}`}>{invoice.paid ? 'Payment Processed' : 'Awaiting Payment'}</div>
						<div className="date">{format(new Date(invoice.date * 1000), 'PPPP')}</div>
					</div>
					<div className="amount">{`$${invoice.amount / 100}`}</div>
				</div>
			);
		});
	}

	render() {
		let { opened } = this.props;
		let { loading, invoices } = this.data;
		return (
			<Popup
				className={`purchase-history-popup ${!appStore.isMobile ? 'desktop' : 'mobile'}`}
				opened={opened}
				closeByBackdropClick={false}
				closeOnEscape={false}
				onPopupClosed={this.closeLinkPopup}
				onPopupOpened={this.handlePopupOpen}
			>
				<div className="popup-header hbox vcenter">
					<Link popupClose className="back-btn hbox vcenter">
						<i className="bx bx-arrow-back"></i> Back
					</Link>
					<div className="grow-1"></div>
					<img src={behavrLogo} className="header-logo" alt="BehaVR Logo" />
				</div>
				<div className="main-content y-scroll">
					<h1>My Purchases</h1>
					{loading && (
						<div className="hbox vcenter hcenter">
							<Preloader size={44} color="blue"></Preloader>
						</div>
					)}
					{!loading && invoices.length == 0 && (
						<div className="empty-msg">
							<div className="empty-svg">{ReceiptSVG}</div>
							<h2>No Purchase History</h2>
						</div>
					)}
					{invoices.length > 0 && this.buildInvoiceList()}
				</div>
			</Popup>
		);
	}
}
