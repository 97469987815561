import appStore from '@/stores/AppStore';
import Framework7 from 'framework7';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { generateId, getStripeKey, getUrlParams } from '@/utils/GeneralUtils';
import CacheStorageService from './CacheStorageService';
import { f7 } from 'framework7-react';
const LOCAL_IP = '192.168.50.103';

const getBaseURL = () => {
	switch (location.hostname) {
		case 'localhost': {
			return 'http://localhost:3021/api/v1';
		}
		case '0.0.0.0': {
			return 'http://localhost:3021/api/v1';
		}
		case LOCAL_IP: {
			return `http://${LOCAL_IP}:3021/api/v1`;
		}
		case 'behavr.app': {
			return 'https://production-cloudapi.azurewebsites.net/api/v1';
		}
		case 'irbehavr.web.app': {
			return 'https://refinery-cloudapi.azurewebsites.net/api/v1';
		}

		case 'dev.behavr.app': {
			return 'https://development-cloudapi.azurewebsites.net/api/v1';
		}
		case 'staging.behavr.app': {
			return 'https://staging-cloudapi.azurewebsites.net/api/v1';
		}
		default: {
			return 'http://localhost:3021/api/v1';
		}
	}
};

console.log(`MY BASE URL IS ${getBaseURL()}`);

class APIService {
	async getHeaders(noToken) {
		const headers = { 'Access-Control-Allow-Origin': '*' };
		if (!noToken) {
			let refreshTime = new Date().getTime() - appStore.tokenRefreshed;
			//* Force refresh if it's been > than 3 mins
			if (refreshTime > 180000) {
				console.log(`Refreshing Token...`);
				const token = await firebase.auth().currentUser.getIdToken(true);
				appStore.tokenRefreshed = new Date().getTime();
				appStore.userToken = token;
			}
			headers.authorization = appStore.userToken;
		}
		return headers;
	}

	async get(url, noToken, noBase) {
		try {
			const response = await axios({
				method: 'GET',
				url: noBase ? url : getBaseURL() + url,
				headers: await this.getHeaders(noToken)
			});
			return response.data;
		} catch (err) {
			return Promise.reject(_.get(err, 'response.data', { message: 'Unknown Error' }));
		}
	}

	async post(url, data, noToken, noBase) {
		try {
			const response = await axios({
				method: 'POST',
				contentType: 'application/json',
				url: noBase ? url : getBaseURL() + url,
				headers: await this.getHeaders(noToken),
				data: data
			});
			return response.data;
		} catch (err) {
			console.log(err);
			return Promise.reject(_.get(err, 'response.data', { message: 'Unknown Error' }));
		}
	}

	async patch(url, data, noToken, noBase) {
		try {
			const response = await axios({
				method: 'PATCH',
				contentType: 'application/json',
				url: noBase ? url : getBaseURL() + url,
				headers: await this.getHeaders(noToken),
				data: data
			});
			return response.data;
		} catch (err) {
			console.log(err);
			return Promise.reject(_.get(err, 'response.data', { message: 'Unknown Error' }));
		}
	}

	async put(url, data, noToken, noBase) {
		try {
			const response = await axios({
				method: 'PUT',
				contentType: 'application/json',
				url: noBase ? url : getBaseURL() + url,
				headers: await this.getHeaders(noToken),
				data: data
			});
			return response.data;
		} catch (err) {
			console.log(err);
			return Promise.reject(_.get(err, 'response.data', { message: 'Unknown Error' }));
		}
	}

	async delete(url, noToken, noBase) {
		try {
			const response = await axios({
				method: 'DELETE',
				contentType: 'application/json',
				url: noBase ? url : getBaseURL() + url,
				headers: await this.getHeaders(noToken)
			});
			return response.data;
		} catch (err) {
			console.log(err);
			return Promise.reject(_.get(err, 'response.data', { message: 'Unknown Error' }));
		}
	}

	async getProductPrice(id) {
		return await this.get(`/payments/product/price/${encodeURIComponent(id)}`);
	}

	async getUserInvoices() {
		return await this.get(`/payments/invoices`);
	}

	async createSubscription(stripePriceId, promoCode) {
		return await this.post(`/payments/subscription/initiate`, { stripePriceId, promoCode });
	}

	async finalizeSubscription(stripeSubscriptionId) {
		return await this.patch(`/payments/subscription/finalize`, { stripeSubscriptionId });
	}

	async verifyEntity() {
		let urlParams = getUrlParams();
		delete urlParams['ref'];
		const CS = new CacheStorageService();
		let clientId = await CS.getValue('entities', 'clientId');
		if (!clientId) {
			clientId = generateId();
			CS.saveValue('entities', 'clientId', clientId);
		}
		urlParams.clientId = clientId;
		return await this.post(`/referring-entities/verify/${encodeURIComponent(appStore.referringEntityId)}`, urlParams, true);
	}

	async getReferringEntity(id) {
		return await this.get(`/referring-entities/id/${encodeURIComponent(id)}`);
	}

	async verifyPhone(phone) {
		return await this.post(`/user/verify-phone`, { phone: phone, referrer: appStore.referringEntityId }, true);
	}

	async createUser(userPayload) {
		return await this.post(`/user/create`, userPayload);
	}

	async getUserProgress() {
		return await this.get(`/user/metadata`);
	}

	async acknowledgeNotification(product, notification) {
		return await this.get(`/user/config/${product}/${notification}/acknowledged`);
	}

	async getTempThoughts() {
		return await this.get(`/user/tempThoughts`);
	}

	async completeTempThought(tempThoughtId, thought) {
		return await this.patch(`/thoughts/complete/${tempThoughtId}`, thought);
	}

	async getThoughts() {
		return await this.get(`/thoughts`);
	}

	async getGoalCompletions(id) {
		return await this.get(`/goals/${id}/completions`);
	}

	async getGoals() {
		return await this.get(`/goals`);
	}

	async getLongTermGoals() {
		try {
			const ltg = await this.get('/goals/longterm');
			if (!ltg || ltg.length < 1) {
				return [2, 5, 8];
			}
			console.log(`Long Term Goals Loaded: ${ltg}`);
			return ltg;
		} catch (err) {
			console.log(err);
			return [2, 5, 8];
		}
	}

	async saveGoal(goal) {
		//* Clean repeatable data
		if (!goal.deadline.repeatable) {
			delete goal.deadline.repeat;
		} else {
			delete goal.deadline.deadlineTiming;
			delete goal.deadline.deadlineDate;
			if (!goal.deadline.repeat.hasStart) {
				delete goal.deadline.repeat.startDate;
			}
			if (!goal.deadline.repeat.hasEnd) {
				delete goal.deadline.repeat.endDate;
			}
			delete goal.deadline.repeat.hasStart;
			delete goal.deadline.repeat.hasEnd;
			if (goal.deadline.repeat.frequency !== 'week') {
				delete goal.deadline.repeat.cadence;
			}
		}

		goal.measurements.forEach((m) => {
			m.amount = Number(m.amount);
		});

		//* Clean Reminder
		if (!_.isEmpty(goal.reminder.bestTime)) {
			goal.reminder = { bestTime: goal.reminder.bestTime };
		} else {
			delete goal.reminder;
		}
		return await this.post('/goals', goal);
	}

	async updateGoal(goal) {
		//* Clean repeatable data
		if (!goal.deadline.repeatable) {
			delete goal.deadline.repeat;
		} else {
			delete goal.deadline.deadlineTiming;
			delete goal.deadline.deadlineDate;
			if (!goal.deadline.repeat.hasStart) {
				delete goal.deadline.repeat.startDate;
			}
			if (!goal.deadline.repeat.hasEnd) {
				delete goal.deadline.repeat.endDate;
			}
			delete goal.deadline.repeat.hasStart;
			delete goal.deadline.repeat.hasEnd;
			if (goal.deadline.repeat.frequency !== 'week') {
				delete goal.deadline.repeat.cadence;
			}
		}

		goal.measurements.forEach((m) => {
			m.amount = Number(m.amount);
		});

		//* Clean Reminder
		if (!_.isEmpty(goal.reminder.bestTime)) {
			goal.reminder = { bestTime: goal.reminder.bestTime };
		} else {
			delete goal.reminder;
		}

		return await this.put('/goals', goal);
	}

	async deleteEntireGoal(id) {
		return await this.delete(`/goals/${id}/all`);
	}

	async deleteGoal(id) {
		return await this.delete(`/goals/${id}`);
	}

	async updateGoalStatus(id, completed) {
		return await this.patch(`/goals/${id}/status`, { completed });
	}

	async createThought(thought) {
		return await this.post(`/thoughts`, thought);
	}

	async updateThought(thought) {
		return await this.patch(`/thoughts/${thought.id}`, thought);
	}

	async findAddress(query) {
		return await this.post(`/user/findAddress`, { query });
	}

	async getAddressDetails(placedId) {
		return await this.get(`/user/addressDetails/${placedId}`);
	}

	async registerExistingUser(profileData) {
		return await this.patch(`/user/registerExistingUser/${appStore.referringEntityId}`, profileData);
	}

	async login() {
		return await this.get(`/user/login/${encodeURIComponent(appStore.referringEntityId)}`);
	}

	async getMyDetails() {
		return await this.get(`/user/details`);
	}

	async updateUserTimezone() {
		return await this.post('/user/updateTimezone', {
			timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
			timezoneOffset: String(new Date().getTimezoneOffset())
		});
	}

	async saveProfile(profileData) {
		return await this.patch(`/user/profile`, profileData);
	}

	async saveCommunicationPreferences(product, commPreferences) {
		return await this.patch(`/user/profile/communicationPreferences/${product}`, commPreferences);
	}

	async initializeProduct(productType, invoiceId) {
		return await this.post(`/user/initialize-product`, {
			productType,
			referringEntityId: appStore.referringEntityId,
			invoiceId: invoiceId
		});
	}

	async completeProductSetup(productType, setupPayload) {
		return await this.patch(`/user/complete-setup`, { productType, ...setupPayload });
	}

	async updateHeadsetPIN(code) {
		return await this.patch(`/user/profile`, { pin: code });
	}

	async syncHeadset(code) {
		return await this.post(`/user/sync-headset`, { sponsorToken: code });
	}

	async submitSupportTicket(description) {
		return await this.post(`/user/support`, { description });
	}

	async getDeviceSyncs() {
		return await this.get('/user/devicesyncs');
	}

	async donatedHeadset() {
		return await this.post(`/user/return-headset`);
	}
}
export default new APIService();
