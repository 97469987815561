import React, { Component } from 'react';
import { autoBind } from '../../utils/GeneralUtils';
import { observer } from '../../utils/State';
import { CardElement } from '@stripe/react-stripe-js';
import './credit-card-input.scss';

@observer
export default class CreditCardInput extends Component {
	constructor(props) {
		super(props);
		autoBind(this);
	}

	onValidate(data) {
		if (this.props.onValidate) {
			this.props.onValidate(data);
		}
	}

	onFocus(evt) {
		if (this.props.onFocus) {
			this.props.onFocus();
		}
	}

	render() {
		let { errorMessage, label, className, onChange } = this.props;
		return (
			<div className={`credit-card-input ${className ? className : ''}`}>
				<div className="label">{label}</div>
				<div className="gradiant">
					<CardElement
						options={{
							style: {
								base: {
									fontSize: '14px',
									color: '#64748b'
								},
								invalid: {
									color: '#9e2146'
								}
							}
						}}
						onChange={onChange}
					/>
				</div>
				<div className="error-message">{errorMessage}</div>
			</div>
		);
	}
}
