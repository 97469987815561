import React, { Component } from 'react';
import { Button, f7, Link, Page, Preloader } from 'framework7-react';
import { observable, observer } from '@/utils/State';
import appStore from '@/stores/AppStore';
import { autoBind, cleanBehaDate, formatPhoneNumber } from '@/utils/GeneralUtils';
import CacheStorageService from '@/services/CacheStorageService';
import lock from '@/assets/lock.png';
import appleStore from '@/assets/appstore.png';
import playstore from '@/assets/playstore.png';
import behavrLogoPNG from '@/assets/behavrlogo.png';
import nurtureLogo from '@/assets/nurtureLogo.png';
import iosSharePNG from '@/assets/iosshare.png';
import iosPWAInstallPNG from '@/assets/iospwaadd.png';
import vrxPng from '@/assets/vrx.png';
import hoagLogo from '@/assets/hoag.png';
import FormBuilder from '@/components/form-builder/FormBuilder';
import BasicInput from '@/components/basic-input/BasicInput';
import './signup.scss';
import StateAbbrvMap from '@/utils/StateAbbrvMap';
import APIService from '@/services/APIService';
import AuthService from '@/services/AuthService';
import TermsPopup from './TermsPopup';
import _ from 'lodash';
import AnalyticsService, { TrackedEvents } from '@/services/AnalyticsService';
import CommPreferences from '../communication-preferences/CommPreferences';
const CS = new CacheStorageService();
@observer
class Signup extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			loading: true,
			logoClickCount: 0,
			verified: false,
			step: 0,
			step1Valid: false,
			step2Valid: false,
			step3Valid: false,
			termsOpen: false,
			acceptedEndUserTerms: false,
			acceptedMarketingTerms: false,
			acceptedContactTerms: false,
			step1Data: { phone: '' },
			step2Data: { code: '' },
			step3Data: {
				firstName: '',
				lastName: '',
				email: '',
				dateOfBirth: new Date().setFullYear(1990, 0, 1)
			},
			addressForm: {
				street: '',
				street2: '',
				city: '',
				state: '',
				zip: ''
			},
			addressValid: false,
			addressInput: '',
			searchResults: [],
			address: null,
			searching: false,
			iosfix: '',
			partnerConfig: null,
			products: [],
			commPreferencesOpen: false
		});
		autoBind(this);
	}

	async componentDidMount() {
		if (appStore.referringEntityId) {
			APIService.verifyEntity()
				.then((res) => {
					if (res.exists) {
						this.data.partnerConfig = res.partnerConfig;
						this.data.products = res.products;
						this.data.loading = false;
						this.data.step = 1;
					} else {
						this.data.loading = false;
						this.data.step = 0;
					}
				})
				.catch((err) => {
					console.log(err);
					this.data.loading = false;
					this.data.step = 0;
				});
		} else if (appStore.isPWA()) {
			this.data.loading = false;
			this.data.step = 1;
		} else {
			this.data.step = 0;
			this.data.loading = false;
		}
	}

	launchHomeExperience() {
		let hasFirstResort = _.find(_.get(appStore, 'user.productStatus', []), { product: 'first-resort' });
		if (hasFirstResort && hasFirstResort.setup) {
			const hasShownPrefs = CS.getValue('entities', 'showedCommPrefs');
			if (_.get(appStore, 'user.acceptedContactTerms', false) && !hasShownPrefs) {
				CS.saveValue('entities', 'showedCommPrefs', true);
				this.data.commPreferencesOpen = true;
			} else {
				f7.view?.main?.router?.navigate('/bvr-landing');
			}
		} else {
			f7.view?.main?.router?.navigate('/home');
		}
	}

	showInstallPage() {
		CS.saveValue('entities', `referringEntityId`, appStore.referringEntityId);
		//skip install and continute home
		if (appStore.isPWA() || !appStore.isMobile) {
			this.launchHomeExperience();
		} else {
			//show pwa install page
			this.data.step = 4;
		}
	}

	onShowInstallPrompt() {
		AnalyticsService.track(TrackedEvents.INSTALL_PWA);
		//show ios pwa install prompt
		if (appStore.deviceDetails.ios) {
			this.data.step = 5;
		} else if (window.deferredPrompt) {
			//show adnroid pwa install prompt
			window.deferredPrompt.prompt();
		}
	}

	skipInstall() {
		AnalyticsService.track(TrackedEvents.SKIP_PWA_INSTALL);
		this.launchHomeExperience();
	}

	onSaveUser() {
		f7.dialog.preloader();
		AnalyticsService.track(TrackedEvents.SUBMIT_REGISTRATION);
		let { step1Data, step3Data, addressForm, acceptedEndUserTerms, acceptedContactTerms, acceptedMarketingTerms } = this.data;
		if (appStore.existingBehaUser) {
			APIService.registerExistingUser({
				acceptedEndUserTerms,
				acceptedContactTerms,
				acceptedMarketingTerms,
				...step3Data,
				...addressForm,
				street1: addressForm.street,
				referringEntityId: appStore.referringEntityId,
				practiceId: appStore.existingBehaUser.practiceId,
				userId: appStore.existingBehaUser.userId
			})
				.then(async () => {
					const isUserSignedUp = await AuthService.cloudAPILogin();
					if (!isUserSignedUp) {
						f7.dialog.close();
						this.data.step = 3;
					} else {
						appStore.existingBehaUser = null;
						f7.dialog.close();
						this.showInstallPage();
					}
				})
				.catch((err) => {
					console.log(err);
					f7.dialog.close();
					f7.dialog.alert(`${err.message}`);
				});
		} else {
			const userPayload = {
				acceptedEndUserTerms,
				acceptedContactTerms,
				acceptedMarketingTerms,
				...step1Data,
				...step3Data,
				...addressForm,
				fId: appStore.firebaseUser.uid,
				referringEntityId: appStore.referringEntityId
			};
			APIService.createUser(userPayload)
				.then(async () => {
					const isUserSignedUp = await AuthService.cloudAPILogin();
					if (!isUserSignedUp) {
						f7.dialog.close();
						this.data.step = 3;
					} else {
						appStore.referringEntityId = appStore.user.referringEntityId;
						f7.dialog.close();
						this.showInstallPage();
					}
				})
				.catch((err) => {
					console.log(err);
					f7.dialog.close();
					f7.dialog.alert(`${err.message}`);
				});
		}
	}

	isFormValid() {
		if (_.get(appStore, `referringEntity.headsetType`, null) === 'pico') {
			return !this.data.step3Valid || !this.data.addressValid || !this.data.acceptedEndUserTerms;
		} else {
			return !this.data.step3Valid || !this.data.acceptedEndUserTerms;
		}
	}

	async resendCode() {
		f7.dialog.preloader();
		try {
			await AuthService.sendSigninCode(this.data.step1Data.phone);
			f7.dialog.close();
			f7.dialog.alert(`Code sent to ${this.data.step1Data.phone}`, 'Success!');
		} catch (err) {
			f7.dialog.close();
			f7.dialog.alert('Something went wrong, please try again', 'Oops');
			console.log(err);
		}
	}

	async onSignup() {
		AuthService.initRecaptcha();
		this.data.step1Data.phone = _.clone(this.data.step1Data.phone).replace('+1', '');
		f7.dialog.preloader();
		AnalyticsService.track(TrackedEvents.REQUEST_VERIFICATION_CODE);
		APIService.verifyPhone(this.data.step1Data.phone)
			.then(async () => {
				try {
					const sendCode = await AuthService.sendSigninCode(this.data.step1Data.phone);
					f7.dialog.close();
					if (sendCode.sent) {
						this.data.step = 2;
					} else {
						AnalyticsService.track(TrackedEvents.INVALID_PHONE_SUBMISSION);
						switch (sendCode.errorCode) {
							case 'auth/invalid-phone-number': {
								f7.dialog.alert(`This doesn't look like a valid phone number. Please change the input and try again.`, 'Oops');
								break;
							}
							default: {
								f7.dialog.alert(`Something went wrong, Please change the input and try again.`, 'Oops');
								break;
							}
						}
					}
				} catch (err) {
					f7.dialog.close();
					console.log(err);
				}
			})
			.catch((err) => {
				console.log(err);
				f7.dialog.close();
			});
	}

	setExistingUserData() {
		if (appStore.existingBehaUser) {
			let { firstName, lastName, email, dateOfBirth, street1, street2, city, state, zip } = appStore.existingBehaUser;
			let cleanDate = cleanBehaDate(dateOfBirth);
			if (cleanDate.getFullYear() < 1960) {
				cleanDate = new Date().setFullYear(1990, 0, 1);
			}
			this.data.step3Data = {
				firstName: _.clone(firstName),
				lastName: _.clone(lastName),
				email: _.clone(email),
				dateOfBirth: cleanDate
			};
			if (!_.isEmpty(street1)) {
				this.data.addressForm = {
					street: _.clone(street1),
					street2: _.clone(street2),
					city: _.clone(city),
					state: _.clone(state),
					zip: _.clone(zip)
				};
				this.data.address = { description: `${street1}, ${street2}, ${city}, ${state}, ${zip}` };
			}
		}
	}

	async onVerifyCode() {
		f7.dialog.preloader('Verifying...');
		const status = await AuthService.confirmSigninCode(`${this.data.step2Data.code}`.trim());
		if (status && status.success) {
			AnalyticsService.track(TrackedEvents.VERIFIED_PHONE);
			const isUserSignedUp = await AuthService.cloudAPILogin();
			if (isUserSignedUp === 'inactive') {
				return;
			}
			let entityId = !_.isEmpty(appStore.referringEntityId) ? appStore.referringEntityId : appStore.user.referringEntityId;
			if (!isUserSignedUp && !_.isEmpty(entityId)) {
				//* Setup global referring entity
				APIService.getReferringEntity(entityId)
					.then((res) => {
						appStore.referringEntity = res;
						this.setExistingUserData();
						this.data.step = 3;
						f7.dialog.close();
					})
					.catch((err) => {
						console.log(err);
						f7.dialog.close();
					});
			} else if (!isUserSignedUp && _.isEmpty(entityId)) {
				f7.dialog.close();
				f7.dialog.alert('In order to create an account ensure you access BehaVR via your provided link', 'No Account Found', () => {
					location.reload();
				});
			} else {
				//* Success user exists and is signed up already
				console.log('User Signed UP, launching HOME');
				f7.dialog.close();
				this.launchHomeExperience();
			}
		} else {
			f7.dialog.close();
			f7.dialog.alert('Please try again', 'Invalid Code');
		}
	}

	doAddressSearch() {
		this.data.searching = true;
		if (this.fireOffSearch) {
			clearTimeout(this.fireOffSearch);
		}
		this.fireOffSearch = setTimeout(() => {
			if (this.data.addressInput.length >= 5) {
				AnalyticsService.track(TrackedEvents.ADDRESS_SEARCH);
				APIService.findAddress(this.data.addressInput)
					.then(async (res) => {
						this.data.searching = false;
						clearTimeout(this.fireOffSearch);
						this.fireOffSearch = null;
						this.data.searchResults = res;
					})
					.catch((err) => {
						this.data.searching = false;
						clearTimeout(this.fireOffSearch);
						this.fireOffSearch = null;
						console.log(err);
					});
			} else {
				this.data.searching = false;
			}
		}, 500);
	}

	onAddressChange(e) {
		this.data.addressInput = e.target.value;
		if (this.data.addressInput.length >= 5) {
			this.doAddressSearch();
		}
	}

	setAddressFields(prediction) {
		if (prediction) {
			APIService.getAddressDetails(prediction.place_id).then((res) => {
				let postalCode = '';
				try {
					let components = _.get(res, 'result.address_components', []);
					for (let i = 0; i < components.length; i++) {
						let part = components[i];
						if (part && part.types[0] === 'postal_code') {
							postalCode = part.short_name;
						}
					}
				} catch (err) {
					console.log(err);
				}
				setTimeout(() => {
					let parts = prediction.description.split(',');
					this.data.addressForm.street = `${_.trim(_.get(parts, '[0]', ''))}`;
					this.data.addressForm.city = `${_.trim(_.get(parts, '[1]', ''))}`;
					this.data.addressForm.state = `${_.trim(_.get(parts, '[2]', '').toLowerCase())}`;
					this.data.addressForm.zip = postalCode;
					this.data.addressValid = true;
					this.data.address = prediction;
				}, 0);
			});
		}
	}

	getDownloadOptions() {
		return (
			<div className="download vbox vcenter hcenter">
				<Button
					fill
					large
					className="primary-btn"
					onClick={() => {
						window.open('https://behavr.com', '_blank');
					}}
				>
					Get Started
				</Button>
			</div>
		);
	}

	getActiveStep() {
		if (this.data.loading) {
			return (
				<div className="loader vbox vcenter hcenter">
					<Preloader size={44} color="blue"></Preloader>
				</div>
			);
		}
		if (!this.data.loading && this.data.step === 0) {
			return (
				<div className="no-entity">
					<h1>Welcome</h1>
					<div className="img-ctn vbox vcenter hcenter">
						<img src={vrxPng} alt="User In VR Session Picture" />
					</div>
					<div className="success-message">
						To learn more about our product please visit the link below.
						<br />
						<br />
						If you've already purchased a BehaVR product, or have been told you have access to one, please ensure you use the link
						provided to you to access the service.
					</div>
					{this.getDownloadOptions()}
				</div>
			);
		}

		switch (this.data.step) {
			case 1: {
				const hasFR = _.find(this.data.products, { type: 'first-resort' });
				console.log(hasFR);
				return (
					<div className="form" key="phonenumber-form">
						<h1>Welcome</h1>
						{appStore.isD2CInstance && (
							<div className="d2c-message">
								In order to complete your purchase, we ask that you start by creating a profile.This will make it easier for you to
								launch <strong>NurtureVR</strong> once your headset arrives.
							</div>
						)}
						<FormBuilder
							formData={this.data.step1Data}
							formConfig={{
								phone: {
									label: 'Mobile Phone:',
									placeholder: 'Enter Your phone number',
									type: 'phone',
									validator: {
										type: 'phone'
									}
								}
							}}
							setValidationState={(valid) => {
								this.data.step1Valid = valid;
							}}
						></FormBuilder>
						<Button fill large className="primary-btn" id="signup-button" disabled={!this.data.step1Valid} onClick={this.onSignup}>
							Submit
						</Button>
						<div className="subtext">
							A verification code will be sent to your phone via SMS. By clicking submit, you agree to receive messages via SMS.
						</div>
						{hasFR && (
							<div className="health-cta">
								<div className="cta-text">
									This app is not a substitute for professional care and does not connect you with a live physician.{' '}
									<strong>
										If you are having suicidal thoughts or you or someone else are experiencing an emergency, you should immediately
										call 9-1-1 and notify the relevant authorities.
									</strong>
								</div>
							</div>
						)}
						{this.data.partnerConfig && (
							<div
								className="partner-cta"
								onClick={() => {
									if (!_.isEmpty(this.data.partnerConfig.link)) {
										AnalyticsService.track(TrackedEvents.PARTNER_LINK_CLICK);
										window.open(this.data.partnerConfig.link, '_blank');
									}
								}}
							>
								<div className="cta-text">In Partnership With</div>
								<img
									src={this.data.partnerConfig.logoUrl.indexOf('hoag') >= 0 ? hoagLogo : this.data.partnerConfig.logoUrl}
									className="partner-logo"
									alt="Partner Logo"
								/>
							</div>
						)}
					</div>
				);
			}
			case 2: {
				return (
					<div className="form" key="verification-form">
						<div className="subheader hbox vcenter">
							<img src={lock} alt="Security Lock" />
							<div>
								<h1 className="thin">A code was sent to:</h1>
								<h1 className="phone">{formatPhoneNumber(this.data.step1Data.phone)}</h1>
							</div>
						</div>
						<FormBuilder
							formData={this.data.step2Data}
							formConfig={{
								code: {
									label: 'Enter Verification Code',
									placeholder: 'Enter Code Here',
									type: 'tel',
									className: 'verification',
									validator: {
										type: 'length',
										value: 6
									}
								}
							}}
							setValidationState={(valid) => {
								this.data.step2Valid = valid;
							}}
						></FormBuilder>
						<Button fill large className="primary-btn" disabled={!this.data.step2Valid} onClick={this.onVerifyCode}>
							Verify
						</Button>
						<Button className="resend-btn" onClick={this.resendCode}>
							Resend Code
						</Button>
						<Button
							className="back-btn"
							large
							onClick={() => {
								location.reload();
							}}
						>
							Back
						</Button>
					</div>
				);
			}
			case 3: {
				return (
					<div className="form" key="registration-form">
						<h1>Complete Registration</h1>
						<FormBuilder
							formData={this.data.step3Data}
							formConfig={{
								firstName: {
									label: 'First Name',
									placeholder: 'Your first name',
									type: 'text',
									validator: {
										type: 'length',
										value: 1
									}
								},
								lastName: {
									label: 'Last Name',
									placeholder: 'Your last name',
									type: 'text',
									validator: {
										type: 'length',
										value: 1
									}
								},
								dateOfBirth: {
									label: 'Date of Birth',
									type: 'date',
									validator: {
										type: 'date'
									}
								},
								email: {
									label: 'Email',
									placeholder: 'your@email.com',
									type: 'email',
									validator: {
										type: 'email'
									}
								}
							}}
							setValidationState={(valid) => {
								if (!valid || this.data.step3Data.password !== this.data.step3Data.confirm) {
									this.data.step3Valid = false;
								} else {
									this.data.step3Valid = true;
								}
							}}
						></FormBuilder>
						{_.get(appStore, `referringEntity.headsetType`, null) === 'pico' && !this.data.address && (
							<BasicInput
								key={`address-input-key`}
								label="Shipping Address"
								type="text"
								placeholder="Input your shipping address"
								value={this.data.addressInput}
								onChange={this.onAddressChange}
								className="address-input"
								icon={<i className="bx bx-search"></i>}
							/>
						)}
						{this.data.searching && (
							<div className="loader-ctn hbox vcenter hcenter">
								<Preloader color="blue"></Preloader>
							</div>
						)}
						{!this.data.searching && !this.data.address && this.data.searchResults.length > 0 && (
							<>
								<div className="select-text">Select your shipping address</div>
								{this.data.searchResults.map((prediction) => {
									return (
										<div
											className="address-card"
											key={prediction.place_id}
											onClick={() => {
												this.setAddressFields(prediction);
											}}
										>
											{prediction.description}
										</div>
									);
								})}
							</>
						)}
						{this.data.address && (
							<FormBuilder
								showErrorState={true}
								formData={this.data.addressForm}
								formConfig={{
									street: {
										label: 'Shipping Street 1',
										type: 'text',
										placeholder: 'Your Street address',
										validator: {
											type: 'length',
											value: 1
										}
									},
									street2: {
										label: 'Shipping Street 2',
										type: 'text',
										placeholder: 'Your Street address'
									},
									city: {
										label: 'City',
										type: 'text',
										placeholder: 'City',
										validator: {
											type: 'length',
											value: 1
										}
									},
									state: {
										label: 'State',
										type: 'dropdown',
										className: 'dropdown-form-input state-dropdown',
										listItems: StateAbbrvMap
									},
									zip: {
										label: 'Postal Code (5-digit)',
										type: 'zipCode',
										placeholder: 'Postal Code',
										validator: {
											type: 'length',
											value: 5
										}
									}
								}}
								setValidationState={(valid) => {
									this.data.addressValid = valid;
								}}
							></FormBuilder>
						)}
						<div className="terms-toggle hbox vcenter">
							<div
								className="toggle-wrap"
								onClick={() => {
									this.data.acceptedEndUserTerms = !this.data.acceptedEndUserTerms;
								}}
							>
								<div className="toggle">
									{this.data.acceptedEndUserTerms && (
										<div>
											<span></span>
										</div>
									)}
								</div>
							</div>
							<div className="terms">
								By clicking this box, I agree and consent to BehaVR’s{' '}
								<a
									href="https://www.behavr.com/about-us/health-safety-and-policies/"
									target="_blank"
									onClick={() => {
										window.open('https://www.behavr.com/about-us/health-safety-and-policies/', '_blank');
									}}
								>
									Health and Safety Warning, and other Policies
								</a>
							</div>
						</div>
						<div className="terms-toggle hbox vcenter">
							<div
								className="toggle-wrap"
								onClick={() => {
									this.data.acceptedContactTerms = !this.data.acceptedContactTerms;
								}}
							>
								<div className="toggle">
									{this.data.acceptedContactTerms && (
										<div>
											<span></span>
										</div>
									)}
								</div>
							</div>
							<div className="terms">
								By clicking this box, I agree and consent to receive email and/or phone communications for help with setup, tips about
								the product, and access to our customer success team. Your wireless carrier's applicable rates apply.
							</div>
						</div>
						<div className="terms-toggle hbox vcenter">
							<div
								className="toggle-wrap"
								onClick={() => {
									this.data.acceptedMarketingTerms = !this.data.acceptedMarketingTerms;
								}}
							>
								<div className="toggle">
									{this.data.acceptedMarketingTerms && (
										<div>
											<span></span>
										</div>
									)}
								</div>
							</div>
							<div className="terms">
								By clicking this box, I am signing up to be part of the community and receive marketing emails.
							</div>
						</div>
						<Button fill large className="primary-btn" disabled={this.isFormValid()} onClick={this.onSaveUser}>
							Save
						</Button>
					</div>
				);
			}
			case 4: {
				return (
					<div className={`completed-section`} key={Math.random()}>
						<h1>Success!</h1>
						<h3>
							{_.get(appStore.user, 'productStatus[0].product', '') === 'first-resort'
								? `You have completed the BehaVR registration process for First Resort.`
								: `You have completed your BehaVR profile.`}
						</h3>
						{appStore.isD2CInstance && (
							<h3>
								Now it’s time to purchase your <strong>NurtureVR</strong> headset!
							</h3>
						)}
						<div className="success-message">
							To install the BehaVR app, tap the button below.<br></br>
							<br></br> Once installed you can launch the BehaVR app from your homescreen and use your newly created credentials to
							login.
						</div>
						<Button fill large className="primary-btn" onClick={this.onShowInstallPrompt}>
							Install
						</Button>
						<Button large className="text-btn" onClick={this.skipInstall}>
							Install Later
						</Button>
					</div>
				);
			}
			case 5: {
				return (
					<div className={`install-pwa y-scroll`} key={Math.random()}>
						<h1>How to Install</h1>
						<h3>First click here</h3>
						<img src={iosSharePNG} className="share" alt="iOS Install Instructions" />
						<h3>Once this screen appears, click the highlighted button.</h3>
						<img src={iosPWAInstallPNG} className="install" alt="iOS Install Butotn" />
						<Button large className="text-btn" onClick={this.skipInstall}>
							Install Later
						</Button>
					</div>
				);
			}
		}
	}

	onHelpCTA() {
		const support = {
			name: 'BehaVR',
			phone: '8669234287'
		};
		f7.dialog
			.create({
				title: 'Need Help?',
				text: `If you need help signing up please contact ${support.name} at ${formatPhoneNumber(support.phone)}`,
				buttons: [
					{
						text: 'Call Now',
						onClick: () => {
							const phoneUrl = `tel:${support.phone}`;
							if (!appStore.isMobile) {
								f7.dialog.alert(formatPhoneNumber(support.phone), 'Phone');
								return;
							}
							window.location.href = phoneUrl;
						}
					},
					{
						text: 'Cancel',
						onClick: () => {
							console.log('cancel clicked');
						}
					}
				],
				verticalButtons: false
			})
			.open();
	}

	render() {
		return (
			<Page className={`signup-page ${!appStore.isMobile ? 'desktop' : 'mobile'}`}>
				<div className={`background step-${this.data.step}`}></div>
				<div className={`signup-content step-${this.data.step}`}>
					{appStore.isD2CInstance ? (
						<div
							className="signup-header vbox vcenter hcenter d2c"
							onClick={() => {
								this.data.logoClickCount++;
								if (this.data.logoClickCount > 5) {
									appStore.disableRecaptcha = true;
									AuthService.disableRecaptcha();
								}
							}}
						>
							<img src={nurtureLogo} className="nurture-logo" alt="NurtureVR Icon" />
							<div className="nurture-text">NurtureVR</div>
							<div className="created-by">Created By:</div>
							<img src={behavrLogoPNG} className="icon-logo-d2c" alt="BehaVR Logo" />
						</div>
					) : (
						<div
							className="signup-header vbox vcenter hcenter"
							onClick={() => {
								this.data.logoClickCount++;
								if (this.data.logoClickCount > 5) {
									appStore.disableRecaptcha = true;
									AuthService.disableRecaptcha();
								}
							}}
						>
							<img src={behavrLogoPNG} className="icon-logo" alt="BehaVR Logo" />
						</div>
					)}
					{this.getActiveStep()}
				</div>
				<div className="version hvox vcenter hcenter">v1.4.2</div>
				<TermsPopup
					opened={this.data.termsOpen}
					onPopupClosed={this.closeTermsPopup}
					secondaryClick={() => {
						this.data.termsOpen = false;
					}}
					primaryClick={() => {
						this.data.termsOpen = false;
						this.data.acceptedEndUserTerms = true;
					}}
				></TermsPopup>
				<CommPreferences
					opened={this.data.commPreferencesOpen}
					product={'first-resort'}
					onClose={() => {
						console.log('Closing Communication Preferences');
						this.data.commPreferencesOpen = false;
						f7.view?.main?.router?.navigate('/bvr-landing');
					}}
				></CommPreferences>
				<div id="recaptcha"></div>
				<div className="help-cta" onClick={this.onHelpCTA}>
					<i className="f7-icons">question_circle_fill</i>
				</div>
			</Page>
		);
	}
}

export default Signup;
