import React from 'react';
import { Button, f7, Fab, Icon, Page, Preloader } from 'framework7-react';
import { observer, ReactiveComponent } from '@/utils/State';
import backgroundImage from '@/assets/bvrgoals.png';
import appStore, { getUserProductConfig } from '@/stores/AppStore';
import { autoBind, getDaysDelta, showError } from '@/utils/GeneralUtils';
import { Splash } from '../Splash';
import APIService from '@/services/APIService';
import BVRNav from '@/components/bvr-nav/BVRNav';
import goalsPNG from '@/assets/goalsIcon.png';
import './bvr-goals.scss';
import _ from 'lodash';
import GoalCapturePopup from '@/components/goal-capture-popup/GoalCapturePopup';
import { format, differenceInDays } from 'date-fns';
import GoalDetailsPopup from '@/components/goal-details-popup/GoalDetailsPopup';
import GoalEditPopup from '@/components/goal-edit-popup/GoalEditPopup';

class BVRGoals extends ReactiveComponent {
	constructor(props) {
		super(props);
		this.setData({
			goalList: [],
			filter: 'todo',
			loading: true,
			activeGoal: null,
			goalToEdit: null,
			goalDetailsOpen: false,
			goalCaptureOpen: false,
			goalEditOpen: false
		});
		autoBind(this);
		this.imgRef = React.createRef();
	}

	componentDidMount() {
		this.loadGoals();
		if (appStore.bvrCreateTarget && appStore.bvrCreateTarget === 'goal') {
			appStore.bvrCreateTarget = null;
			setTimeout(() => {
				this.data.goalCaptureOpen = true;
			}, 200);
		}
	}

	updateGoalStatus(id, completed) {
		f7.dialog.preloader('Loading...');
		APIService.updateGoalStatus(id, completed)
			.then(async (res) => {
				await this.loadGoals();
				f7.dialog.close();
			})
			.catch((err) => {
				console.log(err);
				showError(err, 'Unable to update goal');
			});
	}

	async loadGoals() {
		return new Promise((resolve) => {
			this.data.loading = true;
			let { goals } = getUserProductConfig('first-resort');
			if (goals) {
				APIService.getGoals()
					.then((res) => {
						this.data.goalList = res.sort((a, b) => {
							return new Date(a.deadline.deadlineDate) - new Date(b.deadline.deadlineDate);
						});
						this.data.loading = false;
						resolve();
					})
					.catch((err) => {
						showError(err);
						resolve();
					});
			} else {
				this.data.loading = false;
				resolve();
			}
		});
	}

	Filters() {
		let { filter } = this.data;
		let { goals } = getUserProductConfig('first-resort');
		if (!goals) {
			return <div className="divider-line"></div>;
		}
		return (
			<div className="filter-list hbox vcenter x-scroll">
				<div
					className={`filter ${filter === 'todo' && 'active'} grow-1`}
					onClick={() => {
						this.data.filter = 'todo';
					}}
				>
					<div>TO DO</div>
				</div>
				<div
					className={`filter ${filter === 'pastdue' && 'active'} grow-1`}
					onClick={() => {
						this.data.filter = 'pastdue';
					}}
				>
					<div>PAST DUE</div>
				</div>
				<div
					className={`filter ${filter === 'completed' && 'active'} grow-1`}
					onClick={() => {
						this.data.filter = 'completed';
					}}
				>
					<div>COMPLETED</div>
				</div>
			</div>
		);
	}

	GoalList() {
		//* If not unlocked show coming soon
		let { goals } = getUserProductConfig('first-resort');
		if (!goals) {
			return (
				<div className="empty-message">
					<div className="image-ctn">
						<img src={goalsPNG} alt="Lantern Icon" />
					</div>
					<h2>Coming Soon!</h2>
					<p>Stay on track with your VR practice to unlock this feature.</p>
				</div>
			);
		}

		//* If no goals show empty message
		let { goalList, filter } = this.data;
		if (goalList.length <= 0) {
			return (
				<div className="empty-message">
					<div className="image-ctn">
						<img src={goalsPNG} alt="Lantern Icon" />
					</div>
					<h2>No Goals to Work On</h2>
					<Button
						className="add-goal-btn"
						onClick={() => {
							this.data.goalCaptureOpen = true;
						}}
					>
						Let's add a SMART GOAL
					</Button>
				</div>
			);
		}
		const goalListItems = [];
		_.forEach(goalList, (goal) => {
			let { shortTermGoalId, summary, deadline, repeat, completedDate, status } = goal;
			let daysDelta = !_.isEmpty(deadline.deadlineDate) ? getDaysDelta(new Date(), new Date(deadline.deadlineDate)) : 0;
			let overdue = daysDelta > 0 ? true : false;
			let dueMessage = null;
			if (overdue) {
				dueMessage = `${daysDelta} ${daysDelta === 1 ? 'Day' : 'Days'} Overdue. ${
					deadline.repeatable ? 'Did you do it?' : 'Need more time?'
				}`;
			} else if (daysDelta === 0) {
				dueMessage = 'Due Today';
			}
			if (filter === 'completed') {
				dueMessage = null;
			}
			let showGoal = status == filter;
			if (status === 'todo' && daysDelta > 0) {
				showGoal = false;
			}
			if (daysDelta > 0 && filter === 'pastdue' && status !== 'completed') {
				showGoal = true;
			}

			if (showGoal) {
				goalListItems.push(
					<div className="goal-card-ctn hbox vcenter" key={`goal-card-${shortTermGoalId}`}>
						<div
							className="status"
							onClick={() => {
								if (goal.status === 'completed') {
									this.updateGoalStatus(goal.shortTermGoalId, false);
								} else {
									//Mark completed
									this.updateGoalStatus(goal.shortTermGoalId, true);
								}
							}}
						>
							{status !== 'completed' ? (
								<div className="empty-checkbox"></div>
							) : (
								<div className="checkbox-checked">
									<i className="bx bx-check"></i>
								</div>
							)}
						</div>
						<div
							className="goal-card"
							onClick={(e) => {
								this.onGoalClicked(e, goal);
							}}
						>
							<div className="date vbox hcenter">
								<div className="day">{format(new Date(deadline.deadlineDate), 'E')}</div>
								<div className="daynum">{format(new Date(deadline.deadlineDate), 'd')}</div>
								<div className="month">{format(new Date(deadline.deadlineDate), 'MMM')}</div>
							</div>
							<div className="goal-body grow-1">
								<div className={`goal-type ${deadline.repeatable === false ? 'onetime' : 'repeatable'}`}>
									{!deadline.repeatable
										? 'ONE TIME'
										: deadline.repeat.frequency == 'day'
										? 'DAILY'
										: deadline.repeat.frequency == 'week'
										? 'WEEKLY'
										: 'MONTHLY'}
								</div>
								<div className="text-body grow-1">{summary.length > 65 ? `${summary.substring(0, 65)}...` : summary}</div>
								{dueMessage && <div className={`due-message ${overdue ? 'red' : ''}`}>{dueMessage}</div>}
							</div>
						</div>
					</div>
				);
			}
		});
		return (
			<div className="goals-list">
				{goalListItems.length > 0 ? goalListItems : <div className="empty-message">No goals match this filter.</div>}
			</div>
		);
	}

	onGoalClicked(event, goal) {
		if (
			event.target.classList.contains('status') ||
			event.target.classList.contains('empty-checkbox') ||
			event.target.classList.contains('checkbox-checked') ||
			event.target.classList.contains('bx-check')
		) {
			return;
		}
		this.data.activeGoal = goal;
		this.data.goalDetailsOpen = true;
	}

	render() {
		if (!appStore.isLoggedIn) {
			return <Splash></Splash>;
		}
		let { loading } = this.data;
		const imageHeight = this.imgRef?.current?.clientHeight || 300;
		return (
			<Page name="bvr-goals" className={`bvr-goals-page-cmp ${!appStore.isMobile ? 'desktop' : 'mobile'}`}>
				<div className={`background`}></div>
				<div className="content-ctn y-scroll">
					<div className="img-ctn vbox hcenter" ref={this.imgRef}>
						<img src={backgroundImage} className="bg-image" alt="background waves image" />
					</div>
					<h1 className="text-gradiant" style={{ marginTop: -imageHeight / 3 }}>
						Your Short-Term Goals
					</h1>
					{this.Filters()}
					{!loading && this.GoalList()}
					{loading && (
						<div className="hbox vcenter hcenter">
							<Preloader size={32} color="blue"></Preloader>
						</div>
					)}
				</div>
				<GoalCapturePopup
					opened={this.data.goalCaptureOpen}
					onEdit={(goal) => {
						this.data.activeGoal = null;
						this.data.goalDetailsOpen = false;
						this.data.goalToEdit = goal;
						this.data.goalEditOpen = true;
					}}
					onPopupClosed={() => {
						this.data.goalCaptureOpen = false;
						this.loadGoals();
					}}
				></GoalCapturePopup>
				<BVRNav onDataChanged={this.loadGoals}></BVRNav>
				<GoalEditPopup
					opened={this.data.goalEditOpen}
					goal={this.data.goalToEdit}
					onPopupClosed={async () => {
						this.data.goalEditOpen = false;
						this.data.goalToEdit = null;
						await this.loadGoals();
						if (this.data.activeGoal) {
							let updatedGoal = _.cloneDeep(_.find(this.data.goalList, { shortTermGoalId: this.data.activeGoal.shortTermGoalId }));
							if (!updatedGoal) {
								this.data.activeGoal = null;
								this.data.goalDetailsOpen = false;
							} else {
								this.data.activeGoal = updatedGoal;
							}
						}
					}}
				></GoalEditPopup>
				<GoalDetailsPopup
					opened={this.data.goalDetailsOpen}
					goal={this.data.activeGoal}
					onEdit={(goal) => {
						this.data.goalToEdit = goal;
						this.data.goalEditOpen = true;
					}}
					onDoItAgain={(goal) => {
						this.data.activeGoal = null;
						this.data.goalDetailsOpen = false;
						delete goal.shortTermGoalId;
						this.data.goalToEdit = goal;
						this.data.goalEditOpen = true;
					}}
					onPopupClosed={() => {
						this.data.activeGoal = null;
						this.data.goalDetailsOpen = false;
						this.loadGoals();
					}}
				/>
			</Page>
		);
	}
}

export default observer(BVRGoals);
