import * as React from 'react';

const ThoughtsSVG = (props) => {
	return (
		<svg
			width="16"
			height="24"
			viewBox="0 0 16 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			aria-label="Thoughts Icon"
			{...props}
		>
			<g clipPath="url(#clip0_1576_6172)">
				<path
					className="path"
					d="M12.1153 20.1204H4.28928C4.09169 20.1221 3.9003 20.0535 3.75122 19.9276C3.60214 19.8018 3.50566 19.6273 3.47998 19.4372L1.86136 7.26442C1.84631 7.15305 1.85605 7.03985 1.88993 6.93245C1.9238 6.82505 1.98103 6.72595 2.05773 6.64184C2.13444 6.55772 2.22885 6.49056 2.33459 6.44487C2.44032 6.39918 2.55493 6.37603 2.67067 6.37698H13.7541C13.8699 6.37603 13.9845 6.39918 14.0902 6.44487C14.196 6.49056 14.2904 6.55772 14.3671 6.64184C14.4438 6.72595 14.501 6.82505 14.5349 6.93245C14.5687 7.03985 14.5785 7.15305 14.5634 7.26442L12.9448 19.4372C12.9188 19.6308 12.8194 19.808 12.666 19.9343C12.5127 20.0606 12.3163 20.127 12.1153 20.1204V20.1204Z"
					strokeWidth="1.5"
				/>
				<path
					className="path"
					d="M2.56552 3.19638L0.752674 4.88487C0.636462 4.99356 0.556398 5.13349 0.522768 5.28668C0.489137 5.43987 0.503478 5.59931 0.563947 5.74453C0.624416 5.88975 0.728251 6.01411 0.86211 6.10162C0.995969 6.18914 1.15374 6.23581 1.31514 6.23565H14.6889C14.8442 6.23388 14.9957 6.1888 15.1252 6.10579C15.2548 6.02279 15.3571 5.90536 15.4198 5.76752C15.4825 5.62968 15.503 5.47724 15.4789 5.3284C15.4548 5.17955 15.387 5.04058 15.2838 4.92806L13.7299 3.23565C13.6538 3.1538 13.5608 3.08843 13.4569 3.04378C13.353 2.99912 13.2406 2.9762 13.127 2.97649H3.14418C3.03755 2.97434 2.93154 2.99265 2.83223 3.03039C2.73293 3.06813 2.64229 3.12454 2.56552 3.19638Z"
					strokeWidth="1.5"
				/>
				<path
					className="path"
					d="M2.56552 20.4739L0.752674 22.1623C0.636462 22.271 0.556398 22.411 0.522768 22.5641C0.489137 22.7173 0.503478 22.8768 0.563947 23.022C0.624416 23.1672 0.728251 23.2916 0.86211 23.3791C0.995969 23.4666 1.15374 23.5133 1.31514 23.5131H14.6889C14.8442 23.5113 14.9957 23.4663 15.1252 23.3833C15.2548 23.3003 15.3571 23.1828 15.4198 23.045C15.4825 22.9071 15.503 22.7547 15.4789 22.6059C15.4548 22.457 15.387 22.318 15.2838 22.2055L13.7299 20.5131C13.6538 20.4313 13.5608 20.3659 13.4569 20.3212C13.353 20.2766 13.2406 20.2537 13.127 20.254H3.14418C3.03755 20.2518 2.93154 20.2701 2.83223 20.3079C2.73293 20.3456 2.64229 20.402 2.56552 20.4739Z"
					strokeWidth="1.5"
				/>
				<path
					className="path"
					d="M6.04549 0.856051L5.38996 2.07333C5.18763 2.44636 5.58014 2.85867 6.14666 2.85867H10.1204C10.6707 2.85867 11.0632 2.466 10.8892 2.11259L10.3227 0.895318C10.2463 0.765708 10.1333 0.659927 9.99717 0.590378C9.86099 0.52083 9.70721 0.490397 9.55384 0.502648H6.80624C6.65866 0.491807 6.51073 0.51874 6.37723 0.580756C6.24373 0.642772 6.12934 0.737699 6.04549 0.856051Z"
					strokeWidth="1.5"
				/>
				<path
					className="path"
					d="M8.96717 11.3442C8.96455 11.2997 8.94996 11.2566 8.92485 11.2193C8.89975 11.1819 8.865 11.1516 8.82407 11.1314C8.78314 11.1111 8.73745 11.1016 8.69156 11.1038C8.64567 11.106 8.60118 11.1199 8.56251 11.1439C7.80176 11.7172 5.76636 13.0798 5.76636 15.212C5.76636 18.1217 8.08502 17.8076 8.72842 17.8076C9.37182 17.8076 10.6991 17.2539 10.428 15.212C10.2742 13.9829 9.61866 13.6688 9.30707 13.0445C9.06292 12.5099 8.94671 11.9286 8.96717 11.3442V11.3442Z"
					strokeWidth="1.5"
				/>
			</g>
		</svg>
	);
};
export default ThoughtsSVG;
