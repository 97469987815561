import React from 'react';
import { observer, ReactiveComponent, Store } from '@/utils/State';
import appStore, { getUserProductConfig } from '@/stores/AppStore';
import { f7, Sheet, Toolbar } from 'framework7-react';
import { autoBind } from '@/utils/GeneralUtils';
import headsetPNG from '@/assets/userheadset.png';
import HomeSVG from '@/assets/homesvg';
import GoalSVG from '@/assets/goalssvg';
import ThoughtsSVG from '@/assets/lanternsvg';
import ProgressSVG from '@/assets/progresssvg';
import torchSVG from '@/assets/torch1.svg';
import goalSVG from '@/assets/goals.svg';

import './bvr-nav.scss';
import ThoughtCapturePopup from '../thought-capture-popup/ThoughtCapturePopup';
import GoalCapturePopup from '../goal-capture-popup/GoalCapturePopup';
import GoalEditPopup from '../goal-edit-popup/GoalEditPopup';
import GoalDetailsPopup from '../goal-details-popup/GoalDetailsPopup';
import APIService from '@/services/APIService';
const SVGIcon = (props) => {
	return props.svg;
};

class BVRNav extends ReactiveComponent {
	constructor(props) {
		super(props);
		this.setData({
			createSheetOpen: false,
			thoughtCaptureOpen: false,
			activeGoal: null,
			goalToEdit: null,
			goalDetailsOpen: false,
			goalCaptureOpen: false,
			goalEditOpen: false
		});
		autoBind(this);
	}

	handleSheetOpen() {}

	onCreateThought() {
		this.data.thoughtCaptureOpen = true;
		this.data.createSheetOpen = false;
	}

	onCreateGoal() {
		this.data.goalCaptureOpen = true;
		this.data.createSheetOpen = false;
	}

	async silentUpdateuserCall() {
		try {
			const user = await APIService.getMyDetails();
			appStore.user = user;
		} catch (err) {
			console.log(err);
		}
	}

	SheetContent() {
		let { thoughts, goals, progress } = getUserProductConfig('first-resort');
		let items = [
			<div className="create-header hbox vcenter" key="create-sheet-header">
				<div className="grow-1">{(thoughts || goals) && 'Create'}</div>
				<div
					className="close-btn"
					onClick={() => {
						this.data.createSheetOpen = false;
					}}
				>
					<i className="bx bx-x"></i>
				</div>
			</div>
		];

		if (!thoughts && !goals && !progress) {
			items.push(
				<div className="locked-message vbox hcenter vcenter" key="create-sheet-coming-soon">
					<h1>Coming Soon!</h1>
					<p>Stay on track with your VR practice to unlock this feature.</p>
				</div>
			);
		}

		if (thoughts) {
			items.push(
				<div className="sheet-action hbox vcenter" onClick={this.onCreateThought} key="create-sheet-add-thought">
					<div className="icon vbox vcenter hcenter">
						<img src={torchSVG} alt="Thought Icon" />
					</div>
					Add a New Thought
				</div>
			);
		}
		if (goals) {
			items.push(
				<div className="sheet-action hbox vcenter" onClick={this.onCreateGoal} key="create-sheet-create-goal">
					<div className="icon vbox vcenter hcenter">
						<img src={goalSVG} alt="Goal Icon" />
					</div>
					Add a New SMART Goal
				</div>
			);
		}
		return items;
	}

	render() {
		let { thoughts, goals, progress } = getUserProductConfig('first-resort');
		let { bvrActiveTab } = appStore;
		return (
			<Toolbar tabbar bottom className="bvr-nav-toolbar">
				<div className={`active-tab-bar ${bvrActiveTab}`}></div>
				<div
					className={`action grow-1 vbox hcenter ${bvrActiveTab === 'home' ? 'active' : ''}`}
					onClick={() => {
						this.silentUpdateuserCall();
						appStore.bvrActiveTab = 'home';
						setTimeout(() => {
							f7.view.main.router.navigate('/bvr-landing');
						}, 200);
					}}
				>
					<HomeSVG></HomeSVG>
					<div className="text">Home</div>
				</div>
				<div
					className={`action grow-1 vbox hcenter ${bvrActiveTab === 'progress' ? 'active' : ''}`}
					onClick={() => {
						this.silentUpdateuserCall();
						appStore.bvrActiveTab = 'progress';
						setTimeout(() => {
							f7.view.main.router.navigate('/bvr-progress');
						}, 200);
					}}
				>
					<ProgressSVG></ProgressSVG>
					<div className="text">Progress</div>
				</div>
				<div
					className="action grow-1 vbox hcenter"
					onClick={() => {
						this.silentUpdateuserCall();
						this.data.createSheetOpen = true;
					}}
				>
					<div className="nav-add-btn">+</div>
				</div>
				<div
					className={`action grow-1 vbox hcenter ${bvrActiveTab === 'goals' ? 'active' : ''}`}
					onClick={() => {
						appStore.bvrActiveTab = 'goals';
						this.silentUpdateuserCall();
						setTimeout(() => {
							f7.view.main.router.navigate('/bvr-goals');
						}, 200);
					}}
				>
					<GoalSVG></GoalSVG>
					<div className="text">Goals</div>
				</div>
				<div
					className={`action grow-1 vbox hcenter ${bvrActiveTab === 'thoughts' ? 'active' : ''}`}
					onClick={() => {
						appStore.bvrActiveTab = 'thoughts';
						this.silentUpdateuserCall();
						setTimeout(() => {
							f7.view.main.router.navigate('/bvr-thoughts');
						}, 200);
					}}
				>
					<ThoughtsSVG></ThoughtsSVG>
					<div className="text">Thoughts</div>
				</div>
				<Sheet
					className={`bvr-creation-sheet-cmp ${!appStore.isMobile ? 'desktop' : 'mobile'}`}
					position={!appStore.isMobile ? 'top' : 'bottom'}
					opened={this.data.createSheetOpen}
					closeByBackdropClick={false}
					closeOnEscape={false}
					onSheetClose={this.handleSheetClose}
					onSheetOpen={this.handleSheetOpen}
					fullscreen
					backdrop
					push
				>
					<div className="create-sheet-content">{this.SheetContent()}</div>
				</Sheet>
				<ThoughtCapturePopup
					opened={this.data.thoughtCaptureOpen}
					onPopupClosed={() => {
						this.data.thoughtCaptureOpen = false;
						if (this.props.onDataChanged) {
							this.props.onDataChanged();
						}
					}}
				></ThoughtCapturePopup>
				<GoalCapturePopup
					opened={this.data.goalCaptureOpen}
					onEdit={(goal) => {
						this.data.activeGoal = null;
						this.data.goalDetailsOpen = false;
						this.data.goalToEdit = goal;
						this.data.goalEditOpen = true;
					}}
					onPopupClosed={() => {
						this.data.goalCaptureOpen = false;
						if (this.props.onDataChanged) {
							this.props.onDataChanged();
						}
					}}
				></GoalCapturePopup>
				<GoalEditPopup
					opened={this.data.goalEditOpen}
					goal={this.data.goalToEdit}
					onPopupClosed={async () => {
						this.data.goalEditOpen = false;
						this.data.goalToEdit = null;
						this.data.activeGoal = null;
						var toast = f7.toast.create({
							text: 'Success!',
							position: 'center',
							closeTimeout: 2000
						});
						toast.open();
					}}
				></GoalEditPopup>
			</Toolbar>
		);
	}
}
export default observer(BVRNav);
