import React from 'react';
import appStore from '@/stores/AppStore';
import { f7, Button, Link, Popup, Toggle, Input, Preloader } from 'framework7-react';
import { ReactiveComponent, observer } from '@/utils/State';
import Slider from 'rc-slider';
import { autoBind, showError } from '@/utils/GeneralUtils';
import BasicInput from '../basic-input/BasicInput';
import waveBG from '@/assets/wavebg.png';
import checkSVG from '@/assets/check.svg';
import APIService from '@/services/APIService';
import { format } from 'date-fns';
import _, { flow } from 'lodash';
import LongTermGoals from '@/assets/LongTermGoals';
import './goal-capture-popup.scss';

class GoalCapturePopup extends ReactiveComponent {
	constructor(props) {
		super(props);
		this.setData({
			currentStep: 0,
			advancedEnabled: false,
			advancedCheck: { yes1: false, yes2: false },
			goal: this.initialGoal(),
			longTermGoals: [],
			flowComplete: false,
			savedGoal: null,
			activeCategoryInfo: null,
			tempThoughtMap: {},
			loading: true,
			activeTempThought: null,
			flowType: null,
			helpOverlay: null,
			sampleGoalOpen: false,
			activeExample: 1
		});
		autoBind(this);
	}

	onPopupOpened() {
		this.data.advancedEnabled = false;
		this.data.advancedCheck = { yes1: false, yes2: false };
		APIService.getLongTermGoals()
			.then((res) => {
				this.data.longTermGoals = res;
				this.data.loading = false;
			})
			.catch((err) => {
				showError(err);
			});
		APIService.getGoals()
			.then((res) => {
				if (res.length >= 3) {
					this.data.advancedEnabled = true;
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}

	initialGoal(clear) {
		if (!clear) {
			let { initialGoal } = this.props;
			if (initialGoal) {
				return _.cloneDeep(initialGoal);
			}
		}
		return {
			longTermParentId: null,
			summary: '',
			body: '',
			realLifeEvent: false,
			measurements: [
				{
					amount: 1,
					unit: ''
				}
			],
			deadline: {
				timing: 'today',
				deadlineDate: new Date(),
				repeatable: false,
				repeat: {
					frequency: 'day',
					hasStart: false,
					hasEnd: false,
					startDate: new Date(),
					endDate: new Date(),
					cadence: { sunday: false, monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false }
				}
			},
			reminder: {
				enabled: false,
				bestTime: null
			}
		};
	}

	onSaveGoal() {
		f7.dialog.preloader('Saving...');
		APIService.saveGoal(_.cloneDeep(this.data.goal))
			.then((res) => {
				this.data.flowComplete = true;
				this.data.savedGoal = res;
				f7.dialog.close();
			})
			.catch((err) => {
				showError(err);
			});
	}

	onInputChange(evt) {
		let target = evt.currentTarget;
		_.set(this.data, `goal.${target.name}`, target.value);
	}

	SMARTGoalScreen() {
		return (
			<>
				<div className="title">Build your goal with the SMART Method</div>
				<p>
					SMART goal(s) will allow you to create achievable goal(s). We suggest that you use the “Step by Step Flow” if you are a new
					learner.
				</p>
				<div className="smart-abbrv">
					<div className="hbox vcenter">
						<div className="letter">S</div>
						<div className="term">Specific</div>
					</div>
					<div className="hbox vcenter">
						<div className="letter">M</div>
						<div className="term">Measurable</div>
					</div>
					<div className="hbox vcenter">
						<div className="letter">A</div>
						<div className="term">Attainable</div>
					</div>
					<div className="hbox vcenter">
						<div className="letter">R</div>
						<div className="term">Relevant</div>
					</div>
					<div className="hbox vcenter">
						<div className="letter">T</div>
						<div className="term">Time-Bound</div>
					</div>
				</div>
				{this.data.advancedEnabled ? (
					<div className="vbox vcenter hcenter btn-ctn">
						<Button
							className="step-btn vbox vcenter hcenter"
							onClick={() => {
								this.data.currentStep = 0;
								this.data.flowType = 'stepByStep';
							}}
						>
							Use Step-by-Step Flow
						</Button>
						<Button
							className="step-btn vbox vcenter hcenter"
							onClick={() => {
								this.data.currentStep = 0;
								this.data.flowType = 'advanced';
							}}
						>
							Use Advanced Flow
						</Button>
						<Button
							className="step-btn vbox vcenter hcenter"
							onClick={() => {
								this.data.goal.longTermParentId = null;
							}}
						>
							Back
						</Button>
						<div className="grow-1"></div>
					</div>
				) : (
					<div className="hbox vcenter hcenter">
						<Button
							className="step-btn vbox vcenter hcenter"
							onClick={() => {
								this.data.goal.longTermParentId = null;
							}}
						>
							Back
						</Button>
						<div className="grow-1"></div>
						<Button
							className="step-btn vbox vcenter hcenter"
							onClick={() => {
								this.data.currentStep = 0;
								this.data.flowType = 'stepByStep';
							}}
						>
							Next
						</Button>
					</div>
				)}
			</>
		);
	}

	GoalSummary() {
		return (
			<>
				<BasicInput
					key={`app-goal-input-summary`}
					validate
					name="summary"
					type="textarea"
					placeholder="Quick summary or goal title"
					label="Short-term goal summary"
					value={this.data.goal.summary}
					onChange={this.onInputChange}
					className="goal-summary-input"
				/>
				{/* <div className="toggle-ctn hbox vcenter">
					<div className="toggle-text grow-1">Mark as a "Real-Life Event"</div>
					<Toggle
						className="md"
						value={this.data.goal.realLifeEvent ? true : false}
						onChange={(e) => {
							this.data.goal.realLifeEvent = e.target.checked;
						}}
					></Toggle>
				</div> */}
			</>
		);
	}

	GoalDetails() {
		return (
			<>
				<div
					className="info-icon-"
					onClick={() => {
						this.data.helpOverlay = 'specific';
					}}
				>
					i
				</div>
				<BasicInput
					key={`app-goal-input-body`}
					validate
					name="body"
					type="textarea"
					placeholder="Detail of goal goes here"
					label="Specify Goal"
					value={this.data.goal.body}
					onChange={this.onInputChange}
					className="goal-input"
				/>
			</>
		);
	}

	GoalMeasurement() {
		return (
			<>
				<div className="info-label hbox vcenter">
					<h3>Set Measurable Target</h3>
					<div
						className="info-icon"
						onClick={() => {
							this.data.helpOverlay = 'measure';
						}}
					>
						i
					</div>
				</div>
				{this.data.goal.measurements.map((measurement, i) => {
					return (
						<div className="pair-input hbox vcenter" key={`app-goal-measurement-${i}`}>
							<BasicInput
								validate
								name={`measurements[${i}].amount`}
								type="number"
								placeholder="e.g. 15"
								label={i === 0 ? 'Amount' : null}
								value={this.data.goal.measurements[i].amount}
								onChange={this.onInputChange}
								className="goal-amount-input"
							/>
							<BasicInput
								validate
								name={`measurements[${i}].unit`}
								type="text"
								placeholder="e.g. minutes"
								label={i === 0 ? 'Unit' : null}
								value={this.data.goal.measurements[i].unit}
								onChange={this.onInputChange}
								className="goal-unit-input"
							/>
							{i === 0 ? (
								<div className="trash-placeholder"></div>
							) : (
								<i
									className="bx bx-trash"
									onClick={() => {
										this.data.goal.measurements.splice(i, 1);
									}}
								></i>
							)}
						</div>
					);
				})}
				<div
					className="add-btn hbox vcenter"
					onClick={() => {
						this.data.goal.measurements.push({ amount: 0, unit: '' });
					}}
				>
					<div className="plus-icon">
						<i className="bx bx-plus"></i>
					</div>
					Add more measurements
				</div>
			</>
		);
	}

	GoalDeadline() {
		let {
			deadline: {
				timing,
				deadlineDate,
				repeatable,
				repeat: { frequency, hasStart, hasEnd, startDate, endDate, cadence }
			}
		} = this.data.goal;

		return (
			<>
				<div className="toggle-ctn repeatable hbox vcenter">
					<div className="toggle-text grow-1">Make this goal repeatable</div>
					<Toggle
						className="md"
						value={repeatable ? true : false}
						onChange={(e) => {
							this.data.goal.deadline.repeatable = e.target.checked;
							if (!e.target.checked) {
								//reset repeatable items
								this.data.goal.deadline.repeat = {
									frequency: 'day',
									hasStart: false,
									hasEnd: false,
									startDate: new Date(),
									endDate: new Date(),
									cadence: {
										sunday: false,
										monday: false,
										tuesday: false,
										wednesday: false,
										thursday: false,
										friday: false,
										saturday: false
									}
								};
							} else {
								//reset deadline settings
								this.data.goal.deadline.timing = 'today';
								this.data.goal.deadline.deadlineDate = new Date();
							}
						}}
					></Toggle>
				</div>
				{repeatable && (
					<>
						<div className="message">How Often?</div>
						<div className="date-card">
							<div
								className={`selection hbox vcenter ${frequency === 'day' ? 'checked' : ''}`}
								onClick={() => {
									this.data.goal.deadline.repeat.frequency = 'day';
								}}
							>
								<div className="day grow-1">Every Day</div>
								<div className="checkbox-ctn">{frequency === 'day' && <i className="bx bx-check"></i>}</div>
							</div>
							<div
								className={`selection ${frequency === 'week' ? 'checked' : ''}`}
								onClick={() => {
									this.data.goal.deadline.repeat.frequency = 'week';
								}}
							>
								<div className="hbox vcenter grow-1">
									<div className="day grow-1">Every Week</div>
									<div className="checkbox-ctn">{frequency === 'week' && <i className="bx bx-check"></i>}</div>
								</div>
								{frequency === 'week' && (
									<div className="cadence-picker hbox vcenter">
										{Object.keys(cadence).map((dayOfWeek) => {
											return (
												<div
													key={`cadence-picker-${dayOfWeek}`}
													className={`day-icon hbox vcenter hcenter ${cadence[dayOfWeek] === true ? 'selected' : ''}`}
													onClick={() => {
														this.data.goal.deadline.repeat.cadence[dayOfWeek] = !this.data.goal.deadline.repeat.cadence[dayOfWeek];
													}}
												>
													{dayOfWeek[0]}
												</div>
											);
										})}
									</div>
								)}
							</div>
							<div
								className={`selection hbox vcenter ${frequency === 'month' ? 'checked' : ''}`}
								onClick={() => {
									this.data.goal.deadline.repeat.frequency = 'month';
								}}
							>
								<div className="day grow-1">Every Month</div>
								<div className="checkbox-ctn">{frequency === 'month' && <i className="bx bx-check"></i>}</div>
							</div>
						</div>
						{frequency === 'month' && (
							<div className="start-date-ctn hbox vcenter">
								<div
									className="hbox vcenter grow-1"
									onClick={() => {
										let cal = f7.calendar.create({
											destroyOnClose: true,
											minDate: new Date(),
											openIn: 'customModal',
											header: true,
											footer: true,
											dateFormat: 'M dd',
											backdrop: true,
											value: new Date(),
											on: {
												closed: (e) => {
													this.data.goal.deadline.repeat.hasStart = true;
													this.data.goal.deadline.repeat.startDate = new Date(e.value[0] || new Date());
												}
											}
										});
										cal.open();
									}}
								>
									<div className="cal-icon">
										<i className="bx bx-calendar-alt"></i>
									</div>
									<div className="message">Add Start Date</div>
								</div>
								{hasStart && <div className="date">{format(new Date(startDate), 'MM/dd/yy')}</div>}
								{hasStart && (
									<i
										className="bx bx-trash"
										onClick={() => {
											this.data.goal.deadline.repeat.hasStart = false;
										}}
									></i>
								)}
							</div>
						)}
						<div className="end-date-ctn hbox vcenter">
							<div
								className="hbox vcenter grow-1"
								onClick={() => {
									let cal = f7.calendar.create({
										destroyOnClose: true,
										minDate: new Date(),
										openIn: 'customModal',
										header: true,
										footer: true,
										dateFormat: 'M dd',
										backdrop: true,
										value: new Date(),
										on: {
											closed: (e) => {
												this.data.goal.deadline.repeat.hasEnd = true;
												this.data.goal.deadline.repeat.endDate = new Date(e.value[0] || new Date());
											}
										}
									});
									cal.open();
								}}
							>
								<div className="cal-icon">
									<i className="bx bx-calendar-alt"></i>
								</div>
								<div className="message">Add end date (optional)</div>
							</div>
							{hasEnd && <div className="date">{format(new Date(endDate), 'MM/dd/yy')}</div>}
							{hasEnd && (
								<i
									className="bx bx-trash"
									onClick={() => {
										this.data.goal.deadline.repeat.hasEnd = false;
									}}
								></i>
							)}
						</div>
					</>
				)}

				{!repeatable && <div className="message">Choose a date</div>}
				{!repeatable && (
					<div className="date-card">
						<div
							className={`selection hbox vcenter ${timing === 'today' ? 'checked' : ''}`}
							onClick={() => {
								this.data.goal.deadline.timing = 'today';
								this.data.goal.deadline.deadlineDate = new Date();
							}}
						>
							<div className="day">Today</div>
							<div className="date grow-1">{format(new Date(), 'MM/dd/yyyy')}</div>
							<div className="checkbox-ctn">{timing === 'today' && <i className="bx bx-check"></i>}</div>
						</div>
						<div
							className={`selection hbox vcenter ${timing === 'tomorrow' ? 'checked' : ''}`}
							onClick={() => {
								this.data.goal.deadline.timing = 'tomorrow';
								this.data.goal.deadline.deadlineDate = new Date(new Date().getTime() + 1000 * 60 * 60 * 24);
							}}
						>
							<div className="day">Tomorrow</div>
							<div className="date grow-1">{format(new Date(new Date().getTime() + 1000 * 60 * 60 * 24), 'MM/dd/yyyy')}</div>
							<div className="checkbox-ctn">{timing === 'tomorrow' && <i className="bx bx-check"></i>}</div>
						</div>
						<div
							className={`selection hbox vcenter ${timing === 'nextweek' ? 'checked' : ''}`}
							onClick={() => {
								this.data.goal.deadline.timing = 'nextweek';
								this.data.goal.deadline.deadlineDate = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7);
							}}
						>
							<div className="day">Next Week</div>
							<div className="date grow-1">{format(new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7), 'MM/dd/yyyy')}</div>
							<div className="checkbox-ctn">{timing === 'nextweek' && <i className="bx bx-check"></i>}</div>
						</div>
						<div
							className={`selection hbox vcenter ${timing === 'custom' ? 'checked' : ''}`}
							onClick={() => {
								this.data.goal.deadline.timing = 'custom';
								let cal = f7.calendar.create({
									destroyOnClose: true,
									minDate: new Date(),
									openIn: 'customModal',
									header: true,
									footer: true,
									dateFormat: 'M dd',
									backdrop: true,
									value: new Date(),
									on: {
										closed: (e) => {
											this.data.goal.deadline.deadlineDate = new Date(e.value[0] || new Date());
										}
									}
								});
								cal.open();
							}}
						>
							<div className="day">Custom Date</div>
							<div className="date grow-1">{timing === 'custom' && format(new Date(deadlineDate), 'MM/dd/yyyy')}</div>
							<div className="checkbox-ctn">{timing === 'custom' && <i className="bx bx-check"></i>}</div>
						</div>
					</div>
				)}
			</>
		);
	}

	SpecifyGoalSheet() {
		const isDisabled = () => {
			let { body, summary } = this.data.goal;
			if (body.length < 5 || summary.length < 5) {
				return true;
			}
			return false;
		};
		return (
			<>
				<div className="title">Specify goal</div>
				{this.GoalSummary()}
				{this.GoalDetails()}
				<div className="hbox vcenter hcenter">
					<Button
						className="step-btn vbox vcenter hcenter"
						onClick={() => {
							this.data.flowType = null;
						}}
					>
						Back
					</Button>
					<div className="grow-1"></div>
					<Button
						className="step-btn vbox vcenter hcenter"
						disabled={isDisabled()}
						onClick={() => {
							this.data.currentStep++;
						}}
					>
						Next
					</Button>
				</div>
			</>
		);
	}

	MeasureGoalSheet() {
		const isDisabled = () => {
			let { measurements } = this.data.goal;
			let valid = true;
			measurements.forEach((m) => {
				if (m.amount <= 0 || m.unit.length < 1) {
					valid = false;
				}
			});
			return !valid;
		};
		return (
			<>
				<div className="title">Measure your goal</div>
				{this.GoalMeasurement()}
				<div className="hbox vcenter hcenter">
					<Button
						className="step-btn vbox vcenter hcenter"
						onClick={() => {
							this.data.currentStep--;
						}}
					>
						Back
					</Button>
					<div className="grow-1"></div>
					<Button
						className="step-btn vbox vcenter hcenter"
						disabled={isDisabled()}
						onClick={() => {
							this.data.currentStep++;
						}}
					>
						Next
					</Button>
				</div>
			</>
		);
	}

	ConfidencePrompt() {
		return (
			<>
				<div className="title">Do you feel confident that you will do it?</div>
				<div
					className="card-btn"
					onClick={() => {
						this.data.currentStep++;
					}}
				>
					Yes
				</div>
				<div
					className="card-btn"
					onClick={() => {
						const prompt = f7.dialog.create({
							text: `We suggest that your  Short-term goal should be <strong>attainable</strong> to you, please review your initial goal so you'll be confident achieving it.`,
							destroyOnClose: true,
							buttons: [
								{
									text: 'Review Goal',
									onClick: () => {
										this.data.currentStep = 0;
									}
								}
							]
						});
						prompt.open();
					}}
				>
					No
				</div>
				<div className="info-label hbox vcenter">
					<h3>Attainable Goal</h3>
					<div
						className="info-icon"
						onClick={() => {
							this.data.helpOverlay = 'attainable';
						}}
					>
						i
					</div>
				</div>
				<div className="hbox vcenter hcenter">
					<Button
						className="step-btn vbox vcenter hcenter"
						onClick={() => {
							this.data.currentStep--;
						}}
					>
						Back
					</Button>
					<div className="grow-1"></div>
				</div>
			</>
		);
	}

	MeaningPrompt() {
		return (
			<>
				<div className="title">Is this goal meaningful to you?</div>
				<div
					className="card-btn"
					onClick={() => {
						this.data.currentStep++;
					}}
				>
					Yes
				</div>
				<div
					className="card-btn"
					onClick={() => {
						const prompt = f7.dialog.create({
							text: `We suggest that your Short-term goal should be <strong>relevant</strong> to you, please review your initial goal and adjust it to be meaningful to you.`,
							destroyOnClose: true,
							buttons: [
								{
									text: 'Review Goal',
									onClick: () => {
										this.data.currentStep = 0;
									}
								}
							]
						});
						prompt.open();
					}}
				>
					No
				</div>
				<div className="info-label hbox vcenter">
					<h3>Relevant Goal</h3>
					<div
						className="info-icon"
						onClick={() => {
							this.data.helpOverlay = 'relevant';
						}}
					>
						i
					</div>
				</div>
				<div className="hbox vcenter hcenter">
					<Button
						className="step-btn vbox vcenter hcenter"
						onClick={() => {
							this.data.currentStep--;
						}}
					>
						Back
					</Button>
					<div className="grow-1"></div>
				</div>
			</>
		);
	}

	DeadlineSheet() {
		const isDisabled = () => {
			let frequency = _.get(this.data.goal, 'deadline.repeat.frequency', null);
			if (frequency === 'month' && !_.get(this.data.goal, 'deadline.repeat.hasStart')) {
				return true;
			}
			if (frequency === 'week') {
				let hasSelected = false;
				let cadence = _.get(this.data.goal, 'deadline.repeat.cadence', null);
				Object.keys(cadence).forEach((day) => {
					if (cadence[day] === true) {
						hasSelected = true;
					}
				});
				if (!hasSelected) {
					return true;
				}
			}
			return false;
		};

		return (
			<>
				<div className="title">Set your deadline</div>
				{this.GoalDeadline()}
				<div className="hbox vcenter hcenter">
					<Button
						className="step-btn vbox vcenter hcenter"
						onClick={() => {
							this.data.currentStep--;
						}}
					>
						Back
					</Button>
					<div className="grow-1"></div>
					<Button
						className="step-btn vbox vcenter hcenter"
						disabled={isDisabled()}
						onClick={() => {
							this.data.currentStep++;
						}}
					>
						Next
					</Button>
				</div>
			</>
		);
	}

	ReminderPromptSheet() {
		let { reminder } = this.data.goal;
		return (
			<>
				<div className="title">Would you like to set an SMS reminder for this goal?</div>
				<div
					className="card-btn"
					onClick={() => {
						this.data.goal.reminder.enabled = true;
						this.data.currentStep++;
					}}
				>
					Yes
				</div>
				<div className="card-btn" onClick={this.onSaveGoal}>
					Skip & Submit
				</div>
				<div className="hbox vcenter hcenter">
					<Button
						className="step-btn vbox vcenter hcenter"
						onClick={() => {
							this.data.currentStep--;
						}}
					>
						Back
					</Button>
					<div className="grow-1"></div>
				</div>
			</>
		);
	}

	GoalReminder() {
		let {
			deadline: { timing },
			reminder: { bestTime }
		} = this.data.goal;
		return (
			<>
				<div className="message small">Pick best time to receive reminder text</div>
				<div className="date-card">
					<div
						className={`selection hbox vcenter ${bestTime === 'dayOf' ? 'checked' : ''}`}
						onClick={() => {
							if (bestTime !== 'dayOf') {
								this.data.goal.reminder.bestTime = 'dayOf';
							} else if (bestTime === 'dayOf') {
								this.data.goal.reminder.bestTime = null;
							}
						}}
					>
						<div className="day grow-1">The day of</div>
						<div className="checkbox-ctn">{bestTime === 'dayOf' && <i className="bx bx-check"></i>}</div>
					</div>
					{timing !== 'today' && (
						<div
							className={`selection hbox vcenter ${bestTime === 'dayBefore' ? 'checked' : ''}`}
							onClick={() => {
								if (bestTime !== 'dayBefore') {
									this.data.goal.reminder.bestTime = 'dayBefore';
								} else if (bestTime === 'dayBefore') {
									this.data.goal.reminder.bestTime = null;
								}
							}}
						>
							<div className="day grow-1">A day before</div>
							<div className="checkbox-ctn">{bestTime === 'dayBefore' && <i className="bx bx-check"></i>}</div>
						</div>
					)}
				</div>
			</>
		);
	}

	SMSReminderSheet() {
		return (
			<>
				<div className="title">Set an SMS Reminder (Optional)</div>
				{this.GoalReminder()}
				<div className="divider-line"></div>
				<div className="hbox vcenter hcenter">
					<Button
						className="step-btn vbox vcenter hcenter"
						onClick={() => {
							this.data.goal.reminder.enabled = false;
							this.data.currentStep--;
						}}
					>
						Back
					</Button>
					<div className="grow-1"></div>
					<Button className="step-btn vbox vcenter hcenter" onClick={this.onSaveGoal}>
						Finish
					</Button>
				</div>
			</>
		);
	}

	GoalAddedSheet() {
		let { summary, longTermParentId } = this.data.goal;
		let parent = LongTermGoals[longTermParentId];
		return (
			<div className="success-screen">
				<img src={checkSVG} className="success-check" alt="Checkmark Icon" />
				<h1>Goal Added!</h1>
				<div className="date">{`Today, ${format(new Date(), 'MMM d')}`}</div>
				<div className="goal-text">
					<div className="goal">{summary}</div>
					<div className="parent">{parent?.name || 'Unknown Long Term Goal'}</div>
				</div>
				<h3>Your next steps</h3>
				<div className="btn-ctn vbox hcenter">
					<Link
						className="glass-btn"
						popupClose
						onClick={() => {
							let toEdit = _.cloneDeep(this.data.goal);
							this.closeSheet();
							this.props.onEdit(toEdit);
						}}
					>
						Edit Goal
					</Link>
					<Link
						className="glass-btn"
						popupClose
						onClick={() => {
							this.closeSheet();
							appStore.bvrActiveTab = 'goals';
							f7.view.main.router.navigate('/bvr-goals');
						}}
					>
						View All Goals
					</Link>
					<Link
						popupClose
						className="glass-btn"
						onClick={() => {
							this.closeSheet();
							appStore.bvrActiveTab = 'home';
							f7.view.main.router.navigate('/bvr-landing');
						}}
					>
						Back to Home
					</Link>
				</div>
			</div>
		);
		return (
			<div>
				GOAL ADDED!
				<Link popupClose large className="vbox vcenter hcenter" onClick={this.closeSheet}>
					Back to Home
				</Link>
			</div>
		);
	}

	getHelpOverlay() {
		const closeHelp = () => {
			this.data.helpOverlay = null;
			this.data.activeExample = 1;
			this.data.sampleGoalOpen = false;
		};
		const SampleGoal = () => {
			let exampleGoal = [
				{
					summary: 'Call my mom',
					body: 'Keep regular, standing dates to chat with my mom every week',
					measurement: '30 minutes, 2 contacts',
					timing: 'Weekly, Sunday and Thursday',
					parent: 19
				},
				{
					summary: 'Get a library card',
					body: 'Go to the local library this Saturday morning. with my identification, and ask about getting a library card',
					measurement: '1 hour',
					timing: 'One Time, this Saturday',
					parent: 1
				},
				{
					summary: 'Text Adam',
					body: 'Send a text message to Adam this week saying "Hi! Just thinking of you!"',
					measurement: '1 text message',
					timing: 'One Time, this weekend',
					parent: 20
				}
			][this.data.activeExample - 1];
			return (
				<>
					<Button
						outline
						className="example-btn"
						onClick={() => {
							this.data.sampleGoalOpen = !this.data.sampleGoalOpen;
						}}
					>
						View SMART Goals Example
						{this.data.sampleGoalOpen ? <i className="f7-icons">chevron_up</i> : <i className="f7-icons">chevron_down</i>}
					</Button>
					{this.data.sampleGoalOpen && (
						<div className="example-goal">
							<div className="example-tgls hbox vcenter">
								<div
									className={`example-tgl ${this.data.activeExample === 1 ? 'active' : ''}`}
									onClick={() => {
										this.data.activeExample = 1;
									}}
								>
									EXAMPLE 1
								</div>
								<div
									className={`example-tgl ${this.data.activeExample === 2 ? 'active' : ''}`}
									onClick={() => {
										this.data.activeExample = 2;
									}}
								>
									EXAMPLE 2
								</div>
								<div
									className={`example-tgl ${this.data.activeExample === 3 ? 'active' : ''}`}
									onClick={() => {
										this.data.activeExample = 3;
									}}
								>
									EXAMPLE 3
								</div>
							</div>
							<h3>{exampleGoal.summary}</h3>
							<div className="goal-card">
								<h3>RELATED LONG-TERM GOAL</h3>

								<div className="value hbox vcenter ltg">
									<img src={LongTermGoals[exampleGoal.parent]?.icon} alt="Long term goal icon" />
									{LongTermGoals[exampleGoal.parent]?.name || `Unknown Long Term Goal ${exampleGoal.parent}`}
								</div>

								<div className="divider-line"></div>
								<h3>SPECIFY GOAL</h3>
								<p>{exampleGoal.body}</p>
								<div className="divider-line"></div>
								<h3>MEASURABLE TARGET</h3>
								<p>{exampleGoal.measurement}</p>
								<div className="divider-line"></div>
								<h3>ATTAINABLE</h3>
								<p>Yes</p>
								<div className="divider-line"></div>
								<h3>RELEVANT</h3>
								<p>Yes</p>
								<div className="divider-line"></div>
								<h3>TIME-BOUND</h3>
								<p>{exampleGoal.timing}</p>
							</div>
						</div>
					)}
				</>
			);
		};
		switch (this.data.helpOverlay) {
			case 'specific': {
				return (
					<div className="help-overlay">
						<div className="header hbox vcenter">
							<div className="grow-1"></div>
							<div className="close" onClick={closeHelp}>
								<i className="f7-icons">xmark</i>
							</div>
						</div>
						<div className="title">Specific Goal</div>
						<p>Specify your goal by making it quantifiable. Consider these points below to help you.</p>
						<p className="number-list">
							{`1. What do I want to accomplish?\n2. Why is this goal important?\n3. Who is involved?\n4. Where is it located?\n5. Which resources/limitations are involved?`}
						</p>
						{SampleGoal()}
					</div>
				);
			}
			case 'measure': {
				return (
					<div className="help-overlay">
						<div className="header hbox vcenter">
							<div className="grow-1"></div>
							<div className="close" onClick={closeHelp}>
								<i className="f7-icons">xmark</i>
							</div>
						</div>
						<div className="title">How to measure your goal?</div>
						<p>
							Determine a unit or metric so you can track progress, stay motivated, and recognize when you have achieved it. A
							measurable goal should address questions such as:
						</p>
						<p className="number-list">{`1. How much?\n2. How many?\n3. How will I know when it is accomplished?`}</p>
						{SampleGoal()}
					</div>
				);
			}
			case 'attainable': {
				return (
					<div className="help-overlay">
						<div className="header hbox vcenter">
							<div className="grow-1"></div>
							<div className="close" onClick={closeHelp}>
								<i className="f7-icons">xmark</i>
							</div>
						</div>
						<div className="title">How is the goal attainable?</div>
						<p>Let's make sure that your goal is actually realistic and attainable given your unique circumstances.</p>
						<p>An achievable goal will usually answer questions such as:</p>
						<p className="number-list">{`1. How can I accomplish this goal?\n2. Does achievement of this goal depend on someone else?\n3. How realistic is the goal, based on other constraints, such as financial factors?`}</p>
						{SampleGoal()}
					</div>
				);
			}
			case 'relevant': {
				return (
					<div className="help-overlay">
						<div className="header hbox vcenter">
							<div className="grow-1"></div>
							<div className="close" onClick={closeHelp}>
								<i className="f7-icons">xmark</i>
							</div>
						</div>
						<div className="title">How relevant is this goal?</div>
						<p>Let's make sure that your goal really matters to you, and that it aligns with other relevant goals.</p>
						<p>Try answer the following questions:</p>
						<p className="number-list">{`1. Does pursuing the goal seem worthwhile?\n2. Is it the right time?\n3. Am I the right person to reach this goal?\n4. How is this goal connected to other goals?`}</p>
						{SampleGoal()}
					</div>
				);
			}
			case 'deadline': {
				return (
					<div className="help-overlay">
						<div className="header hbox vcenter">
							<div className="grow-1"></div>
							<div className="close" onClick={closeHelp}>
								<i className="f7-icons">xmark</i>
							</div>
						</div>
						<div className="title">What is your goal time frame?</div>
						<p>
							The reason why your goal should be time-bound is relatively self-explanatory, because every goal needs a target date; a
							deadline to work towards.
						</p>
						{SampleGoal()}
					</div>
				);
			}
		}
	}

	AdvancedGoalSheet1() {
		const isDisabled = () => {
			let { body, summary, measurements, deadline } = this.data.goal;
			let valid = true;

			measurements.forEach((m) => {
				if (m.amount <= 0 || m.unit.length < 1) {
					valid = false;
				}
			});
			if (!valid) {
				return true;
			}
			if (summary.length < 5) {
				return true;
			}
			let frequency = _.get(this.data.goal, 'deadline.repeat.frequency', null);
			if (frequency === 'month' && !_.get(this.data.goal, 'deadline.repeat.hasStart')) {
				return true;
			}
			if (frequency === 'week') {
				let hasSelected = false;
				let cadence = _.get(this.data.goal, 'deadline.repeat.cadence', null);
				Object.keys(cadence).forEach((day) => {
					if (cadence[day] === true) {
						hasSelected = true;
					}
				});
				if (!hasSelected) {
					return true;
				}
			}
			return false;
		};

		return (
			<div className="advanced-flow">
				<div className="title">Create your Short-term goal</div>
				{this.GoalSummary()}
				<div className="section-divider"></div>
				{this.GoalMeasurement()}
				<div className="section-divider"></div>
				<div className="info-label hbox vcenter">
					<h3>Set your deadline</h3>
					<div
						className="info-icon"
						onClick={() => {
							this.data.helpOverlay = 'deadline';
						}}
					>
						i
					</div>
				</div>
				{this.GoalDeadline()}
				<div className="section-divider"></div>
				<div className="info-label hbox vcenter">
					<h3>Set an SMS Reminder (Optional)</h3>
				</div>
				{this.GoalReminder()}
				<div className="section-divider"></div>
				<div className="hbox vcenter hcenter mb-100">
					<Button
						className="step-btn vbox vcenter hcenter"
						onClick={() => {
							this.data.flowType = null;
						}}
					>
						Back
					</Button>
					<div className="grow-1"></div>
					<Button
						disabled={isDisabled()}
						className="step-btn vbox vcenter hcenter"
						onClick={() => {
							this.data.currentStep++;
						}}
					>
						Finish
					</Button>
				</div>
			</div>
		);
	}

	AdvancedGoalSheet2() {
		return (
			<>
				<div className="title">Would you like to add SMART details for you Short-term goals?</div>
				<div
					className="card-btn"
					onClick={() => {
						this.data.currentStep++;
					}}
				>
					Yes
				</div>
				<div className="card-btn" onClick={this.onSaveGoal}>
					Skip
				</div>
				<div className="hbox vcenter hcenter">
					<Button
						className="step-btn vbox vcenter hcenter"
						onClick={() => {
							this.data.currentStep--;
						}}
					>
						Back
					</Button>
					<div className="grow-1"></div>
				</div>
			</>
		);
	}

	AdvancedGoalSheet3() {
		const isDisabled = () => {
			let { body } = this.data.goal;
			if (body.length < 5) {
				return true;
			}
			let { yes1, yes2 } = this.data.advancedCheck;
			if (!yes1 || !yes2) {
				return true;
			}
			return false;
		};
		let { yes1, yes2 } = this.data.advancedCheck;
		return (
			<div className="advanced-flow step-3">
				<div className="title">Short-term goal details</div>
				{this.GoalDetails()}
				<div className="section-divider"></div>
				<div className="info-label hbox vcenter">
					<h3>Attainable Goal</h3>
					<div
						className="info-icon"
						onClick={() => {
							this.data.helpOverlay = 'attainable';
						}}
					>
						i
					</div>
				</div>
				<h2>Do you feel confident that you will do it?</h2>
				<div className="hbox vcenter yes-no">
					<div
						className={`card-btn ${yes1 ? 'selected' : ''}`}
						onClick={() => {
							this.data.advancedCheck.yes1 = true;
						}}
					>
						Yes
					</div>
					<div
						className="card-btn"
						onClick={() => {
							const prompt = f7.dialog.create({
								text: `We suggest that your  Short-term goal should be <strong>attainable</strong> to you, please review your initial goal so you'll be confident achieving it.`,
								destroyOnClose: true,
								buttons: [
									{
										text: 'Review Goal',
										onClick: () => {
											this.data.advancedCheck.yes1 = false;
											this.data.advancedCheck.yes2 = false;
											this.data.currentStep = 0;
										}
									}
								]
							});
							prompt.open();
						}}
					>
						No
					</div>
				</div>
				<div className="section-divider"></div>
				<div className="info-label hbox vcenter">
					<h3>Relevant Goal</h3>
					<div
						className="info-icon"
						onClick={() => {
							this.data.helpOverlay = 'relevant';
						}}
					>
						i
					</div>
				</div>
				<h2>Is this goal meaningful to you?</h2>
				<div className="hbox vcenter yes-no">
					<div
						className={`card-btn ${yes2 ? 'selected' : ''}`}
						onClick={() => {
							this.data.advancedCheck.yes2 = true;
						}}
					>
						Yes
					</div>

					<div
						className="card-btn"
						onClick={() => {
							const prompt = f7.dialog.create({
								text: `We suggest that your Short-term goal should be <strong>relevant</strong> to you, please review your initial goal and adjust it to be meaningful to you.`,
								destroyOnClose: true,
								buttons: [
									{
										text: 'Review Goal',
										onClick: () => {
											this.data.advancedCheck.yes1 = false;
											this.data.advancedCheck.yes2 = false;
											this.data.currentStep = 0;
										}
									}
								]
							});
							prompt.open();
						}}
					>
						No
					</div>
				</div>

				<div className="section-divider"></div>
				<div className="hbox vcenter hcenter mb-100">
					<Button
						className="step-btn vbox vcenter hcenter"
						onClick={() => {
							this.data.currentStep--;
						}}
					>
						Back
					</Button>
					<div className="grow-1"></div>
					<Button disabled={isDisabled()} className="step-btn vbox vcenter hcenter" onClick={this.onSaveGoal}>
						Finish
					</Button>
				</div>
			</div>
		);
	}

	constructFlow(flowType) {
		if (this.data.helpOverlay !== null) {
			return this.getHelpOverlay(this.data.helpOverlay);
		}
		switch (flowType) {
			case 'stepByStep': {
				const currentScreen = [
					this.SpecifyGoalSheet,
					this.MeasureGoalSheet,
					this.ConfidencePrompt,
					this.MeaningPrompt,
					this.DeadlineSheet,
					this.ReminderPromptSheet,
					this.SMSReminderSheet
				][this.data.currentStep];
				if (!currentScreen || typeof currentScreen !== 'function') {
					return (
						<Link popupClose className="step-btn vbox vcenter hcenter" onClick={this.closeSheet}>
							Unknown Flow
						</Link>
					);
				}
				return currentScreen();
			}
			case 'advanced': {
				const currentScreen = [this.AdvancedGoalSheet1, this.AdvancedGoalSheet2, this.AdvancedGoalSheet3][this.data.currentStep];
				if (!currentScreen || typeof currentScreen !== 'function') {
					return (
						<Link popupClose className="step-btn vbox vcenter hcenter" onClick={this.closeSheet}>
							Unknown Flow
						</Link>
					);
				}
				return currentScreen();
			}
			default: {
				return (
					<Link popupClose className="step-btn vbox vcenter hcenter" onClick={this.closeSheet}>
						Unknown Flow
					</Link>
				);
			}
		}
		return <div>WUT</div>;
	}

	closeSheet() {
		this.data.flowComplete = false;
		this.data.savedGoal = null;
		this.data.flowType = null;
		this.data.activeExample = 1;
		this.data.goal = this.initialGoal(true);
		this.data.currentStep = 0;
	}

	getStepContent() {
		let { currentStep, goal, loading, longTermGoals, flowType, flowComplete } = this.data;
		console.log(`MY LONG TERM GOALS`);
		console.log(longTermGoals);
		console.log(`==============`);
		if (flowComplete) {
			return this.GoalAddedSheet();
		}
		if (!goal.longTermParentId) {
			return (
				<>
					<div className="title">Choose a Long-term Goal relevant to your new Short-term goal</div>
					{loading && (
						<div className="hbox vcenter hcenter loader">
							<Preloader></Preloader>
						</div>
					)}
					{!loading &&
						longTermGoals.length > 0 &&
						longTermGoals.map((ltGoal) => {
							return (
								<div
									key={`ltg-${ltGoal}-card`}
									className="ltg card-btn hbox vcenter hleft"
									onClick={() => {
										this.data.goal.longTermParentId = ltGoal;
									}}
								>
									<img src={LongTermGoals[ltGoal] ? LongTermGoals[ltGoal]?.icon : LongTermGoals[1].i} alt="Long term goal icon" />
									{LongTermGoals[ltGoal] ? LongTermGoals[ltGoal]?.name : `Unknown Goal ${ltGoal}`}
								</div>
							);
						})}
					{!loading && longTermGoals.length == 0 && (
						<div className="card-btn vbox vcenter hcenter" onClick={this.closeSheet}>
							No long term goals found
						</div>
					)}
					<div className="vbox vcenter hcenter">
						<Link popupClose className="step-btn vbox vcenter hcenter" onClick={this.closeSheet}>
							Cancel
						</Link>
					</div>
				</>
			);
		} else if (flowType !== null) {
			return this.constructFlow(flowType);
		} else {
			return this.SMARTGoalScreen();
		}
	}

	render() {
		let { onPopupClosed, opened } = this.props;
		return (
			<Popup
				className={`goal-capture-popup ${!appStore.isMobile ? 'desktop' : 'mobile'}`}
				opened={opened}
				closeByBackdropClick={false}
				closeOnEscape={false}
				onPopupOpened={this.onPopupOpened}
				onPopupClosed={onPopupClosed}
			>
				<div className={`background ${(this.data.flowType !== null && this.data.flowType) || ''}`}>
					{this.data.flowComplete && <img src={waveBG} alt="background blue wave" />}
				</div>
				<div className={`page-ctn y-scroll`}>{opened && this.getStepContent()}</div>
			</Popup>
		);
	}
}

export default observer(GoalCapturePopup);
