import Signup from '../pages/signup/Signup.js';
import Home from '../pages/home/Home.js';
import BVRLanding from '@/pages/BVRLanding/BVRLanding.js';
import BVRThoughts from '@/pages/BVRThoughts/BVRThoughts.js';
import BVRGoals from '@/pages/BVRGoals/BVRGoals.js';
import ProgressPage from '@/pages/ProgressPage/ProgressPage.jsx';

var routes = [
	{
		path: '/',
		component: Signup
	},
	{
		path: '/home',
		component: Home
	},
	{
		path: '/bvr-landing',
		component: BVRLanding
	},
	{
		path: '/bvr-thoughts',
		component: BVRThoughts
	},
	{
		path: '/bvr-goals',
		component: BVRGoals
	},
	{
		path: '/bvr-progress',
		component: ProgressPage
	}
];

export default routes;
