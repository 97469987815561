import React, { Component } from 'react';
import { Link, Popover, List } from 'framework7-react';
import { observer } from '../../utils/State';
import { autoBind } from '../../utils/GeneralUtils';
import './drop-down.scss';
import _ from 'lodash';

@observer
export default class DropDown extends Component {
	constructor(props) {
		super(props);
		autoBind(this);
	}

	render() {
		let { label, name, icon, items, displayValue, className, subtext } = this.props;
		let addedClass = '';
		switch (name) {
			case 'formbuilder-dropdown-userId':
				addedClass = 'capitalize';
				break;

			default:
				break;
		}
		return (
			<>
				<Link className={`dropdown-component vbox ${className}`} popoverOpen={`.${name}`} id={name}>
					<div className="label">{label}</div>
					<div className="gradiant">
						<div className="selected-value hbox vcenter neumorphic">
							{icon && <i className={icon} />}
							<div className={`grow-1 ellipse ${addedClass}`}>{displayValue}</div>
							{items.length > 1 && <i className="bx bx-chevron-down"></i>}
						</div>
					</div>
					{!!subtext && subtext}
				</Link>
				<Popover className={name}>
					<List>{items}</List>
				</Popover>
			</>
		);
	}
}
