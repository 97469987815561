import * as React from 'react';
const GoalSVG = (props) => {
	return (
		<svg width="19" height="24" viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label="Goals Icon" {...props}>
			<g clipPath="url(#clip0_1635_7520)">
				<path
					d="M15.8024 2.28577H3.19748C1.7495 2.28577 0.575684 3.45157 0.575684 4.88966V20.8247C0.575684 22.2628 1.7495 23.4286 3.19748 23.4286H15.8024C17.2504 23.4286 18.4242 22.2628 18.4242 20.8247V4.88966C18.4242 3.45157 17.2504 2.28577 15.8024 2.28577Z"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
					className="path"
				/>
				<path
					d="M6.91736 0.740278L7.81273 0.102065C7.90653 0.0348977 8.02014 0.000129339 8.13597 0.00314181H10.3114C10.4278 -0.000191131 10.5418 0.0358372 10.6346 0.105256L11.5849 0.797717C11.6777 0.867136 11.7918 0.903164 11.9082 0.899831H12.9555C13.0946 0.900674 13.2278 0.955847 13.3259 1.0533C13.424 1.15076 13.4791 1.28258 13.4791 1.41997V3.01551C13.4791 3.15291 13.424 3.28473 13.3259 3.38218C13.2278 3.47964 13.0946 3.53481 12.9555 3.53565H5.50803C5.439 3.53565 5.37064 3.52218 5.30689 3.496C5.24315 3.46982 5.18528 3.43145 5.13661 3.38311C5.08794 3.33477 5.04944 3.27741 5.02332 3.21432C4.99719 3.15123 4.98396 3.08366 4.98439 3.01551V1.41997C4.98345 1.28557 5.03558 1.1561 5.12971 1.059C5.22385 0.961895 5.35259 0.904806 5.48864 0.899831L6.62967 0.861539C6.73554 0.849849 6.83559 0.807679 6.91736 0.740278Z"
					className="fillme"
				/>
				<path d="M6.90918 6.28577H16.1213" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="path" />
				<path d="M6.90918 10.8572H16.1213" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="path" />
				<path d="M6.90918 15.4286H16.1213" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="path" />
				<path d="M6.90918 20H16.1213" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="path" />
				<path
					d="M2.87891 19.8933L3.77006 20.5715L4.60618 18.8572"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
					className="path"
				/>
				<path
					d="M2.87891 15.3219L3.77006 16.0001L4.60618 14.2858"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
					className="path"
				/>
				<path
					d="M2.87891 11.3219L3.77006 12.0001L4.60618 10.2858"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
					className="path"
				/>
				<path
					d="M2.87891 6.75036L3.77006 7.42852L4.60618 5.71423"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
					className="path"
				/>
			</g>
		</svg>
	);
};

export default GoalSVG;
