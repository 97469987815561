import React, { Component } from 'react';
import { observable, observer } from '@/utils/State';
import { autoBind, formatPhoneNumber, showError } from '@/utils/GeneralUtils';
import { Button, f7, Link, Popup } from 'framework7-react';
import behavrLogo from '@/assets/logo.png';
import appStore from '@/stores/AppStore';
import './support.scss';
import _ from 'lodash';
import BasicInput from '@/components/basic-input/BasicInput';
import APIService from '@/services/APIService';

@observer
export default class SupportPage extends Component {
	constructor(props) {
		super(props);
		this.data = observable({
			showTicketScreen: false,
			description: ''
		});
		autoBind(this);
	}

	closeLinkPopup() {
		this.data.description = '';
		if (this.props.onClose) {
			this.props.onClose();
		}
	}

	handlePopupOpen() {
		console.log('Support Popup Opened');
	}

	submitSupportTicket() {
		f7.dialog.preloader();
		APIService.submitSupportTicket(this.data.description)
			.then((res) => {
				f7.dialog.close();
				console.log(res);
				this.data.description = '';
				this.data.showTicketScreen = false;
				var toast = f7.toast.create({
					text: 'Success!',
					position: 'center',
					closeTimeout: 2000
				});
				toast.open();
			})
			.catch((err) => {
				showError(err);
			});
	}

	handleSupportClick(e) {
		const supportCall = () => {
			const support = {
				name: 'BehaVR',
				phone: '8669234287'
			};
			f7.dialog
				.create({
					title: 'Need Help?',
					text: `Our Customer Experience team is here to support you. \n\nOur standard support hours are Monday thru Friday from 8 AM - 6 PM ET. \n\n If you need help, please contact BehaVR at ${formatPhoneNumber(
						support.phone
					)}.`,
					buttons: [
						{
							text: 'Call Now',
							onClick: () => {
								const phoneUrl = `tel:${support.phone}`;
								if (!appStore.isMobile) {
									f7.dialog.alert(formatPhoneNumber(support.phone), 'Phone');
									return;
								}
								window.location.href = phoneUrl;
							}
						},
						{
							text: 'Cancel',
							onClick: () => {
								console.log('cancel clicked');
							}
						}
					],
					verticalButtons: false
				})
				.open();
		};
		switch (e.currentTarget?.dataset?.type) {
			case 'faq': {
				window.open('https://behavr.my.site.com/help/s/', '_blank');
				break;
			}
			case 'tutorial': {
				appStore.openVideoKey = 'https://player.vimeo.com/video/549319579?h=733fbb3e5b';
				break;
			}
			case 'tutorial-quest': {
				appStore.openVideoKey = 'https://player.vimeo.com/video/658778860?h=16c56d9347';
				break;
			}
			case 'ticket': {
				this.data.showTicketScreen = true;
				break;
			}
			case 'disclaimer': {
				f7.dialog.alert(
					`Any information or advice provided in connection with this application is not meant to be medical advice, and you acknowledge and agree that you are not using or relying upon it as such. You should consult your physician or other health care professional before making any lifestyle or behavior changes or discontinuing any prescription medication that may affect any medical plan of care, as established by your physician or other health care professional.`,
					'Disclaimer'
				);
				break;
			}
			case 'terms': {
				window.open('https://www.behavr.com/about-us/health-safety-and-policies/', '_blank');
				break;
			}
			case 'contact': {
				supportCall();
				break;
			}
			default: {
				supportCall();
				break;
			}
		}
	}

	render() {
		let { opened } = this.props;
		return (
			<Popup
				className={`support-page-popup ${!appStore.isMobile ? 'desktop' : 'mobile'}`}
				opened={opened}
				closeByBackdropClick={false}
				closeOnEscape={false}
				onPopupClosed={this.closeLinkPopup}
				onPopupOpened={this.handlePopupOpen}
				backdrop={false}
			>
				<div className="popup-header hbox vcenter">
					<Link popupClose className="back-btn hbox vcenter">
						<i className="bx bx-arrow-back"></i> Back
					</Link>
					<div className="grow-1"></div>
					<img src={behavrLogo} className="header-logo" alt="BehaVR Logo" />
				</div>
				<div className="main-content y-scroll">
					<h1>Help & Support</h1>
					{this.data.showTicketScreen && (
						<div>
							<BasicInput
								key={`support-ticket-input-body`}
								validate
								name="body"
								type="textarea"
								placeholder="What can we help you with?"
								label="Description"
								value={this.data.description}
								errorMessage={`Do not provide personal or health information`}
								onChange={(e) => {
									this.data.description = e.target.value;
								}}
								className="support-input"
							/>
							<Button
								fill
								large
								className="primary-btn"
								id="signup-button"
								disabled={this.data.description.length === 0}
								onClick={this.submitSupportTicket}
							>
								Submit
							</Button>
							<Button
								large
								onClick={() => {
									this.data.showTicketScreen = false;
								}}
							>
								Cancel
							</Button>
						</div>
					)}
					{!this.data.showTicketScreen && (
						<>
							<div className="support-btn hbox vcenter" data-type="faq" onClick={this.handleSupportClick}>
								<i className="bx bx-help-circle"></i>
								FAQ
							</div>
							{appStore?.referringEntity?.headsetType === 'pico' && (
								<div className="support-btn hbox vcenter" data-type="tutorial" onClick={this.handleSupportClick}>
									<i className="bx bxs-videos"></i>
									Setup Tutorial
								</div>
							)}
							{appStore?.referringEntity?.headsetType === 'quest' && (
								<div className="support-btn hbox vcenter" data-type="tutorial-quest" onClick={this.handleSupportClick}>
									<i className="bx bxs-videos"></i>
									Setup Tutorial
								</div>
							)}
							<div className="support-btn hbox vcenter" data-type="ticket" onClick={this.handleSupportClick}>
								<i className="bx bx-receipt"></i>
								Create Support Ticket
							</div>
							<div className="support-btn hbox vcenter" data-type="disclaimer" onClick={this.handleSupportClick}>
								<i className="bx bx-error-circle"></i>
								Disclaimer
							</div>
							<div className="support-btn hbox vcenter" data-type="terms" onClick={this.handleSupportClick}>
								<i className="bx bx-check-shield"></i>
								Health & Saftey Warning and Policies
							</div>
							<div className="support-btn hbox vcenter" data-type="contact" onClick={this.handleSupportClick}>
								<i className="bx bx-phone-call"></i>
								Contact Us
							</div>
						</>
					)}
				</div>
			</Popup>
		);
	}
}
