import React from 'react';
import { Button, f7, Fab, Icon, Page, Preloader } from 'framework7-react';
import { observer, ReactiveComponent } from '@/utils/State';
import backgroundImage from '@/assets/bvrprogress.png';
import appStore, { getUserProductConfig } from '@/stores/AppStore';
import { autoBind, showError } from '@/utils/GeneralUtils';
import { Splash } from '../Splash';
import progressIcon from '@/assets/progressIcon.png';
import APIService from '@/services/APIService';
import BVRNav from '@/components/bvr-nav/BVRNav';
import './progress-page.scss';
import _ from 'lodash';

class ProgressPage extends ReactiveComponent {
	constructor(props) {
		super(props);
		this.setData({
			loading: true
		});
		autoBind(this);
		this.imgRef = React.createRef();
	}

	componentDidMount() {
		this.data.loading = false;
	}

	ProgressContent() {
		//* If not unlocked show coming soon
		let { progress } = getUserProductConfig('first-resort');
		if (!progress) {
			return (
				<div className="empty-message">
					<div className="image-ctn">
						<img src={progressIcon} alt="Progress Icon" />
					</div>
					<h2>Coming Soon!</h2>
					<p>Stay on track with your VR practice to unlock this feature.</p>
				</div>
			);
		}

		return <div className="progress-content">Empty Display</div>;
	}

	render() {
		if (!appStore.isLoggedIn) {
			return <Splash></Splash>;
		}
		let { loading } = this.data;
		const imageHeight = this.imgRef?.current?.clientHeight || 300;
		return (
			<Page name="progress-page" className={`progress-page-cmp ${!appStore.isMobile ? 'desktop' : 'mobile'}`}>
				<div className={`background`}></div>
				<div className="content-ctn y-scroll">
					<div className="img-ctn vbox hcenter" ref={this.imgRef}>
						<img src={backgroundImage} className="bg-image" alt="background waves image" />
					</div>
					<h1 className="text-gradiant" style={{ marginTop: -imageHeight / 3 }}>
						Your Progress
					</h1>
					<div className="divider-line"></div>
					{!loading && this.ProgressContent()}
					{loading && (
						<div className="hbox vcenter hcenter">
							<Preloader size={32} color="blue"></Preloader>
						</div>
					)}
				</div>

				<BVRNav onDataChanged={this.onDataChanged}></BVRNav>
			</Page>
		);
	}
}

export default observer(ProgressPage);
