// @ts-nocheck
import _ from 'lodash';
import axios from 'axios';
import appStore from '../stores/AppStore';

export const TrackedEvents = {
	REQUEST_VERIFICATION_CODE: 'REQUEST_VERIFICATION_CODE',
	INVALID_PHONE_SUBMISSION: 'INVALID_PHONE_SUBMISSION',
	VERIFIED_PHONE: 'VERIFIED_PHONE',
	ADDRESS_SEARCH: 'ADDRESS_SEARCH',
	SUBMIT_REGISTRATION: 'SUBMIT_REGISTRATION',
	PARTNER_LINK_CLICK: 'PARTNER_LINK_CLICK',
	SKIP_PWA_INSTALL: 'SKIP_PWA_INSTALL',
	INSTALL_PWA: 'INSTALL_PWA', //! LAST PRE-LOGIN EVENT
	LOGIN: 'LOGIN',
	LOGOUT: 'LOGOUT',
	PWAPinCreated: 'PWAPinCreated'
};

class AnalyticsService {
	getUserId() {
		//default to firebaseId and backup the sessionId of the user
		return _.get(appStore, 'user.fId', `unknown-user-${appStore.sessionId}`);
	}

	getHeaders() {
		const headers = { 'Access-Control-Allow-Origin': '*' };
		headers.authorization = appStore.userToken;
		return headers;
	}

	track(event, data) {
		let eventData = {
			sessionId: appStore.sessionId,
			isPWA: appStore.isPWA(),
			deviceDetails: appStore.deviceDetails,
			userId: this.getUserId()
		};
		if (data) {
			eventData = { ...data, ...eventData };
		}
		this.trackEvent(event, eventData);
	}

	getEnviornmentName() {
		switch (location.hostname) {
			case 'dev.behavr.app':
			case 'behavr-pwa-dev.web.app':
			case 'tmppwa.web.app': {
				return 'development';
			}
			case 'localhost':
			case 'irbehavr.web.app': {
				return 'refinery';
			}
			case 'staging.behavr.app': {
				return 'staging';
			}
			case 'behavr.app': {
				return 'production';
			}
			default: {
				return 'refinery';
			}
		}
	}

	async trackEvent(event, data) {
		try {
			console.log(this.getHeaders());
			const response = axios({
				method: 'POST',
				contentType: 'application/json',
				url: `https://${this.getEnviornmentName()}-ir-behavr-functions.azurewebsites.net/api/publish-analytics-event-api`,
				headers: this.getHeaders(),
				data: {
					metaData: data,
					event,
					time: new Date().getTime(),
					userId: this.getUserId()
				}
			}).then(
				(response) => {
					console.log('Analytics data posted successfully');
				},
				(error) => {
					console.log('Analytics data post error');
					console.log(error);
				}
			);
		} catch (err) {
			console.log(err);
		}
	}
}

export default new AnalyticsService();
