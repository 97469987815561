import React from 'react';
import appStore from '@/stores/AppStore';
import { f7, Button, Link, Popup, Toggle, Input } from 'framework7-react';
import { ReactiveComponent, observer } from '@/utils/State';
import Slider from 'rc-slider';
import { autoBind, showError } from '@/utils/GeneralUtils';
import BasicInput from '../basic-input/BasicInput';
import light1A from '@/assets/light1A.png';
import light2A from '@/assets/light2A.png';
import light3A from '@/assets/light3A.png';
import light4A from '@/assets/light4A.png';
import light5A from '@/assets/light5A.png';
import light1P from '@/assets/light1P.png';
import light2P from '@/assets/light2P.png';
import light3P from '@/assets/light3P.png';
import light4P from '@/assets/light4P.png';
import light5P from '@/assets/light5P.png';

import allornothingPNG from '@/assets/allornothing.png';
import blamePNG from '@/assets/blame.png';
import catastrophizingPNG from '@/assets/catastrophizing.png';
import disqualifyingPNG from '@/assets/disqualifying.png';
import mindreadingPNG from '@/assets/mindreading.png';

import waveBG from '@/assets/wavebg.png';
import torchOff from '@/assets/torch1.svg';
import torchOn from '@/assets/torch2.svg';
import checkSVG from '@/assets/check.svg';
import _ from 'lodash';
import './thought-capture-popup.scss';
import APIService from '@/services/APIService';
import { format } from 'date-fns';

const anxiousLights = [light1A, light2A, light3A, light4A, light5A];
const positiveLights = [light1P, light2P, light3P, light4P, light5P];

//* For info purposed only
const StepModel = {
	title: 'string',
	action: ['input', 'range', 'completed', 'inputWithDate', 'multiSelect'],
	placeholder: 'string',
	next: 'number',
	back: 'number',
	cards: [
		{
			label: 'string',
			type: 'string',
			info: 'string',
			icon: 'image'
		}
	]
};

const anxiousCapture = {
	1: {
		key: 'body',
		title: 'Type or record your thought',
		placeholder: '"I feel my family never listens"',
		action: 'input',
		maxLength: 500,
		next: 2
	},
	2: {
		key: 'trigger',
		title: 'What caused this thought?',
		placeholder: 'Think about what happened: where it was, when it was, and who was there.',
		action: 'inputWithDate',
		maxLength: 500,
		back: 1,
		next: 3
	},
	3: {
		key: 'impact',
		title: 'How much does this thought hold you back?',
		action: 'range',
		next: 4,
		back: 2
	},
	4: {
		key: 'category',
		title: 'How would you like to label this distorted thought? (Optional)',
		action: 'multiSelect',
		back: 3,
		next: 5,
		cards: [
			{
				label: 'Mindreading',
				type: 'mindreading',
				info: `Mindreading is when we make assumptions about what other people are thinking. Mindreading can be useful sometimes, like when we can tell that someone disagrees with something by rolling their eyes. 

				But, often-times, people can be difficult to read and you might fill in the blanks. Because mindreading can lead us to make incorrect assumptions, we may then start feeling anxious or bad about ourselves. For example, you may think that a colleague is angry with you because they did not say hello back to you when you passed them in the hallway. 
				
				This way of thinking is not based on evidence, but rather a guess, and what can be more helpful for your mood is to think of what else might explain behaviors of others and base your decisions on the facts that are in front of you. 
				`,
				icon: mindreadingPNG
			},
			{
				label: 'Catastrophizing',
				type: 'catastrophizing',
				info: `Catastrophizing consists of always assuming the worst. While considering potential negative outcomes may be occasionally useful for us because it will allow us to prepare, always thinking that the worst will occur leads to significant distress and anxiety. .

				Catastrophizing happens when uncertain events or even the smallest setback or small make us think of the worst possible outcomes and consequences. These thoughts can be hard to shake off, and can lead us to feel distressed and anxious .
				 
				So, when you find yourself jumping to the worst conclusion, especially without much real evidence to support these potential outcomes to believe it, you are likely catastrophizing.
				`,
				icon: catastrophizingPNG
			},
			{
				label: 'All-or-Nothing Thinking',
				type: 'allornothing',
				info: `“All-or-nothing” thinking, means you adopt extreme perspectives about yourself, others, and the world arounds you. When you engage in all-or-none thinking, you think in absolute terms. So, you may think that people are either all good or all bad, or that you are either worthy or not worthy. There is no in-between. 

				This kind of thinking may not only cause you to feel anxious or sad, but may also lead you to engage in some behaviors that are not useful to you, such as working till you achieve perfection, or giving up on something because you will never get it right. This kind of thinking can then really get in the way of you living the life you want. 
				
				In essence, when you fail to see the middle ground, ot take a balanced perspective, you may be engaging in all-or-nothing thinking.
				`,
				icon: allornothingPNG
			},
			{
				label: 'Disqualifying the Positive',
				type: 'disqualifying',
				info: `Disqualifying the positive is when you reject the good things and focus on or consider only the negative aspects. When you engage in this kind of thinking, you are basically saying that the positive aspects of a situation or your experience do not count. 

				When you only consider the negative, you fail to establish a balanced and more objective view of yourself, others, and your life’s circumstances. This may lead you to feel anxious, doubt yourself, and feel like a fraud when you achieve important things, something that is called the “impostor syndrome”. 
				
				This kind of thinking is one sided, and often exaggerated. So, when you undermine your progress and second-guess yourself any time something good happens, you are disqualifying the positive.
				`,
				icon: disqualifyingPNG
			},
			{
				label: 'Personalization and Blame',
				type: 'personalization',
				info: `We often try to make sense of things in the world, and this includes placing blame for when things do not go our way. 

				We are personalizing when we believe that we are entirely to blame for something that may have little or even nothing to do with us. This can lead us to feel anxious and distressed. For example, you might think that your friends intentionally excluded you when you saw them having dinner without you. 
				
				What can be more helpful is to examine how much control you have in different situations, what your particular role is in it, and thinking of other potential explanations for the situation. 
				`,
				icon: blamePNG
			}
		]
	},
	5: {
		key: 'anxious-thought-completed',
		title: 'Thought Added!',
		action: 'completed'
	}
};

const positiveCapture = {
	1: {
		key: 'body',
		title: 'Type or record your thought',
		placeholder: 'Share your positive thought.',
		action: 'input',
		maxLength: 500,
		next: 2
	},
	2: {
		key: 'trigger',
		title: 'What caused this thought?',
		placeholder: 'Think about what happened: where it was, when it was, and who was there.',
		action: 'inputWithDate',
		maxLength: 500,
		back: 1,
		next: 3
	},
	3: {
		key: 'impact',
		title: 'How much does this thought benefit you?',
		action: 'range',
		next: 4,
		back: 2
	},
	4: {
		key: 'positive-thought-completed',
		title: 'Thought Added!',
		action: 'completed'
	}
};

class ThoughtCapturePopup extends ReactiveComponent {
	constructor(props) {
		super(props);
		this.setData({
			currentStep: 1,
			thought: this.initialThought(),
			flowComplete: false,
			savedThought: null,
			activeCategoryInfo: null,
			tempThoughtMap: {},
			activeTempThought: null,
			editing: false
		});
		autoBind(this);
	}

	onPopupOpened() {
		this.data.editing = false;
		if (this.props.tempThoughts && this.props.tempThoughts.length > 0) {
			_.forEach(_.cloneDeep(this.props.tempThoughts), (tt) => {
				this.data.tempThoughtMap[tt._id] = tt;
			});
			this.processTempThought();
		}
	}

	processTempThought(afterSaving) {
		const thoughtIds = Object.keys(this.data.tempThoughtMap);
		if (thoughtIds.length > 0) {
			this.data.activeTempThought = _.cloneDeep(this.data.tempThoughtMap[thoughtIds[0]]);
		} else {
			this.data.activeTempThought = null;
		}
		if (this.data.activeTempThought && afterSaving) {
			this.data.savedThought = null;
			f7.dialog.alert(
				`Looks like there is more than one thought to complete. You also recorded, "${this.data.activeTempThought.body}"`,
				'Complete Thought',
				() => {
					let { type, body } = this.data.activeTempThought;
					this.data.flowComplete = false;
					this.data.currentStep = 1;
					this.data.thought = {
						type: type,
						trigger: '',
						triggeringDate: new Date(),
						body: body,
						impact: 3,
						categories: []
					};
				}
			);
		} else if (this.data.activeTempThought && !afterSaving) {
			let { type, body } = this.data.activeTempThought;
			this.data.flowComplete = false;
			this.data.thought = {
				type: type,
				trigger: '',
				triggeringDate: new Date(),
				body: body,
				impact: 3,
				categories: []
			};
		} else {
			console.log(`No temp thoughts to process;`);
		}
	}

	completeTempThought(thought, nextStep) {
		APIService.completeTempThought(this.data.activeTempThought._id, thought)
			.then((res) => {
				this.data.flowComplete = true;
				this.data.savedThought = this.transformSavedThought(res);
				this.data.currentStep = nextStep;
				f7.dialog.close();
				delete this.data.tempThoughtMap[Object.keys(this.data.tempThoughtMap)[0]];
				this.processTempThought(true);
			})
			.catch((err) => {
				showError(err);
			});
	}

	initialThought(clear) {
		if (!clear) {
			let { initialThought } = this.props;
			if (initialThought) {
				return _.cloneDeep(initialThought);
			}
		}
		return {
			type: null,
			trigger: '',
			triggeringDate: new Date(),
			body: '',
			impact: 3,
			categories: []
		};
	}

	getCategoryLevels() {
		let categories = [];
		let level = 1;
		_.forEach(this.data.thought.categories, (cat) => {
			categories.push({
				type: cat,
				level: level
			});
			level++;
		});
		return categories;
	}

	transformSavedThought(thought) {
		let categories = [];
		_.sortBy(thought.categories, ['level'], ['asc']).forEach((cat) => {
			categories.push(cat.type);
		});
		let transformed = { ...thought, categories: categories };
		//update from timestamps to date obj
		if (thought.triggeringDate) {
			transformed.triggeringDate = new Date(thought.triggeringDate);
		}
		return transformed;
	}

	saveThought(nextStep) {
		console.log(`Saving Thought...`);
		f7.dialog.preloader('Saving...');
		let thought = _.cloneDeep(this.data.thought);
		thought = {
			...thought,
			categories: this.getCategoryLevels(),
			triggeringDate: thought.triggeringDate ? new Date(thought.triggeringDate).getTime() : undefined
		};

		if (this.data.activeTempThought) {
			this.completeTempThought(thought, nextStep);
		} else {
			return APIService.createThought(thought)
				.then((res) => {
					this.data.flowComplete = true;
					this.data.savedThought = this.transformSavedThought(res);
					this.data.currentStep = nextStep;
					f7.dialog.close();
				})
				.catch((err) => {
					showError(err);
				});
		}
	}

	updateThought(thought, nextStep) {
		console.log(`Updating Thought...`);
		f7.dialog.preloader('Updating...');
		return APIService.updateThought({
			...thought,
			categories: this.getCategoryLevels(),
			triggeringDate: thought.triggeringDate ? new Date(thought.triggeringDate).getTime() : undefined
		})
			.then((res) => {
				this.data.flowComplete = true;
				this.data.savedThought = this.transformSavedThought(res);
				this.data.currentStep = nextStep;
				f7.dialog.close();
			})
			.catch((err) => {
				showError(err);
			});
	}

	onTriggerDateCange(e) {
		this.data.thought.triggeringDate = e.length > 0 ? e[0] : new Date();
	}

	onInputChange(evt) {
		let target = evt.currentTarget;
		_.set(this.data, `thought.${target.name}`, target.value);
	}

	onGoTo(step) {
		let thoughtType = this.data.thought.type;
		let finalStep = false;
		if (
			(thoughtType === 'anxious' && anxiousCapture[step].action === 'completed') ||
			(thoughtType === 'positive' && positiveCapture[step].action === 'completed')
		) {
			finalStep = true;
		}
		if (finalStep && this.data.savedThought != null) {
			this.updateThought({ ...this.data.savedThought, ...this.data.thought }, step);
		} else if (finalStep && this.data.savedThought == null) {
			this.saveThought(step);
		} else {
			this.data.currentStep = step;
		}
	}

	constructStep(step) {
		if (!step) {
			return '';
		}
		let { key, title, action, placeholder, next, back, cards, maxLength } = step;
		let thought = this.data.thought;
		switch (action) {
			case 'input': {
				return (
					<>
						<div className="title">{title}</div>
						<BasicInput
							key={`app-form-input-${key}`}
							validate
							name={key}
							type="textarea"
							placeholder={placeholder}
							value={this.data.thought[key]}
							onChange={this.onInputChange}
							className="thought-input"
						/>
						{thought[key].length >= 475 && (
							<div className={`input-counter hbox hright ${thought[key].length > maxLength ? 'red' : ''}`}>
								{maxLength - thought[key].length} characters left
							</div>
						)}
						<div className="spacer"></div>
						<div className="btn-ctn hbox vcenter">
							<Button
								disabled={!back && this.data.editing}
								className="step-btn"
								onClick={() => {
									if (back && back > 0) {
										this.onGoTo(back);
									} else {
										this.data.currentStep = 1;
										this.data.thought.type = null;
									}
								}}
							>
								Back
							</Button>
							<div className="grow-1"></div>
							<Button
								className="step-btn"
								disabled={this.data.thought[key].length < 10 || this.data.thought[key].length > maxLength}
								onClick={() => {
									this.onGoTo(next);
								}}
							>
								Next
							</Button>
						</div>
					</>
				);
			}
			case 'range': {
				return (
					<>
						<div className="title">{title}</div>
						<img
							src={thought.type === 'anxious' ? anxiousLights[thought.impact - 1] : positiveLights[thought.impact - 1]}
							className="lantern"
							alt="Lantern Icon"
						/>
						<div className="impact-text">{['Not at All', 'Slightly', 'Somewhat', 'Very Much', 'Extremely'][thought.impact - 1]}</div>
						<div className="vbox vcenter hcenter">
							<Slider
								min={1}
								max={5}
								value={thought.impact}
								dots={true}
								onChange={(val) => {
									this.data.thought.impact = val;
								}}
							/>
						</div>
						<div className="spacer"></div>
						<div className="btn-ctn hbox vcenter">
							<Button
								className="step-btn"
								onClick={() => {
									if (back && back > 0) {
										this.onGoTo(back);
									} else {
										this.data.currentStep = 1;
										this.data.thought.type = null;
									}
								}}
							>
								Back
							</Button>
							<div className="grow-1"></div>
							<Button
								className="step-btn"
								onClick={() => {
									this.onGoTo(next);
								}}
							>
								Next
							</Button>
						</div>
					</>
				);
			}
			case 'inputWithDate': {
				return (
					<>
						<div className="title">{title}</div>
						<div className="date-selector hbox vcenter hcenter">
							<div className="cal-icon">
								<i className="bx bx-calendar-alt"></i>
							</div>
							<Input
								label="Open in Modal"
								type="datepicker"
								placeholder="Today"
								value={[thought.triggeringDate]}
								onCalendarChange={this.onTriggerDateCange}
								calendarParams={{
									openIn: 'customModal',
									header: true,
									footer: true,
									dateFormat: 'M dd',
									backdrop: true,
									maxDate: new Date()
								}}
							></Input>
						</div>
						<BasicInput
							key={`app-form-input-${key}`}
							validate
							name={key}
							type="textarea"
							placeholder={placeholder}
							value={this.data.thought[key]}
							onChange={this.onInputChange}
							className="thought-trigger"
						/>
						{thought[key].length >= 475 && (
							<div className={`input-counter hbox hright ${thought[key].length > maxLength ? 'red' : ''}`}>
								{maxLength - thought[key].length} characters left
							</div>
						)}
						<div className="spacer"></div>
						<div className="btn-ctn hbox vcenter">
							<Button
								className="step-btn"
								onClick={() => {
									if (back && back > 0) {
										this.onGoTo(back);
									} else {
										this.data.currentStep = 1;
										this.data.thought.type = null;
									}
								}}
							>
								Back
							</Button>
							<div className="grow-1"></div>
							<Button
								className="step-btn"
								disabled={this.data.thought[key].length < 3 || this.data.thought[key].length > maxLength}
								onClick={() => {
									this.onGoTo(next);
								}}
							>
								Next
							</Button>
						</div>
					</>
				);
			}
			case 'multiSelect': {
				return (
					<>
						<div className="title">{title}</div>
						{cards.map((card) => {
							let { label, type, info, icon } = card;
							return (
								<div
									key={`category-card-${type}`}
									className={`card-btn hbox vcenter ${thought.categories.indexOf(type) >= 0 ? 'selected-cat' : ''}`}
									onClick={(e) => {
										if (e.target.innerHTML === 'i' || e.target.classList.contains('info-icon')) {
											return;
										}
										let index = thought.categories.indexOf(type);
										if (thought.categories.length < 2 && index < 0) {
											this.data.thought.categories.push(type);
										} else if (index >= 0) {
											this.data.thought.categories.splice(index, 1);
										}
									}}
								>
									<div className="icon-ctn">
										<img src={icon} className="image-icon" alt={`Icon for ${label}`} />
									</div>
									<div className="category-label grow-1">{label}</div>
									<div
										className="info-ctn"
										onClick={() => {
											this.data.activeCategoryInfo = card;
										}}
									>
										<div className="info-icon">
											<div>i</div>
										</div>
									</div>
								</div>
							);
						})}
						<div className="btn-ctn hbox vcenter">
							<Button
								className="step-btn"
								onClick={() => {
									if (back && back > 0) {
										this.onGoTo(back);
									} else {
										this.data.currentStep = 1;
										this.data.thought.type = null;
									}
								}}
							>
								Back
							</Button>
							<div className="grow-1"></div>
							<Button
								className="step-btn"
								onClick={() => {
									this.onGoTo(next);
								}}
							>
								Finish
							</Button>
						</div>
						<div className="spacer"></div>
						<div className="spacer"></div>
						<div className="spacer"></div>
					</>
				);
			}
			case 'completed': {
				return (
					<div className="success-screen">
						<img src={checkSVG} className="success-check" alt="Checkmark Icon" />
						<h1>Thought Added!</h1>
						<div className="date">{`Today, ${format(new Date(), 'MMM d')}`}</div>
						<div className="thought-text">
							<div className="thought">"{thought.body}"</div>
							{thought.type === 'anxious' && (
								<div className="hbox vcenter">
									{!this.data.savedThought?.isPriority && <img src={torchOff} alt="Torch Off Icon" />}
									{this.data.savedThought?.isPriority && <img src={torchOn} alt="Torch On Icon" />}
									<div className="toggle-text grow-1">Set as Priority</div>
									<Toggle
										className="md"
										value={this.data.savedThought?.isPriority ? true : false}
										onChange={(e) => {
											this.updateThought(
												{ ..._.cloneDeep(this.data.savedThought), isPriority: e.target.checked },
												this.data.currentStep
											);
										}}
									></Toggle>
								</div>
							)}
						</div>
						<h3>Your next steps</h3>
						<div className="btn-ctn vbox hcenter">
							<Button
								className="glass-btn"
								onClick={() => {
									this.data.flowComplete = false;
									this.data.editing = true;
									this.data.currentStep = 1;
								}}
							>
								Edit Thought
							</Button>
							<Link
								popupClose
								className="glass-btn"
								onClick={() => {
									this.data.flowComplete = false;
									this.data.savedThought = null;
									this.data.thought = this.initialThought(true);
									this.data.currentStep = 1;
								}}
							>
								Back to Home
							</Link>
						</div>
					</div>
				);
			}
			default: {
				return <div>Unknown Step</div>;
			}
		}
	}

	buildInformationScreen() {
		let { activeCategoryInfo } = this.data;
		let { label, info, icon } = activeCategoryInfo;
		return (
			<div className="information-container vbox hcenter animated fadeIn">
				<div className="info-header hbox vcenter">
					<div className="grow-1"></div>
					<div
						className="close-btn"
						onClick={() => {
							this.data.activeCategoryInfo = null;
						}}
					>
						<i className="bx bx-x"></i>
					</div>
				</div>
				<img src={icon} className="image-icon" alt={`${label} Category Icon`} />
				<div className="category-label">{label}</div>
				<div className="info-box">{info}</div>
			</div>
		);
	}

	getStepContent() {
		let { currentStep, thought } = this.data;
		if (!thought.type) {
			return (
				<>
					<div className="title">How would you like to describe this thought?</div>
					<div
						className="card-btn vbox vcenter hcenter"
						onClick={() => {
							this.data.thought.type = 'anxious';
						}}
					>
						Distorted Thought
					</div>
					<div
						className="card-btn vbox vcenter hcenter"
						onClick={() => {
							this.data.thought.type = 'positive';
						}}
					>
						Affirmation or Positive Thought
					</div>
					<div className="vbox vcenter hcenter">
						<Link
							popupClose
							className="step-btn vbox vcenter hcenter"
							onClick={() => {
								this.data.flowComplete = false;
								this.data.savedThought = null;
								this.data.thought = this.initialThought(true);
								this.data.currentStep = 1;
							}}
						>
							Cancel
						</Link>
					</div>
				</>
			);
		} else if (thought.type !== null) {
			return this.constructStep(this.data.thought.type === 'anxious' ? anxiousCapture[currentStep] : positiveCapture[currentStep]);
		}
	}

	render() {
		let { onPopupClosed, opened } = this.props;
		return (
			<Popup
				className={`thought-capture-popup  ${!appStore.isMobile ? 'desktop' : 'mobile'}`}
				opened={opened}
				closeByBackdropClick={false}
				closeOnEscape={false}
				onPopupOpened={this.onPopupOpened}
				onPopupClosed={onPopupClosed}
			>
				<div className={`background`}>{this.data.flowComplete && <img src={waveBG} alt="background blue wave" />}</div>
				{this.data.activeCategoryInfo !== null && opened ? (
					this.buildInformationScreen()
				) : (
					<div className={`page-ctn y-scroll`}>{opened && this.getStepContent()}</div>
				)}
			</Popup>
		);
	}
}

export default observer(ThoughtCapturePopup);
